import React, { useState, useRef } from "react";
import { FcSearch } from "react-icons/fc";
import classes from "../../Components/UIcomp/RightTable.module.css";
import HeaderForRightSec from "../../Components/UIcomp/HeadingForRightSec";
import { TiTick } from "react-icons/ti";
import ReactExport from "react-data-export";
import TablesExcelDir from "../../Components/ExcelDir/TablesExcelDir";
import { useHistory, Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FreelancerSearch = () => {
  const [searchUserDetail, setSearchUserDetail] = useState("UserName");
  const [countData, setCountData] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const[totalCount, setTotalCount] = useState("")

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
    setIsLoader("next")
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
    setIsLoader('prev');
  };

  const dateAndTimeHandler  =(signUpDate) =>{

    var date = new Date(signUpDate);
      return date.toString().slice(3,25)
}

  const SearchDetail = () => {
    //setIsLoader(true)

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SearchTerm: searchInput,
      Page: "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/FreelancerSearch",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCountData(result.data);
          setTotalCount(result.count)

        } else if (
          result.status_code === 200 &&
          result.status === "SUCCESS" &&
          result.data === []
          
        ) {
          setCountData("No data found !");
        } else if (result.status_code >= 300 && result.status !== "Success") {
          alert("Please select one field");
        } else {
          alert(result.message);
        }
      })
      .catch(error => console.log('error', error))
      .finally(()=>{
         setIsLoader("")
      });
}
  console.log(searchUserDetail);

  return (
    <>
      <div className={classes.MainContainer}>
        <HeaderForRightSec headingText={" Freelancer Search "} />
       
          <div className={classes.searchBarContainer}>
            <input  type="text" placeholder="Search.."name="search" className={classes.searchInput}
              onChange={(e) => setSearchInput(e.target.value)}value={searchInput} />
            <div className={classes.searchIcon} onClick={() => SearchDetail()}><FcSearch /></div>
            <div className={classes.recordLength}>Data Count {totalCount}</div> 
          </div>

        <div className={classes.buttonContainer}>
          <input
            type="radio"
            id="Username"
            name="button"
            value={searchUserDetail}
            className={classes.button}
            checked={searchUserDetail==="UserName"}
            onChange={() => setSearchUserDetail("UserName")}
          />
            <label for="username">Username</label>
           {" "}
          <input
            type="radio"
            id="fullname"
            name="button"
            value={searchUserDetail}
            className={classes.button}
            onChange={() => setSearchUserDetail("Full Name")}
          />
            <label for="fullname">Fullname</label>
           {" "}
          <input
            type="radio"
            id="email"
            name="button"
            value={searchUserDetail}
            className={classes.button}
            onChange={() => setSearchUserDetail("Email")}
          />
            <label for="email">Email</label>
          <input
            type="radio"
            id="mobile"
            name="button"
            value={searchUserDetail}
            className={classes.button}
            onChange={() => setSearchUserDetail("Mobile")}
          />
            <label for="mobile">Mobile</label>
        </div>

        <TablesExcelDir tableData={countData} tableType={"FreelancerSearch"} />
        <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ?
                    <>
                    
                        
                        <button  onClick={handlePrevbtn}  className={classes.buttonone}>
                       { isLoader=="prev"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Prev</>}
                            </button> 
                    
                    </> 
                   : <span className={classes.buttononePre}>Prev</span>}

                     <span className={classes.CurrentPageText}>{currentPage}</span>
                    {(countData.length >= 20 )? 
                    <>
                  
                       
                        <button  onClick={handleNextbtn}   className={classes.buttonone}>
                        {isLoader=="next"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Next</>}
                        </button>
                    
                    </> : 
                    <span className={classes.buttononePre}>Next</span>}
                    
                </div>

        {/* <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                    {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                    {countData.length >=20 ? <span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span> : <span className={classes.CurrentPageText}>{currentPage}</span>}
                    {countData.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
  </div> */}
                
        {countData.length > 0 ? (
          <div className={classes.table_container}>
            <table className={classes.signup_details_table} id="table-to-xls">
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>S.No</th>
                <th className={classes.table_heading}>User Name</th>
                <th className={classes.table_heading}>Full Name</th>
                <th className={classes.table_heading}>Email</th>
                <th className={classes.table_heading}>Mobile</th>
                <th className={classes.table_heading}>Date</th>
              </tr>

              <tbody>
                {countData.map((item, index) => {
                  return (searchInput===item.UserName &&searchUserDetail === "UserName") 
                  ||(searchInput.includes(item.FirstName) &&searchUserDetail === "Full Name") 
                  ||(searchInput===item.Mobile &&searchUserDetail === "Mobile") 
                  ||(searchInput.includes(item.Email) &&searchUserDetail === "Email") ? 
                  (
                    <tr className={classes.table_row}>
                      {<td className={classes.table_td}>{index + 1}</td>}
                      <td className={classes.table_td}> <div className={classes.Tagsection}> <Link to={{ pathname : "/SignUpDetail/"+ item.FreelancerId, state: {signUpData: item }}}  className={classes.userName}>{item.UserName}</Link>
                                    {item.isOldUser===true && <div className={classes.Tag}>Old</div>}
                                  </div></td>
                      <td className={classes.table_td}>
                        {item.FirstName + " " + item.LastName}
                      </td>
                      <td className={classes.table_td}>
                        {item.Email} {item.EmailVerified && <TiTick color="green" />}
                      </td>
                      <td className={classes.table_td}>
                        {item.Mobile} {item.MobileVerified && <TiTick color="green" />}{" "}
                      </td>
                      <td className={classes.table_td}>{dateAndTimeHandler(item.UpdatedAt)}  </td>
                    </tr>
                  ) : (
                    <></>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={classes.NoDataFound}>
          
          </div>
        )}
      </div>
    </>
  );
};

export default FreelancerSearch;
