const CompanyProfileInfo = () =>{


    return(
<div>
    Company profile Page
</div> 
        )
}

export default CompanyProfileInfo;