
import classes from './HeadingForRightSec.module.css'
const HeaderForRightSec = (props) => {



    return(
<div className={classes.mainContainer}> 
<div className={classes.headingContent}> {props.headingText} </div>
</div>
    )
}

export default HeaderForRightSec;