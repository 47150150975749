import React, { useState, useEffect } from "react";
import classes from "./SignupDetail.module.css";
import FreelancerChat from "../Components/FreelancerDetsils/FreelancerChat";
import Application from "../Components/FreelancerDetsils/Application";
import FreelancerTransaction from "../Components/FreelancerDetsils/FreelancerTransaction";
import ProfileInfoFreelancer from "../Components/FreelancerDetsils/ProfileInfoFreelancer";
import { useLocation } from "react-router-dom";

const SignUpDetail = () => {
  const { pathname } = useLocation();


  const [tabSelected, setTabSelected] = useState("Application");
  const [freelancerProfileData, setFreelancerProfileData] = useState("");
  const [applicationData, setApplicationData] = useState("");

  useEffect(() => {
    freelancerProfile();
    ApiApplication()
  }, []);


  const freelancerProfile = (end, today) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "FreelancerId": pathname.split('/').pop()

    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api-preview.rozgaarindia.com/api/rozgaarapi/FreelancerProfile", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.data){
        setFreelancerProfileData(result.data);
        }else{
          setFreelancerProfileData("https://freesvg.org/img/abstract-user-flat-4.png");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const ApiApplication = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "FreelancerId": pathname.split('/').pop()

    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/MyAaplication", requestOptions)
      .then(response => response.json())
      .then((result) => {
        setApplicationData(result.dataFreelancerdetail);
      })
      .catch((error) => console.log("error", error));
  };

  const tabSelectionSwitch = () => {
    switch (tabSelected) {
      case "Application":
        return (

          <Application applicationData={applicationData} />
        )
      case "Trasaction":
        return (
          <div className={classes.TransactionInfo_margin}>
            <FreelancerTransaction />

          </div>
        );
      case "Chat":
        return <FreelancerChat />
      default:
        return <div></div>;
    }
  };

  return (
    <div className={classes.pageLayout}>
      <ProfileInfoFreelancer freelancerProfileData={freelancerProfileData} />
      <div className={classes.tabSection}>

        <div className={tabSelected === "Application" ? classes.selectedTab : classes.tabHeading}
          onClick={() => setTabSelected("Application")}> Application</div>

        <div className={tabSelected === "Trasaction" ? classes.selectedTab : classes.tabHeading}
          onClick={() => setTabSelected("Trasaction")}> Transaction </div>


        <div className={tabSelected === "Chat" ? classes.selectedTab : classes.tabHeading}
          onClick={() => setTabSelected("Chat")}>Chat</div>

      </div>

      {tabSelectionSwitch()}
    </div>
  );
};
export default SignUpDetail;