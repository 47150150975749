import React from 'react';
import './App.css';
import MainRoute from './Routing/MainRoute';

const App=()=> {
  return (
    <MainRoute/>
  );
}

export default App;
