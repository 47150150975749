import React, { useState, useEffect } from "react";
import classes from "./ViewUserDetail.module.css";
import bredcrumb from "../../Components/BreadCrumb/BreadCrumb.module.css";
import { useHistory } from "react-router-dom"

const ViewUserDetail = () => {

  let loggedIn = localStorage.getItem("UserLoggedIn");
  let history = useHistory();
  
  const [data, setData] = useState("");
  const [approve, setApprove] = useState("");
  const [reject, setReject] = useState("");

  useEffect(() => {
    ViewUserDetailApi();
  }, []);

  let url = window.location.href;
  let object = new URL(url);
  let path = object.pathname;
  let RequirementID = path.split("/").pop();

  const ViewUserDetailApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      RequirementID: RequirementID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "http://api-preview.rozgaarindia.com/api/rzadmin/GetPostAReqOneUserDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const PostAReqAdminStatusApi = (RequirementID, status) => {
    if (status === "Approved") setApprove(approve, RequirementID);
    else if (status === "Disapproved") setReject(reject, RequirementID);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      RequirementID: RequirementID,
      Status: status,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "http://api-preview.rozgaarindia.com/api/rzadmin/PostAReqAdminStatus",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {loggedIn ?
        <>

      <div className={classes.MainContainer}>

        <ul className={bredcrumb.breadcrumb}>
          <li className={bredcrumb.breadcrumb_li}>
            <a href="/DashBoard" className={bredcrumb.breadcrumb_li_a}>PostARequirement</a>
          </li>
          <li className={bredcrumb.breadcrumb_li}>ViewUserDetail</li>
        </ul> 

        <div className={classes.postARequirementDetailsHeading}>
          Post a requirements details
        </div>
        
        <div className={classes.table_container}>
          <table className={classes.signup_details_table}>
            <tr className={classes.table_row}>
              <th className={classes.table_heading}>Id</th>
              <th className={classes.table_heading}>KeyIndex</th>
              <th className={classes.table_heading}>UserId</th>
              <th className={classes.table_heading}>RequirementID</th>
              <th className={classes.table_heading}>Title</th>
              <th className={classes.table_heading}>Description</th>
              <th className={classes.table_heading}>IsCompany</th>
              <th className={classes.table_heading}>CompanyName</th>
              <th className={classes.table_heading}>CompanyLogo</th>
              <th className={classes.table_heading}>CompanyWebsite</th>
              <th className={classes.table_heading}>RequirementType</th>
              <th className={classes.table_heading}>FreelancerPolicy</th>
              <th className={classes.table_heading}>IsRemote</th>
              <th className={classes.table_heading}>IsHybrid</th>
              <th className={classes.table_heading}>IsOnsite</th>
              <th className={classes.table_heading}>City</th>
              <th className={classes.table_heading}>State</th>
              <th className={classes.table_heading}>Country</th>
              <th className={classes.table_heading}>Location</th>
              <th className={classes.table_heading}>Address</th>
              <th className={classes.table_heading}>Pincode</th>
              <th className={classes.table_heading}>MultipleFreelancers</th>
              <th className={classes.table_heading}>FreelancersCount</th>
              <th className={classes.table_heading}>Budget</th>
              <th className={classes.table_heading}>BudgetCurrency</th>
              <th className={classes.table_heading}>BudgetUnit</th>
              <th className={classes.table_heading}>TotalAmount</th>
              <th className={classes.table_heading}>ImageUrl</th>
              <th className={classes.table_heading}>Status</th>
              <th className={classes.table_heading}>CreatedDate</th>
              <th className={classes.table_heading}>UpdatedDate</th>
              <th className={classes.table_heading}>CreatedBy</th>
              <th className={classes.table_heading}>UpdatedBy</th>
              <th className={classes.table_heading}>AddonId</th>
              <th className={classes.table_heading}>AddonAmount</th>
              <th className={classes.table_heading}>AddonName</th>
              <th className={classes.table_heading}>IsDeleted</th>
              <th className={classes.table_heading}>RSkillID</th>
              <th className={classes.table_heading}>Skill</th>
            </tr>

            <tbody>
              <tr className={classes.table_row}>
                <td className={classes.table_td}>{data.ID}</td>
                <td className={classes.table_td}>{data.KeyIndex}</td>
                <td className={classes.table_td}>{data.UserId}</td>
                <td className={classes.table_td}>{data.RequirementID}</td>
                <td className={classes.table_td}>{data.Title}</td>
                <td className={classes.table_td}>{data.Description}</td>
                <td className={classes.table_td}>{data.IsCompany}</td>
                <td className={classes.table_td}>{data.CompanyName}</td>
                <td className={classes.table_td}>{data.CompanyLogo}</td>
                <td className={classes.table_td}>{data.CompanyWebsite}</td>
                <td className={classes.table_td}>{data.RequirementType}</td>
                <td className={classes.table_td}>{data.FreelancerPolicy}</td>
                <td className={classes.table_td}>{data.IsRemote}</td>
                <td className={classes.table_td}>{data.IsHybrid}</td>
                <td className={classes.table_td}>{data.IsOnsite}</td>
                <td className={classes.table_td}>{data.City}</td>
                <td className={classes.table_td}>{data.State}</td>
                <td className={classes.table_td}>{data.Country}</td>
                <td className={classes.table_td}>{data.Location}</td>
                <td className={classes.table_td}>{data.Address}</td>
                <td className={classes.table_td}>{data.Pincode}</td>
                <td className={classes.table_td}>{data.MultipleFreelancers}</td>
                <td className={classes.table_td}>{data.FreelancersCount}</td>
                <td className={classes.table_td}>{data.Budget}</td>
                <td className={classes.table_td}>{data.BudgetCurrency}</td>
                <td className={classes.table_td}>{data.BudgetUnit}</td>
                <td className={classes.table_td}>{data.TotalAmount}</td>
                <td className={classes.table_td}>{data.ImageUrl}</td>
                <td className={classes.table_td}>{data.Status}</td>
                <td className={classes.table_td}>{data.CreatedDate}</td>
                <td className={classes.table_td}>{data.UpdatedDate}</td>
                <td className={classes.table_td}>{data.CreatedBy}</td>
                <td className={classes.table_td}>{data.UpdatedBy}</td>
                <td className={classes.table_td}>{data.AddonId}</td>
                <td className={classes.table_td}>{data.AddonAmount}</td>
                <td className={classes.table_td}>{data.AddonName}</td>
                <td className={classes.table_td}>{data.IsDeleted}</td>
                <td className={classes.table_td}>{data.RSkillID}</td>
                <td className={classes.table_td}>{data.Skill}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={classes.postARequirementLiveButtons}>
          <button
            className={classes.approve}
            onClick={() => PostAReqAdminStatusApi(RequirementID, "Approved")}
          >
            Approve
          </button>
          <button
            className={classes.disapprove}
            onClick={() =>
              PostAReqAdminStatusApi(RequirementID, "Disapproved")
            }
          >
            Reject
          </button>
      </div>
      </div>
      </> : history.push('/')}
    </>
  );
};

export default ViewUserDetail;
