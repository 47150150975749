import React from 'react'
import classes from "../../Components/UIcomp/RightTable.module.css";

const ChatCases = (props) => {
    const chatType = (type) => {
        switch (type) {
            case 'Freelancer':

                switch (props.mType) {
                    case 'text':
                        return (
                            <div className={classes.BoxCover}>
                                < div className={classes.freelancermsgBox} >
                                    <div className={classes.freelancertxthead}>{props.items.FreelancerFirstName}</div>
                                    <div></div>
                                    <div className={classes.messageFreelancer}>{props.msg}</div>
                                </div >
                            </div>
                        );

                    case 'image':
                        return (
                            < div className={classes.freelancermsgBox} >
                                <div className={classes.freelancertxthead}>{props.items.FreelancerFirstName}</div>
                                <div className={classes.messageFreelancer}>{props.msg}</div>
                            </div >
                        );
                    default:
                        console.log('invalid input');
                        break;

                }
                return chatType(props.mType);


            case 'Client':

                switch (props.mType) {
                    case 'text':
                        return (
                            <div className={classes.clientmsgBox}>
                                <div className={classes.Clienttxthead}>{props.items.ClientFirstName}</div>
                                <div className={classes.messageClient}>{props.msg}</div>
                            </div>
                        );
                        
                    case 'image':
                        return (
                            <div className={classes.clientmsgBox}>
                                <div className={classes.Clienttxthead}>{props.items.ClientFirstName}</div>
                                <div className={classes.messageClient}>{props.msg}</div>
                            </div>
                        );
                    default:
                        console.log('invalid input');
                        break;

                }
                return chatType(props.mType);

            default:
                console.log('invalid input');

        }
    };
    return chatType(props.msgSender);
}

export default ChatCases;