import React from 'react'
import { useHistory } from "react-router-dom";

const LogOut= () => {
    let history = useHistory();

    localStorage.removeItem('UserLoggedIn')
    history.push('/');
     
  return (
    <> 

    </>
  )
  
}

export default LogOut;