import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "../../Components/UIcomp/RightTable.module.css";

const FreelancerChat = () => {
  const [chat, setChat] = useState([]);

  useEffect(() => {
    chatDetails();
  }, []);
  let url = window.location.href;
  let object = new URL(url);
  let path = object.pathname;
  let RequirementID = path.split("/").pop();

  const chatDetails = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "UserId": RequirementID

    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api-preview.rozgaarindia.com/api/client/UserGroupList", requestOptions)
      .then(response => response.json())
      .then((result) => {
        if (result.status === "SUCCESS") {
          setChat(result.data)
        } else {
          console.log("fail")
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>

      {!chat ? <div>
        You  have no  any chat  yet
      </div> :

        <div className={classes.mainContainer}>
          <table className={classes.signup_details_table} id="table-to-xls">
            <tr className={classes.table_row}>
              <th className={classes.table_heading}>S.No.</th>
              <th className={classes.table_heading}>Status</th>
              <th className={classes.table_heading}>Freelancer User Name</th>
              <th className={classes.table_heading}>Client User Name</th>
              <th className={classes.table_heading}>View</th>
            </tr>
            {chat.map((item, index) => {
              return (
                <tr className={classes.table_row}>
                  <td className={classes.table_td}>{index + 1}</td>
                  <td className={classes.table_td}>{item.g_status}</td>
                  <td className={classes.table_td}>{item.FreelancerUserName}</td>
                  <td className={classes.table_td}>{item.ClientUserName}</td>
                  <td className={classes.table_td}> <Link target={"blank"} to={"/ViewChat/" + item.g_id}>View</Link></td>
                </tr>
              )
            })}
          </table>
        </div>
      }
    </>
  )
}
export default FreelancerChat;