import React, { useEffect, useState,useRef } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

/* import { DownloadTableExcel } from 'react-export-table-to-excel'; */
import classes from '../Components/UIcomp/RightTable.module.css'
import { useHistory, Link } from "react-router-dom";
import HeaderForRightSec from '../Components/UIcomp/HeadingForRightSec';
import { TiTick } from 'react-icons/ti';
import ReactExport from "react-data-export";
import TablesExcelDir from '../Components/ExcelDir/TablesExcelDir';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const PaymentList = () => {

    let loggedIn = localStorage.getItem("UserLoggedIn");
    let history = useHistory();
    const tableRef = useRef(null);
    
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [countData, setCountData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
  

    useEffect(() => {
        var date = new Date();
date.setDate(date.getDate() - 7);
const dateFormat = (date.getDate() + 1).length > 1 ? (date.getDate() + 1) : "0" + (date.getDate() + 1)
const monthFormat = (date.getMonth()+1).length > 1 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)
var finalDate = date.getFullYear() + '-'+  monthFormat + '-' + dateFormat
setStartDate(finalDate)

var todayDate = new Date();
  todayDate.setDate(todayDate.getDate())
  const todaymonthFormat = (todayDate.getMonth()+1).length > 1 ? (todayDate.getMonth()+1) : "0" + (todayDate.getMonth()+1)
  var finalToday = todayDate.getFullYear() + '-'+  todaymonthFormat + '-' + todayDate.getDate()
 setEndDate(finalToday)
 SignupDetails(finalDate ,finalToday, currentPage)
    }, []);
    
    useEffect(() => {
        if (startDate && endDate && currentPage)
            SignupDetails(startDate, endDate, currentPage)
    }, [currentPage])

    
    const SignupDetails = (end ,today, page ) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "StartDate":  end? end : (startDate),
            "EndDate": today ?  today : (endDate),
            "Page":page?page:currentPage,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        console.log ("aa",raw)
        fetch("https://api-preview.rozgaarindia.com/api/client/PaymentDetailList", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("Api Result",result)
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    setCountData(result.data)
                }
                else if (result.status_code  === 200 && result.status === "SUCCESS" && result.data===[]  ){
                   setCountData("No data found !")
                  }
                else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
                    alert("Please select start date and end date")
                  }
                else {
                    alert(result.message)
                }

            })
            .catch(error => console.log('error', error));
    }

    const dateAndTimeHandler  =(signUpDate) =>{

        var date = new Date(signUpDate);
          return date.toString().slice(3,25)
    }
    
    var dataSet2 = [
        {
            name: "Johnson",
            total: 25,
            remainig: 16
        },
        {
            name: "Josef",
            total: 25,
            remainig: 7
        }
    ];

  

    return (

        <>
       
        <div className={classes.MainContainer}>
           
        
           <HeaderForRightSec headingText={"All Payment List"} />
       

            <div className={classes.date_container}>
                <div className={classes.date_field}><label>From</label><input type="date" className={classes.date_input} onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                <div className={classes.date_field}><label>To</label><input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
               <div className={classes.searchButton} onClick={()=> {setCurrentPage(1);SignupDetails()}}> Search  </div>
               <div className={classes.recordLength}> {countData.length} results </div>
            </div>
           {/*  <ExcelFile element={<button>Download Data</button>}>
 <ExcelSheet data={countData} name="Leaves">
                    <ExcelColumn label="Country" value="Country"/>
                    <ExcelColumn label="Full Name" value="FirstName"/>
                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                </ExcelSheet>
                </ExcelFile> 
 */}
                
{/* <Button onClick={onDownload}> Export excelkk </Button> */}
<TablesExcelDir tableData={countData} tableType={"PaymentList"} />
                <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                    {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                    {countData.length >=20 ? <span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span> : <span className={classes.CurrentPageText}>{currentPage}</span>}
                    {countData.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
                </div>

            {countData.length > 0 ? <div className={classes.table_container}>
                <table className={classes.signup_details_table} id="table-to-xls">
                    <tr className={classes.table_row}>
                      {/*   <th className={classes.table_heading}>Country</th> */}
                      
                        <th className={classes.table_heading}>Order Id </th>
                        <th className={classes.table_heading}>GST </th>
                        <th className={classes.table_heading}>Total Amount </th>
                        <th className={classes.table_heading}>Payment Type </th>
                        <th className={classes.table_heading}>Full Name</th>
                        <th className={classes.table_heading}>Username</th>
                        <th className={classes.table_heading}>Email</th>
                        <th className={classes.table_heading}>Mobile</th>
                        <th className={classes.table_heading}>Date</th>
                    </tr>

                    <tbody>
                        {countData.map((item, value) => {
                            return (
                                <tr className={classes.table_row} >

                                    <td className={classes.table_td}>{item.PGOrderId}</td>
                                    <td className={classes.table_td}>{item.GST}</td>
                                    <td className={classes.table_td}>{item.GrandTotal}</td>
                                    <td className={classes.table_td}>{item.PaymentType}</td>
                                    <td className={classes.table_td}>{item.FreelancerUserName} {item.FreelancerLastName}</td>
                                    <td className={classes.table_td}> <Link to={{ pathname : "/SignUpDetail/"+ item.ID, state: {signUpData: item }}}  className={classes.userName}>{item.FreelancerUserName}</Link></td>
                                    <td className={classes.table_td}>{item.FreelancerEmail} {item.EmailVerified  && <TiTick color='green'/>}</td>
                                    <td className={classes.table_td}>{item.FreelancerMobile} {item.MobileVerified && <TiTick color='green' />} </td>
                                    <td className={classes.table_td}>{dateAndTimeHandler(item.CreatedAt)} </td>
                                   
                                </tr>
                            )
                        }

                       )}

                   </tbody>

                </table>

            </div> : <div className={classes.NoDataFound}>Please select start date and end date</div>}
            
        </div>

    </> 
     

    );
}

export default PaymentList;
