import React, { useState } from 'react';
import RiHeadingText from '../../Components/RiHeadingText';
import classes from './InsertPartner.module.css';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useHistory } from "react-router-dom"
import bredcrumb from "../../Components/BreadCrumb/BreadCrumb.module.css";

const InsertPartner = () => {
    
    let loggedIn = localStorage.getItem("UserLoggedIn");
  // console.log("loggedIn:", loggedIn);
  let history = useHistory();

    const [name, setName] = useState();
    const [about, setAbout] = useState();
    const [contact, setContact] = useState();
    const [jobs, setJobs] = useState();


    const PartnerAPI = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Name": (name),
            "About": (about),
            "Jobs": (jobs),
            "Contacts": (contact)
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rzadmin/AdminPartnerPageJson", requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => {
                alert("Server Error")
            });
    }
    
    return (
        <>
     
        <div className={classes.MainContainer}>
            <ul className={bredcrumb.breadcrumb}>
             <li className={bredcrumb.breadcrumb_li}>
               <a href="/DashBoard" className={bredcrumb.breadcrumb_li_a}>PartnerPage</a>
             </li>
             <li className={bredcrumb.breadcrumb_li}>InsertPartner</li>

           </ul> 

           {/* <RiHeadingText title={'Partner Companies'} /> */ }

              <div className={classes.inputContainer}>
                <label className={classes.lableDesign}>Name</label>
                <input type="text" name="name" className={classes.inputArea} onChange={(e) => setName(e.target.value)} value={name} />
              </div>

            <div className={classes.inputContainer}>
                <label className={classes.lableDesign}>Jobs</label>
                <SunEditor
                    onChange={(e) => {
                      setJobs(e);
                    }}
                    value={jobs}
                    setContents={jobs}
                  />
               </div>

              <div className={classes.inputContainer}>
                <label className={classes.lableDesign}>About</label>

                <SunEditor
                    onChange={(e) => {
                      setAbout(e);
                    }}
                    value={about}
                    setContents={about}
                  />
             </div>

              <div className={classes.inputContainer}>
                <label className={classes.lableDesign}>Contact</label>

                <SunEditor
                    onChange={(e) => {
                      setContact(e);
                    }}
                    value={contact}
                    setContents={contact}
                  />
              </div>

            <button className={classes.buttonBox} onClick={() => PartnerAPI()}> Submit</button>
        </div>
        
    </>

  );

}

export default InsertPartner;
