import React, { useState } from 'react';
import RiHeadingText from '../../Components/RiHeadingText';
import classes from './PartnerPages.module.css';
import bredcrumb from "../../Components/BreadCrumb/BreadCrumb.module.css";
import { Link } from "react-router-dom";
import {useHistory} from "react-router-dom";



const PartnerPages = () => {

    let loggedIn = localStorage.getItem("UserLoggedIn");
    let history = useHistory()

    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

   
    const PartnerAPI = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "StartDate": startDate,
            "EndDate": endDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rzadmin/PartnerPageJson", requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status_code===200&& result.status==="SUCCESS"){
                    setData(result.LandingPageID)
                  }
                  else{
                    alert(result.message)
                  }
             
            })
            .catch(error => console.log('error', error));
    }
           /* const pageInfo = {
                "currentPageName": "PartnerPages"
            } */
    return (
        <>
        
            <div className={classes.MainContainer}>
                <ul className={bredcrumb.breadcrumb}>
                    <li className={bredcrumb.breadcrumb_li}>
                    <a href="/DashBoard" className={bredcrumb.breadcrumb_li_a}>DashBoard</a>
                    </li>
                    <li className={bredcrumb.breadcrumb_li}>Partner Pages</li>

                </ul> 

                {/* <BreadCrumb pageInfo={pageInfo}/>
                    <RiHeadingText title={'Partner Companies'} /> */}

                    <div className= {classes.partnerpages_heading}>Partner Pages</div>
                    
            <div className={classes.date_container}>
                <div className={classes.date_field}>From<input type="date" className={classes.date_input} onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                <div className={classes.date_field}>To<input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
                <button className={classes.search_button} onClick={PartnerAPI}>Search</button>
                <div className={classes.button}>  Insert Partner </div>
            </div>
            
            <div className={classes.table_container}>
                <table className={classes.table}>
                    <tr className={classes.table_row}>
                        <th className={classes.table_heading}>Id</th>
                        <th className={classes.table_heading}>Name</th>
                        <th className={classes.table_heading}>CreatedDate</th>
                        <th className={classes.table_heading}>UpdatedDate</th>
                        <th className={classes.table_heading}>Update</th>
                    </tr>
                    <tbody>
                        {data.map((item, value) => {
                            return (
                                <tr className={classes.table_row} >
                                    <td className={classes.table_td}>{item.ID}</td>
                                    <td className={classes.table_td}>{item.Name}</td>
                                    <td className={classes.table_td}>{item.CreatedDate.slice(0, 10).split("-").reverse().join("-")}</td>
                                    <td className={classes.table_td}>{item.UpdatedDate.slice(0, 10).split("-").reverse().join("-")}</td>
                                    <td className={classes.table_td}><Link to={"/UpdatePartnerPage/" + item.Name + "/" + item.PartnerPageId} className={classes.update_link} >Update </Link></td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>

                </table>
            </div>
        </div>
       
        </>
    )
}

export default PartnerPages
