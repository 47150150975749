import React, { useState, useEffect } from "react";
import classes from "./Modal.module.css";
import { ImCross } from "react-icons/im";


const Modal = (props) => {

  const [show, setShow] = useState(false);
  //const [name, setName] = useState()

  /*  const closeHandler = (e) => {
     setShow(false);
     props.onClose(false);
   };
   const ModalisClose = () => {
     return (setShow(false))
   } */
  const Backdrop = () => {
    return <div className={classes.backdrop} />;
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const modalType = (type) => {
    switch (type) {
      case 'modal1':
        return (
          <>
            <Backdrop />
            <div className={classes.modal}>

              <div className={classes.ModalProfBox}>

                <div className={classes.modalmainText}> Do you want your form to be Approved ?</div>
              </div >

              <div className={classes.okcancel}>
                <div className={classes.ok} onClick={props.YesButton}> YES</div>
                <div className={classes.cancel} onClick={props.NoButton}> NO</div>
              </div>

            </div>

          </>
        );

      case 'modal2':
        return (
          <>
            <Backdrop />
            <div className={classes.modal}>

              <div onClick={props.CancelNo} className={classes.icon} >< ImCross /></div>

              <div className={classes.ModalProfBox}>
               <div className={classes.modalmainText}> Do you want your form to be Rejected ? </div>
              </div>

              <div className={classes.input}>
                <input className={classes.inputbox} placeholder="Enter the reason for rejection" value={props.value} onChange={props.onChange} required /> 
              </div>

              <div className={classes.okcancel}>
                <div className={classes.ok} onClick={props.CancelYes}> YES</div>
                <div onClick={props.CancelNo} className={classes.cancel}> NO </div>
              </div>

            </div>
          </>
        );
      default:
        return (
          <div
            style={{
              visibility: show ? "visible" : "hidden",
              opacity: show ? "1" : "0"
            }}
            className={classes.overlay}
          >
            <div className={classes.popup}>
              <h4>{props.title}</h4>
             
              <div className={classes.content}>{props.children}</div>
            </div>
          </div>
        );
    };

  }
  return modalType(props.modalCateg);

};

export default Modal;
