import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import HeaderForRightSec from "../../Components/UIcomp/HeadingForRightSec";
import classes from "../../Components/UIcomp/RightTable.module.css";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaPen} from "react-icons/fa";
import {countries} from "../../JsonFiles/countries";
import states from "../../JsonFiles/States.json";
import RIAdminInput from "../../Components/RIAdminInput";
import Loader from "react-loader-spinner";
import TextEditor from "../../Components/TextEditor";

const UserEditInfo = () => {

  let loggedIn = localStorage.getItem("UserLoggedIn");
  let history = useHistory();

  const { ReqId, userID } = useParams();

  const [title, setTitle] = useState("");
  //const [budgetCurrency,setBudgetCurrency ] = useState("₹");
  const [budget, setBudget] = useState("");
  const [budgetUnit, setBudgetUnit] = useState("");
  const [freelancerPolicy, setFreelancerPolicy] = useState("");
  const [freelancerCount, setFreelancerCount] = useState("");
  const [requirementType, setRequirementType] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [imageUrlPre, setimageUrlPre] = useState("");
  const [description, setDescription] = useState("");
  const [onSite, setOnSite] = useState("");
  const [hybrid, setHybrid] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [cityData, setCityData] = useState("");
  const [cityLoading, setCityLoading] = useState(false);
  const [updateLocation, setUpdateLocation] = useState(true);
  const [error, setError] = useState({});
  const [focus, setFocus] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [status, setStatus] = useState();
  //const [errorMessage, setErrorMessage] = useState(false) 
  //const [errorShow, setErrorShow] = useState(false);


  //  let userID = localStorage.getItem("Client_userID");

  useEffect(() => {
    getUserReq();
  }, []);

  const getUserReq = () => {

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserId: userID,
      RequirementID: ReqId,
      
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/getRequirementser",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setTitle(result.data.Title);
         // setBudgetCurrency(result.data.BudgetCurrency);
          setBudget(result.data.Budget);
          setBudgetUnit(result.data.BudgetUnit);
          setDescription(result.data.DescriptionHTML);
          setFreelancerCount(result.data.FreelancersCount);
          setRequirementType(result.data.RequirementType);
          setFreelancerPolicy(result.data.FreelancerPolicy);
          setimageUrlPre(result.data.ImageUrl);
          setOnSite(result.data.IsOnsite);
          setHybrid(result.data.IsHybrid);
          setCountry(result.data.Country);
          setState(result.data.State);
          setCity(result.data.City);
          setPinCode(result.data.Pincode);
          setStatus(result.data.Status);


          if(result.data.FreelancerPolicy!== "" && result.data.FreelancerPolicy== 'Hybrid'){
            setFreelancerPolicy('Hybrid');
              } else if(result.data.FreelancerPolicy!== "" && result.data.FreelancerPolicy== 'Remote'){
                setFreelancerPolicy('Remote');
            } else if(result.data.FreelancerPolicy!== "" && result.data.FreelancerPolicy== 'Onsite'){
              setFreelancerPolicy('Onsite');
            }
        }
      })
      .catch((error) => console.log("error", error));
  };

   const updateUserData = () => {

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );

    var formdata = new FormData();
    formdata.append("UserId", userID);
    formdata.append("RequirementID", ReqId);
    formdata.append("Status", status);
    formdata.append("RequirementType", requirementType);
    formdata.append("FreelancerPolicy", freelancerPolicy);
    formdata.append("Description", description);
    formdata.append("Title", title);
    formdata.append("FreelancersCount", freelancerCount);
    formdata.append("ImageUrl", imageUrl);
    formdata.append("Budget", budget);
    formdata.append("BudgetCurrency", "₹");
    formdata.append("BudgetUnit",budgetUnit );
    formdata.append("Pincode ", pinCode);
    formdata.append("City ", city);
    formdata.append("State ", state);
    formdata.append("Country ", country);
   

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/PostARequirementsUpdate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          alert("Data updated successfully!!");
          history.push("/UserDetailInfo/"+ userID)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const cityList = (selectedState) => {
    setCityLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Country: country,
      State: selectedState ? selectedState : state,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api-preview.rozgaarindia.com/api/rozgaarapi/GetCityLocatinList", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCityData(result.data);
        } 
        
      })
      .catch(error => console.log('error', error))
      .finally(()=>{
        setCityLoading(false)
      });
}


 const HandleCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleState = (e) => {
    setState(e.target.value);
    cityList(e.target.value);
  };


  const policy = (e) => {
    if(freelancerPolicy==="Remote"){
      setFreelancerPolicy(e.target.value)
      setCountry("")
      setState("")
      setCity("")
    }
    else{
      setFreelancerPolicy(e.target.value)
    }
  }

  

  return (
    <>
      <div className={classes.MainContainer}>

        <HeaderForRightSec headingText={"User Edit Info"} />

        <div className={classes.box}>
          <div className={classes.inputBox}>
            <div>Title</div>
            <input
              type="text"
              className={classes.inputUpdate}
              placeholder="e.g. flutter developer"
              id="fname"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
      
          <div className={classes.inputBox}>
            <div>Budget</div>
            <input
              type="text"
              className={classes.inputUpdate}
              placeholder="₹"
              id="password"
              onChange={(e)=>setBudget(e.target.value)}
              value={budget}
            />
          </div>

          <div className={classes.inputBoxone}>
            <label className={classes.inputBoxone}>Budget Unit</label>
            <select name= "type" id= "type"    onChange={(e) => setBudgetUnit(e.target.value)} value= {budgetUnit} className= {classes.inputUpdated}>
              <option value = " ">Select</option>
              <option value = "Total"> Total</option>
              <option value = "Weekly">Weekly </option>
              <option value = "Monthly"> Monthly </option>
              <option value = "On Maturity"> On Maturity </option>
            </select>
          </div>

          <div className={classes.inputBoxone}>
            <label className={classes.inputBoxone}>Freelancer Policy</label>
            <select name= "type" id= "type" onChange={policy} value= {freelancerPolicy} className= {classes.inputUpdated}>
              <option value = " ">Select</option>
              <option value = "Hybrid"> Hybrid </option>
              <option value = "Remote">Remote </option>
              <option value = "Onsite"> Onsite </option>
            </select>
          </div>

          
          

 {onSite === "1" || hybrid==="1"||freelancerPolicy==="Hybrid"||freelancerPolicy==="Onsite"? (

    <>
                 

             {updateLocation && country && state ? (
                          <>
                            <div className={classes.inputBoxContainer}>
                              <div className={classes.inputBox}>
                                {country}
                                </div>
                                <FaPen
                                  className={classes.editButton}
                                  onClick={() => {
                                    setUpdateLocation(false);
                                    setCountry(" ");
                                    setState(" ");
                                    setCity(" ");
                                  }}
                                  size={16}
                                />
                             
                            </div>

                            <div className={classes.inputBoxContainer}>
                              <div className={classes.inputBox}>{state}</div>
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className={classes.inputUpdate1}>
                              <RIAdminInput
                                input={"Dropdown"}
                                placeHolder={"Select Country"}
                                displayData={countries}
                                // onInput={() => {
                                //   error.country = " ";
                                // }}
                                onChange={HandleCountry}
                                value={country}
                                defaultCountry={country}
                              />
                            
                              <div className={classes.error_message}>
                                {error.country}
                              </div>
                            </div>

                            <div className={classes.personalInfoInput}>
                              <RIAdminInput
                                input={"Dropdown"}
                                placeHolder={"Select State"}
                                displayData={states}
                                selectedCountry={country}
                                // onInput={() => {
                                //   error.state = " ";
                                // }}
                                onChange={(e) => {
                                  handleState(e);
                                }}
                                value={state}
                                defaultState={state}
                              />
                             
                            </div>
                          </>
               )}

                        {updateLocation && city ? (
                          <div className={classes.inputBoxContainer}>
                            <div className={classes.inputBox}>{city}</div>
                          </div>
                        ) : (
                          <>
                            {cityLoading ? (
                              <div className={classes.inputBox}>
                                <div className={classes.inputBox}>
                                  <Loader
                                    type="TailSpin"
                                    color="#1778f2"
                                    width={20}
                                    height={18}
                                  />
                                </div>{" "}
                              </div>
                            ) : (
                              <div className={classes.inputBox}>

                              <RIAdminInput
                                input={"Dropdown"}
                                placeHolder={"Select City"}
                                displayData={cityData}
                                // selectedCountry={country}
                                // onInput={() => {
                                //   error.city = " ";
                                // }}
                                onChange={(e) => {
                               setCity(e.target.value)
                                }}
                                value={city}
                               
                              />
                                
                                
                              </div>
                            )}
                          </>
                 )}
                        

                        <div className={classes.inputBox}>
            <label className={classes.inputBox}>Pincode</label>
            <input
              type="text"
              className={classes.inputUpdate}
              placeholder=""
              id="password"
              maxLength="6"
              onChange={(e) =>
                setPinCode(
                  isNaN(parseInt(e.target.value))
                    ? ""
                    : e.target.value
                )
              }
              value={pinCode}
            />
          </div>
                      </>
                    ) :<></>
   }
          
    
                        <div className={classes.inputBoxone}>
            <label className={classes.inputBoxone}>Requirement Type</label>
            <select name= "type" id= "type"onChange={(e) => setRequirementType(e.target.value)} value= {requirementType} className= {classes.inputUpdated}>
              <option value = " ">Select</option>
              <option value = "onetime"> onetime </option>
              <option value = "commission">commission </option>
              <option value = "contract"> contract </option>
              <option value = "monthly-basis">monthly-basis </option>
            </select>
          </div>

          <div className={classes.inputBox}>
            <label className={classes.inputBox}>Freelancer Count </label>
            <input
              type="text"
              className={classes.inputUpdate}
              placeholder="0"
              onChange={(e) => setFreelancerCount(e.target.value)}
              value={freelancerCount}
            />
          </div>

          <label className={classes.inputBox}>Image Url</label>
          <label className={classes.inputUpdate} for="uploadM">
            <input
              type="file"
              id="uploadM"
             
              hidden
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                e.target.files[0]
                  ? setimageUrl(e.target.files[0])
                  : setimageUrl(imageUrl);
              }}
            />
            {imageUrl ? (
              <img
                className={classes.form_upload_label}
                src={URL.createObjectURL(imageUrl)}
                alt=""
                height={20}
                width={20}
              />
            ) : (
              <>
                {imageUrlPre ? (
                  <img
                    className={classes.form_upload_label}
                    src={imageUrlPre}
                    alt=""
                    height={20}
                    width={20}
                  />
                ) : (
                  <div className={classes.uploadPlaceholder}>
                    Upload mobile image
                  </div>
                )}{" "}
              </>
            )}
            <AiOutlineCloudUpload id="uploadM" className={classes.uploadIcon} />
          </label>

          <div className={classes.inputBoxone}>
            <label className={classes.inputBoxone}>Status</label>
            <select name= "type" id= "type"onChange={(e) => setStatus(e.target.value)} value= {status} className= {classes.inputUpdated}>
              <option value = " ">Select</option>
              <option value = "Approved"> Approved </option>
              <option value = "Disapproved"> Disapproved</option>
              <option value = "Draft"> Draft </option>
              <option value = "Submitted"> Submitted </option>
              <option value = "Closed"> Closed </option>
            </select>
          </div>

          {/* <div className={classes.inputBox}>
           
            <textarea
              className={classes.inputUpdate}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              rows={8}
              cols={60}
              placeholder="e.g. Write your descritption here..."
            ></textarea>
          </div> */}
     {     console.log(description)}
          <label className={classes.inputBox}>Description</label>
          <div className={classes.htmlEditor} > <TextEditor  onChangeContent={ setDescription} setFocus={setFocus} value={ description} /></div>
          <div className={classes.searchButton} onClick={() => {updateUserData(status)}}>Submit </div>

        </div>
      </div>
    </>
  );
};

export default UserEditInfo;
