import React,{useState} from 'react';
import classes from './RiAdminInput.module.css'

const RIAdminInput = (props) => {

  const [countryNameFilter, setCountryNameFilter] = useState(false);
  const [searchItem, setSearchItem] = useState("India");
  const [countryDialCode, setCountryCode] = useState();
  const [flag, setFlag] = useState();
  const [isOpen, setIsOpen] = useState(false);


  const onOptionClicked = (item, value, flag, countryDialCode, country) => {
   
    setSearchItem(value);
    setIsOpen(false);
    setFlag(flag);
    setCountryCode(countryDialCode);
    if (props.setCountry) {
      props.setCountry(value);
    }

    if (props.setCountryObject) {
      props.setCountryObject(item);
    }
  };

  const DropdowonOptions = () => {

    switch (props.label || props.placeHolder) {
      case "Country":
        return (
          <>
            {props.displayData
              .filter((data) => {
                if (
                  searchItem === "" ||
                  searchItem === "India" ||
                  countryNameFilter
                ) {
                  return props.displayData;
                } else if (
                  data.name.toLowerCase().startsWith(searchItem.toLowerCase())
                ) {
                  return data.name;
                }
              })
              .map((item, index) => {
                return (
                  <>
                    <li
                      onClick={() =>
                        onOptionClicked(
                          item,
                          item.name,
                          `https://flagpedia.net/data/flags/normal/${item.code.toLowerCase()}.png`,
                          item.dial_code,
                          item.name,
                          item
                        )
                      }
                      className={classes.dropdownLi}
                      key={item}
                    >
                      <img
                        className={classes.flagImage}
                        src={`https://flagpedia.net/data/flags/normal/${item.code.toLowerCase()}.png`}
                        alt="Country_Flag_Logo"
                      />{" "}
                      {item.name}
                    </li>
                  </>
                );
              })}
          </>
        );

      case "Select Country":
        return (
          <>
            <option value="">
              Select country
            </option>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  <>
                    <option
                      key={item.id}
                      selected={
                        props.defaultCountry === item.name ? true : false
                      }
                      value={item.name}
                    >
                      {item.name}
                    </option>
                  </>
                );
              })}
          </>
        );

      case "Select State":
        return (
          <>
            <option value= "" >
              Select state
            </option>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  item.country_name === props.selectedCountry && (
                    <option
                      key={item.id}
                      selected={props.defaultState === item.name ? true : false}
                      value={item.name}
                    >
                      {item.name}
                    </option>
                  )
                );
              })}
          </>
        );
      case "Select City":
        return (
          <>
            <option value="" >
              Select City
            </option>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  <option
                    key={item.id}
                    selected={props.defaultCity === item.City ? true : false}
                    value={item.City}
                  >
                    {item.City}
                  </option>
                );
              })}
          </>
        );
     
      default:
        break;
    }
  };
        const inputType = (type) => {
            switch (type) {
              case "SingleLineInput":
                return (
                  
                  <div className={classes.inputContainer}>
                    <label className={classes.lableDesign}>
                      {props.label}
                    </label>
                    <input type={props.type} name={props.label} className={classes.inputArea} placeholder={props.placeHolder} maxLength={props.maxLength} minLength={props.minLength} value={props.value} onChange={props.onChange} />
                  </div>)

              case "MultiLineInput":
                return (
                  <div className={classes.inputContainer}>
                    <label className={classes.lableDesign}>
                      {props.label}
                    </label>
                    <textarea
                      rows="10"
                      cols="30"
                      name="text"
                      onChange={props.onChange}
                      value={props.value}
                      className={classes.inputArea}
                      placeholder={props.placeholder}
                      maxLength={props.maxLength}
                    />
                  </div>)
                    case "Dropdown":
                      return (
                        <div className={classes.formContainer}>
                          <label className={classes.lableDesign}>{props.label}</label>
                          <select
                            onChange={props.onChange}
                            className={classes.inputArea_dropdown}
                            onInput={props.onInput}
                            value={props.value}
                          >
                            {DropdowonOptions()}
                          </select>
                        </div>
                      );

                   case "MultiLineSkills":
                    return (
                      <div className={classes.inputContainer_skill}>
                        <label className={classes.lableDesign}>
                          {props.label}
                        </label>
                        <textarea
                          rows="10"
                          cols="30"
                          name="text"
                          onChange={props.onChange}
                          value={props.value}
                          className={classes.inputArea}
                          placeholder={props.placeholder}
                          maxLength={props.maxLength}
                        />
                      </div>)

                  case "ColumnWiseInput":
                    return (
                      
                      <div className={classes.ColumnWiseInput}>
                        <label className={classes.lableDesign}>
                          {props.label}
                        </label>
                        <input type={props.type} name={props.label} className={classes.inputArea} placeholder={props.placeHolder} maxLength={props.maxLength} minLength={props.minLength} value={props.value} onChange={props.onChange} />
                      </div>)

              default:
                console.log("Invalid Input");
                break;
            }
          };
          
          return <div>{inputType(props.input)}</div>;
        }
export default RIAdminInput
