import React, { useState } from 'react';
import RIAdminInput from '../../Components/RIAdminInput';
import classes from './InsertNotification.module.css';
import {GrFormNext} from 'react-icons/gr'
import bredcrumb from "../../Components/BreadCrumb/BreadCrumb.module.css";
import { useHistory } from "react-router-dom"

const InsertNotification = () => {
    let loggedIn = localStorage.getItem("UserLoggedIn");
    let history = useHistory();

    const [read, setRead] = useState();
    const [text, setText] = useState();
    const [event, setEvent] = useState();
    const [showType, setShowType] = useState();
    const [notificationURL, setNotificationURL] = useState();
    const [appURL, setAppURL] = useState();

    const InsertAPI = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "UserID": "useridhdth1",
            "NotificationRead": read,
            "Event": event,
            "ShowType": showType,
            "Text": text,
            "NotificationUrl": notificationURL,
            "AppUrl": appURL
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rzadmin/InsertNotification", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    alert("Data Inserted Successfully")
                    setNotificationURL("")
                    setRead("")
                    setShowType("")
                    setText("")
                    setEvent("")
                    setAppURL("")
                }
            })
            .catch(error => console.log('error', error));
    }
            console.log("state:", read );

            
        {/* const pageInfo = {
            "currentPageName": "InsertNotification",
            "previousPage":"/Notifications",
            "previousPageName":"Notication",
            "slash":<GrFormNext/>
       } */}
    return (
        <>
     
        <div className={classes.MainContainer}>
           <ul className={bredcrumb.breadcrumb}>
               <li className={bredcrumb.breadcrumb_li}>
                <a href="/DashBoard" className={bredcrumb.breadcrumb_li_a}>Notification</a>
               </li>

           <li className={bredcrumb.breadcrumb_li}>InsertNotification</li>

           </ul>
           {/*  <BreadCrumb pageInfo={pageInfo}/> */}

            <div className={classes.radioinput}>
                <div className={classes.lableDesign}>Notification Read</div>
                <input type="radio" id="yes" name="select_work" onChange={() => setRead("1")} value={read} />&nbsp;
                <label for="yes">Yes</label>&nbsp;&nbsp;&nbsp;
                <input type="radio" id="no" name="select_work" onChange={() => setRead("0")} value={read} />&nbsp;
                <label for="no">No</label>
            </div>
            <RIAdminInput
                input={"SingleLineInput"}
                label={"Event"}
                onChange={(e) => setEvent(e.target.value)}
                value={event}
                maxLength={1000}
            />
            <RIAdminInput
                input={"SingleLineInput"}
                label={"Text"}
                onChange={(e) => setText(e.target.value)}
                value={text}
                maxLength={1000}
            />
            <RIAdminInput
                input={"SingleLineInput"}
                label={"Show Type"}
                onChange={(e) => setShowType(e.target.value)}
                value={showType}
                maxLength={1000}
            />
            <RIAdminInput
                input={"SingleLineInput"}
                label={"Notification URL"}
                onChange={(e) => setNotificationURL(e.target.value)}
                value={notificationURL}
                maxLength={1000}
            />
            <RIAdminInput
                input={"SingleLineInput"}
                label={"App URL"}
                onChange={(e) => setAppURL(e.target.value)}
                value={appURL}
                maxLength={1000}
            />
            <button className={classes.buttonBox} onClick={InsertAPI} > Submit</button>
        </div>
        
     </>

    );

}

export default InsertNotification
