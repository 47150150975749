import React, { useState } from 'react';
import RiHeadingText from '../Components/RiHeadingText';
import classes from "./ReportABug.module.css";
import bredcrumb from "../Components/BreadCrumb/BreadCrumb.module.css";
import {useHistory} from "react-router-dom";

const ReportABug = () => {

    let loggedIn = localStorage.getItem("UserLoggedIn");
    let history = useHistory();

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [countData, setCountData] = useState([])

    const ReportABugDetails = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "StartDate": (startDate),
            "EndDate": (endDate)
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rzadmin/AdminReportABug", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    setCountData(result.data)
                }
                 if (result.status_code  === 200 && result.status === "SUCCESS" && result.data===[]  ){
                   setCountData("No data found !")
                  }
                else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
                    alert("Please select start date and end date")
                  }
                else {
                    alert(result.message)
                }
            })
            .catch(error => console.log('error', error));
    }

 
    return (
        <>
      
           <div className={classes.MainContainer}>
            <ul className={bredcrumb.breadcrumb}>
                <li className={bredcrumb.breadcrumb_li}>
                  <a href="/" className={bredcrumb.breadcrumb_li_a}>DashBoard</a>
                </li>
                <li className={bredcrumb.breadcrumb_li}>ReportABug</li>

            </ul> 

         {/*  <BreadCrumb pageInfo={pageInfo}/>
          {/* <RiHeadingText title={'Report A Bug'} /> */}

            <div className= {classes.reportABug_heading}>ReportABug</div>

            <div className={classes.date_container}>
                <div className={classes.date_field}>From<input type="date" className={classes.date_input} onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                <div className={classes.date_field}>To<input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
                <button className={classes.search_button} onClick={ReportABugDetails}>Search</button>
            </div>
            
            <div className={classes.table_container}>
            {countData.length>0?
                <table className={classes.signup_details_table}>
                    <tr className={classes.table_row}>
                        <th className={classes.table_heading}>Image</th>
                        <th className={classes.table_heading}>Id</th>
                        <th className={classes.table_heading}>Full Name</th>
                        <th className={classes.table_heading}>Email</th>
                        <th className={classes.table_heading}>Mobile</th>
                        <th className={classes.table_heading}>BugType</th>
                        <th className={classes.table_heading}>Subject</th>
                        <th className={classes.table_heading}>Message</th>
                    </tr>

                    <tbody>
                        {countData.map((item, value) => {
                            return (
                                <tr className={classes.table_row} >
                                     <td className={classes.table_td}><img src={item.Image} className={classes.Img}/></td>
                                    <td className={classes.table_td}>{item.ID}</td>
                                    <td className={classes.table_td}>{item.Name}</td>
                                    <td className={classes.table_td}>{item.Email}</td>
                                    <td className={classes.table_td}>{item.MobileNumber}</td>
                                    <td className={classes.table_td}>{item.BugType}</td>
                                    <td className={classes.table_td}>{item.Subject}</td>
                                    <td className={classes.table_td}>{item.Message}</td>
                                </tr>
                            )
                        }

                        )}

                    </tbody>

                </table>
                :<div className={classes.NoDataFound}>Please select start date and end date</div>}
            </div>
           
        </div>
      
    </>

    )
}

export default ReportABug;
