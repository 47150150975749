import React, { useState } from "react";

//import "suneditor/dist/css/suneditor.min.css";
import classes from "../Screens/FaqCreate.module.css";
import TextEditor from "../Components/TextEditor";

const FaqCreate = () => {
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [selectType, setSelectType] = useState("");

  const FaqSave = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      Question: question,
      Answer: answer,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/SaveFaq",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          alert("Data Save Successfully");
          setQuestion("");
          setAnswer("");
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (

    <div className={classes.MainContainer}>
        

      <div className={classes.inputContainer}>
      <label  className={classes.label}>Question : </label>
       <input className={classes.question} type= "text" name = "Question"  onChange={(e) => setQuestion(e.target.value)}value={question} />
    </div>
<div className={classes.inputContainer}>
<label  className={classes.label}>Answer : </label>
</div>


<div className={classes.TextEditor}>
      <TextEditor onChangeContent={setAnswer} value={answer} />
      </div>

      <div className={classes.choosetype}>
          <label className={classes.label} >Choose Type: </label>
          <select name="type" id="type" onChange={(e)=>setSelectType(e.target.value)} value={selectType} className = {classes.typeDropdown}>
            
            <option value="Client">Client</option>
            <option value="Freelancer">Freelancer</option>
          </select>
        </div>

      <div className={classes.btnDiv}>
        <div className={classes.Button} onClick={FaqSave}>
          {" "}
          Save{" "}
        </div>
      </div>
    </div>
  );
};

export default FaqCreate;
