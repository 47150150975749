import HeaderForRightSec from '../UIcomp/HeadingForRightSec';
import classes from './ProfileInfoFreelancer.module.css'
import { TiTick } from 'react-icons/ti';
import { useLocation } from "react-router-dom";

const ProfileInfoFreelancer = (props) => {
   // console.log(props.ProfilePicture)
   const dateAndTimeHandler = (userDetailDate) => {
      var date = new Date(userDetailDate);
      return date.toString().slice(3, 25)
   }
   let location = useLocation();
   let state = location.state.signUpData;
   console.log("sss", state)
   return (
      <div className={classes.mainContainer}>

         <HeaderForRightSec headingText={"Freelancer Profile"} />
         <div className={classes.sectionContainer}>
            <div className={classes.containerWidthManage}>
               <div className={classes.contentSectionLeft}>
                  <div className={classes.userInfoContainer}>
                     <div className={classes.imgContainer}>
                        <img className={classes.profile} src={props.freelancerProfileData.ProfilePicture ? props.freelancerProfileData.ProfilePicture : "https://freesvg.org/img/abstract-user-flat-4.png"} alt="freelancerProfile" />
                     </div>
                     <div className={classes.individualSections}>
                        <div className={classes.mainHeading}> User Information</div>
                        <div className={classes.heading}> First Name : <span className={classes.response}>{props.freelancerProfileData.FirstName} </span></div>
                        <div className={classes.heading}> Last Name : <span className={classes.response}>{props.freelancerProfileData.LastName}  </span></div>
                        <div className={classes.heading}> Email : <span className={classes.response}>{props.freelancerProfileData.Email}{<TiTick color='green' />}  </span></div>
                        <div className={classes.heading}> Mobile : <span className={classes.response}>{props.freelancerProfileData.Mobile} {<TiTick color='green' />}  </span></div>
                        <div className={classes.heading}> Country : <span className={classes.response}>{props.freelancerProfileData.Country}</span></div>
                     </div>
                  </div>
               </div>
               <div className={classes.contentSectionLeft}>
                  <div>
                     <div className={classes.mainHeading}> Personal Information</div>
                     <div className={classes.heading}> Description : <span className={classes.response}>{props.freelancerProfileData.Description}  </span></div>
                     <div className={classes.heading}> State : <span className={classes.response}>{props.freelancerProfileData.State}  </span></div>
                     <div className={classes.heading}> City : <span className={classes.response}>{props.freelancerProfileData.City}  </span></div>
                     <div className={classes.heading}> Address : <span className={classes.response}>{props.freelancerProfileData.Address}  </span></div>
                     <div className={classes.heading}> Pin Code : <span className={classes.response}>{props.freelancerProfileData.Pincode}  </span></div>
                     <div className={classes.heading}> Gender : <span className={classes.response}>{props.freelancerProfileData.Gender}  </span></div>
                     <div className={classes.heading}> DOB : <span className={classes.response}>{props.freelancerProfileData.DOB}  </span></div>



                     <div className={classes.heading}>Certification</div>
                     {props.freelancerProfileData.Certification && props.freelancerProfileData.Certification.map((item) => {
                        return (
                           <div>
                              <div className={classes.heading}> CertificateAuthority : <span className={classes.response}>  {item.CertificateAuthority}</span></div>
                              <div className={classes.heading}> CertificateFile : <span className={classes.response}> {item.CertificateFile}</span></div>
                              <div className={classes.heading}> CertificateName : <span className={classes.response}> {item.CertificateName}</span></div>
                              <div className={classes.heading}> CertificateYear : <span className={classes.response}>  {item.CertificateYear}</span></div>

                           </div>
                        )
                     })}




                     <div className={classes.heading}> IsExperienced : <span className={classes.response}>{props.freelancerProfileData.IsExperienced === "0" ? <></> : <>Yes</>}   </span></div>
                     <div className={classes.heading}> Work Experience </div>
                     {props.freelancerProfileData.WorkExperience && props.freelancerProfileData.WorkExperience.map((item) => {
                        return (
                           <div>
                              <div className={classes.heading}>CurrentCity : <span className={classes.response}>{item.CurrentCity} </span></div>
                              <div className={classes.heading}>CurrentCountry : <span className={classes.response}>{item.CurrentCountry} </span></div>
                              <div className={classes.heading}>CurrentState : <span className={classes.response}>{item.CurrentState} </span></div>
                              <div className={classes.heading}>CurrentPincode : <span className={classes.response}>{item.CurrentPincode} </span></div>
                              <div className={classes.heading}>IsFreelancer : <span className={classes.response}>{item.IsFreelancer === "0" ? <></> : <>yes</>} </span></div>
                              <div className={classes.heading}>WorkExperienceComanyName : <span className={classes.response}>{item.WorkExperienceComanyName} </span></div>
                              <div className={classes.heading}>WorkExperienceCompanyAddress : <span className={classes.response}>{item.WorkExperienceCompanyAddress} </span></div>
                              <div className={classes.heading}>WorkExperienceJobType : <span className={classes.response}>{item.WorkExperienceJobType} </span></div>
                              <div className={classes.heading}>WorkExperienceYears : <span className={classes.response}>{item.WorkExperienceYears} </span></div>
                           </div>
                        )
                     })}

                     <div className={classes.heading}>IsAvailable : <span className={classes.response}>{props.freelancerProfileData.IsAvailable}  </span></div>

                     <div className={classes.heading}> AvailableCity : <span className={classes.response}>{props.freelancerProfileData.AvailableCity}  </span></div>
                     <div className={classes.heading}> AvailableCountry : <span className={classes.response}>{props.freelancerProfileData.AvailableCountry}  </span></div>
                     <div className={classes.heading}>AvailablePincode : <span className={classes.response}>{props.freelancerProfileData.AvailablePincode}  </span></div>
                     <div className={classes.heading}>AvailableState : <span className={classes.response}>{props.freelancerProfileData.AvailableState}  </span></div>

                     <div>
                        <div className={classes.heading}>Work Model</div>
                        <div className={classes.heading}> IsContractual : <span className={classes.response}>{props.freelancerProfileData.IsContractual === "0" ? <></> : <>IsContractual</>}</span></div>
                        <div className={classes.heading}>IsMonthly : <span className={classes.response}>{props.freelancerProfileData.IsMonthly === "0" ? <></> : <>IsMonthly</>}  </span></div>
                        <div className={classes.heading}>IsOnCommision : <span className={classes.response}>{props.freelancerProfileData.IsOnCommision === "0" ? <></> : <>IsOnCommision</>}  </span></div>
                        <div className={classes.heading}>IsOneTime : <span className={classes.response}>{props.freelancerProfileData.IsOneTime === "0" ? <></> : <>IsOneTime</>}  </span></div>
                     </div>
                     <div className={classes.heading}>Education</div>
                     {props.freelancerProfileData.Education && props.freelancerProfileData.Education.map((item) => {
                        return (
                           <div>
                              <div className={classes.heading}>College :<span className={classes.response}>{item.College}</span></div>
                              <div className={classes.heading}>Degree :<span className={classes.response}>{item.Degree}</span></div>
                              <div className={classes.heading}>year : <span className={classes.response}>{item.Year}</span></div>
                           </div>
                        )
                     })}

                    


                     {props.freelancerProfileData.SocialMedia && props.freelancerProfileData.SocialMedia.map((item) => {
                        return (
                           <div>
                              <div className={classes.heading}>SocialMedia Name : <span className={classes.response}> {item.Name}</span></div>
                              <div className={classes.heading}>SocialMedia Type : <span className={classes.response}> {item.Type}</span></div>
                              <div className={classes.heading}>SocialMedia URL : <span className={classes.response}><a href={item.URL}>{item.URL}</a></span></div>

                           </div>
                        )
                     })}
                     <div className={classes.langaugeSection}>
                        <div className={classes.heading}>Languages Name : </div>

                        {props.freelancerProfileData.Languages && props.freelancerProfileData.Languages.map((item) => {
                           return (
                              <div className={classes.languages}>
                                 <span className={classes.response}>{item.Name} - {item.Fluency},</span>

                              </div>
                           )
                        })}
                     </div>
                     {props.freelancerProfileData.Portfolio && props.freelancerProfileData.Portfolio.map((item) => {
                        return (
                           <div>

                              <div className={classes.heading}>Portfolio :  <span className={classes.response}>{item.Portfolio}  </span></div>
                              <div className={classes.heading}>Portfolio Type :  <span className={classes.response}>{item.PortfolioType}  </span></div>
                              <div className={classes.heading}>Portfolio URL:  <span className={classes.response}><a href={item.PortfolioURL}>{item.PortfolioURL} </a> </span></div>
                           </div>
                        )
                     })}
                     <div className={classes.heading}>Video Profile : <span className={classes.response}><a href={props.freelancerProfileData.VideoProfile}>{props.freelancerProfileData.VideoProfile} </a> </span></div>
                     <div className={classes.SkillsSection}>
                        {props.freelancerProfileData.Skills && props.freelancerProfileData.Skills.map((item) => {
                           return (


                              <div className={classes.Skills}>
                                 <span className={classes.response}>{item.Skills}  </span>
                              </div>
                           )
                        })}
                     </div>
                     <div className={classes.heading}>Updated Date :  <span className={classes.response}>{dateAndTimeHandler(props.freelancerProfileData.UpdatedAt)}  </span></div>
                  </div>
                  <div className={classes.rightSection}>
                     <div className={classes.heading}>AlternateMobile : <span className={classes.response}>{props.freelancerProfileData.AlternateMobile}  </span></div>
                     <div className={classes.heading}> WhatAppNumber : <span className={classes.response}>{props.freelancerProfileData.WhatsAppNumber}  </span></div>
                     <div className={classes.heading}> AlternateEmail : <span className={classes.response}>{props.freelancerProfileData.AlternateEmail}  </span></div>
                  </div>
                  {
                     props.IsCompany === "1" ? <div className={classes.IscompanyTag}>Company</div>
                        : <div className={classes.IscompanyTag}>Individual</div>
                  }
               </div>
            </div>
            <div className={classes.containerWidthManage}>
               <div className={classes.contentSectionLeft}>
                  {
                     props.IsCompany === "1" ? <>

                        <div className={classes.heading}> Company Name :  <span className={classes.response}>{props.freelancerProfileData.CompanyName}  </span></div>
                        <div className={classes.heading}> Company Website :  <span className={classes.response}>{props.freelancerProfileData.CompanyWebsite}  </span></div>
                        <div className={classes.heading}> Company Country :  <span className={classes.response}>{props.freelancerProfileData.CompanyCountry}  </span></div>
                        <div className={classes.heading}> Company State :  <span className={classes.response}>{props.freelancerProfileData.CompanyState}  </span></div>
                        <div className={classes.heading}> Company City :  <span className={classes.response}>{props.freelancerProfileData.CompanyCity}  </span></div>
                        <div className={classes.heading}> Company Address :  <span className={classes.response}>{props.freelancerProfileData.CompanyAddress}  </span></div>
                        <div className={classes.heading}> GST Number :  <span className={classes.response}>{props.freelancerProfileData.GSTNumber}  </span></div>
                        <div className={classes.heading}> Company Logo :  <span className={classes.response}> {props.freelancerProfileData.CompanyLogo ? <img className={classes.profile} src={props.freelancerProfileData.CompanyLogo} alt="logo" /> : <></>}  </span></div>



                     </>
                        : <></>
                  }
               </div>
            </div>

         </div>

      </div>

   )

}

export default ProfileInfoFreelancer; 