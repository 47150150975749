import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import classes from './Login.module.css'
import { useHistory } from "react-router-dom";

const Login = () => {

    const [data, setData] = useState({
        username: '',
        password: ""
    });


    let history = useHistory();


    const handleChange = (e) => {
        let key = e.target.name;
        let val = e.target.value;
       setData({ ...data, [key]: val });
    }

    const submitData = (e) => {
        e.preventDefault();
       
    }

    const onLoginSuccess = (res) => {
        console.log("res:", res)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Token": res.tokenObj.access_token
        });
 
       
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rzadmin/VerifyGoogleAuth", requestOptions)
            .then(response => response.json())
            .then(result => {
                
                if(result.status_code === 200 && result.status === "SUCCESS"){
                    sessionStorage.setItem("email",res.profileObj.email)
                    localStorage.setItem('UserLoggedIn',true)
                    sessionStorage.setItem('UserLoggedIn',true)
                    history.push("/DashBoard")
                }else{
                    console.log("error");
                {/* alert(result.message) */}
                }
            })
            .catch(error => console.log('error', error));
    };

    const onLoginFailure = () => {
       
    };

  
    return (
        <>

            <div className={classes.login_form} >
                <div><img src="/Rozgaar_Black_Logo.svg" className={classes.logoImage} /></div>

             

                <h3 className={classes.signIn}>Login Form</h3>

                <form className={classes.login_form_fld}>

                    <input type="text" name="username" value={data.username} onChange={()=>handleChange} className={classes.login_field} placeholder="User Name" /> <br /> <br />
                    <input type="password" name="password" value={data.password} onChange={()=>handleChange} className={classes.login_field} placeholder="Password" /> <br /> <br />

                    <button onClick={submitData} className={classes.login_btn}>login</button>

                </form>

                <div className={classes.other_signIn}>
                    <span> Or </span>
                    <span> Log In with</span>
                </div>

                <div className={classes.google_signIn}>
                    <GoogleLogin
                       //  clientId={"878789164296-qpuam31qa0fl6h767akhldgpe2c6nnha.apps.googleusercontent.com"}

                //   clientId={"846687682963-769809rmhljqa1qecvfu4en0v2ln94ff.apps.googleusercontent.com"}

                        //    Live clientId
             clientId={"503774238711-hneu6nndp2v02ahs163rhibbbdpvft0c.apps.googleusercontent.com"}
                        // Live clientId
                        buttonText="Google"
                        onSuccess={onLoginSuccess}
                        onFailure={onLoginFailure}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={false}
                        className={classes.gsin}
                    />
                </div>
            </div>
        </>
    );

}

export default Login;