import React, {useState, useEffect}  from 'react'
import { Link } from 'react-router-dom';
import classes from "./DashBoardOne.module.css"
import bredcrumb from "../Components/BreadCrumb/BreadCrumb.module.css";
import Accordion from "../Components/Accordion";

const  DashBoardOne = ()  => {

  let todayDate = new Date().toISOString().substr(0, 10);

    const [startDate, setStartDate] = useState(todayDate);
    const [endDate, setEndDate] = useState(todayDate);
    const [signUpCount, setSignUpCount] = useState([]);
  
    useEffect(()=> {
      SignupCount();

    }, []);

    const SignupCount = () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
          "StartDate": (startDate),
          "EndDate": (endDate)
      });

      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
      };

      fetch("https://api-preview.rozgaarindia.com/api/rzadmin/GetSignupCount", requestOptions)
          .then(response => response.json())
          .then(result => {
             setSignUpCount(result.data.length);
          })
          .catch(error => console.log('error', error));
  }

  return (
    
    <div className={classes.MainContainer}>

        <ul className={bredcrumb.breadcrumb}>
            <li className={bredcrumb.breadcrumb_li}>
              <a href="/DashBoardOne" className={bredcrumb.breadcrumb_li_a}>DashBoard</a>
            </li>
        </ul>  

            <Accordion heading="Signup Detail-Today" content={
              <>
                <div className={classes.display}>
                  {signUpCount > 0 ? <>
                    <Link to={{ pathname : "/SignUPToday/", state: {todayDate: todayDate } }}  className={classes.dasboard_link}>Sign Up <span className={classes.signCount}>{signUpCount}</span> </Link>
                  </>
                  : <span className={classes.dasboard_link}>Sign Up <span className={classes.signCount}>{signUpCount}</span> </span>
                   }
                  <Link to="/" className={classes.dasboard_link}>Google </Link>
                  <Link  className={classes.dasboard_link}>Facebook </Link>
                </div>
              </>
            } />

            <Accordion heading="Profile Status-Today" content={  
              <>
                <div className={classes.display}>
                  <button className={classes.dasboard_button}>Draft </button>
                  <button className={classes.dasboard_button}>Under Review</button>
                  <button className={classes.dasboard_button}>Live</button>
                </div>
              </>
            } />

           <Accordion heading="Package-Today" content={  
              <>
                <div className={classes.display}>
                  <button className={classes.dasboard_button}></button>
                  <button className={classes.dasboard_button}></button>
                  <button className={classes.dasboard_button}></button>
                </div> 
                  
              </>

            } />
    </div>
   
   
       
  )
}

export default DashBoardOne;