import React, { useState } from "react";
import classes from "./accordion.module.css";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Accordion = ({ heading, content }) => {
  const [isActive, setIsActive] = useState(false);
  
  return (
    <li className={classes.accordion_item}>
      <div className={classes.accordion_toggle} onClick={() => setIsActive(!isActive)}>
        <h3>{heading}</h3><span>{isActive ? <div><IoIosArrowUp size={20} /></div> : <div><IoIosArrowDown size={20} /></div>}</span>
      </div>
      {isActive && <div className={classes.accordion_content}>{content}</div>}
    </li>
  );
};

export default Accordion;