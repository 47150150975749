import HeaderForRightSec from '../UIcomp/HeadingForRightSec';
import classes from './ProfileInfoClient.module.css'
import { TiTick } from 'react-icons/ti';

const ProfileInfoClient = (props) =>{
// console.log(props.clientProfileData.ProfilePicture)

const dateAndTimeHandler  =(userDetailDate) =>{
  var date = new Date(userDetailDate);
      return date.toString().slice(3,25)
}


    return(
        <div className={classes.mainContainer}> 

        <HeaderForRightSec headingText={"Client Profile"} />

    <div className={classes.sectionContainer}>
      <div className={classes.containerWidthManage}> 
       <div className={classes.contentSectionLeft}>
        <div className={classes.userInfoContainer}>

        <div className={classes.imgContainer}>
         <img className={classes.profile} src={props.clientProfileData.ProfilePicture ? props.clientProfileData.ProfilePicture : "https://freesvg.org/img/abstract-user-flat-4.png"} alt="clientProfile" />
        </div>

        <div className={classes.individualSections}> 
           <div className={classes.mainHeading}> User Information</div>
           <div className={classes.heading}> UserName : <span className={classes.response}>{props.clientProfileData.UserName} </span></div>
           <div className={classes.heading}> First Name : <span className={classes.response}>{props.clientProfileData.FirstName} </span></div>
           <div className={classes.heading}> Last Name : <span className={classes.response}>{props.clientProfileData.LastName}  </span></div>
           <div className={classes.heading}> Email : <span className={classes.response}>{props.clientProfileData.Email}{ <TiTick color='green'/>}  </span></div>
           <div className={classes.heading}> Mobile : <span className={classes.response}>{props.clientProfileData.Mobile} { <TiTick color='green'/>}  </span></div>
           <div className={classes.heading}> Country : <span className={classes.response}>{props.clientProfileData.Country}  </span></div>
        </div>
        
        </div>
        </div>

        <div className={classes.contentSectionLeft}>

      <div>
        <div className={classes.mainHeading}> Personal Information</div>
        <div className={classes.heading}> State : <span className={classes.response}>{props.clientProfileData.State}  </span></div>
        <div className={classes.heading}> City : <span className={classes.response}>{props.clientProfileData.City}  </span></div>
        <div className={classes.heading}> Address : <span className={classes.response}>{props.clientProfileData.Address}  </span></div>
        <div className={classes.heading}> Pin Code : <span className={classes.response}>{props.clientProfileData.PinCode}  </span></div>
        <div className={classes.heading}> Gender : <span className={classes.response}>{props.clientProfileData.Gender}  </span></div>
        <div className={classes.heading}> DOB : <span className={classes.response}>{props.clientProfileData.DOB}  </span></div>
        <div className={classes.heading}>Facebook Url :  <span className={classes.response}><a href={props.clientProfileData.FacebookUrl}>{props.clientProfileData.FacebookUrl}</a>  </span></div>
        <div className={classes.heading}>Linkedin Url :  <span className={classes.response}><a href={props.clientProfileData.LinkedinUrl}>{props.clientProfileData.LinkedinUrl}</a>  </span></div>
        <div className={classes.heading}>Twitter Url :  <span className={classes.response}><a href={props.clientProfileData.TwitterUrl}>{props.clientProfileData.TwitterUrl}</a> </span></div>
        <div className={classes.heading}>Instagram Url :  <span className={classes.response}><a href={props.clientProfileData.InstagramUrl}>{props.clientProfileData.InstagramUrl}</a>  </span></div>
        <div className={classes.heading}>Other Social Url :  <span className={classes.response}><a href={props.clientProfileData.OthersSocialUrl}>{props.clientProfileData.OthersSocialUrl}</a>  </span></div>
        <div className={classes.heading}>Languages :  <span className={classes.response}>{props.clientProfileData.Languages}  </span></div>
        <div className={classes.heading}>Updated Date :  <span className={classes.response}>{dateAndTimeHandler(props.clientProfileData.UpdatedAt)}  </span></div>
      </div>
       
       <div className={classes.rightSection}>
       
        <div className={classes.heading}>AlternateMobile : <span className={classes.response}>{props.clientProfileData.AlternateMobile}  </span></div>
        <div className={classes.heading}> WhatAppNumber : <span className={classes.response}>{props.clientProfileData.WhatsAppNumber}  </span></div>
        <div className={classes.heading}> AlternateEmail : <span className={classes.response}>{props.clientProfileData.AlternateEmail}  </span></div>
        </div>
    {
       props.IsCompany==="1"?<div className={classes.IscompanyTag}>Company</div>
       :<div className={classes.IscompanyTag}>Individual</div>
    }

    
    
    </div>
</div>

<div className={classes.containerWidthManage}> 
<div className={classes.contentSectionLeft}> 

{
      props.IsCompany ==="1"?<>
  
        <div className={classes.heading}> Company Name :  <span className={classes.response}>{props.clientProfileData.CompanyName}  </span></div>
        <div className={classes.heading}> ContactPerson Name :  <span className={classes.response}>{props.clientProfileData.ContactPersonName}  </span></div>
        <div className={classes.heading}> ContactPerson Mobile :  <span className={classes.response}>{props.clientProfileData.ContactPersonMobile}  </span></div>
        <div className={classes.heading}> ContactPerson Email :  <span className={classes.response}>{props.clientProfileData.ContactPersonEmail}  </span></div>
        <div className={classes.heading}>ContactPerson Designation :  <span className={classes.response}>{props.clientProfileData.ContactPersonDesignation}  </span></div>
        <div className={classes.heading}> Company Website :  <span className={classes.response}>  {props.clientProfileData.CompanyWebsite}  </span></div>
        <div className={classes.heading}> Company Country :  <span className={classes.response}>{props.clientProfileData.CompanyCountry}  </span></div>
        <div className={classes.heading}> Company State :  <span className={classes.response}>{props.clientProfileData.CompanyState}  </span></div>
        <div className={classes.heading}> Company City :  <span className={classes.response}>{props.clientProfileData.CompanyCity}  </span></div>
        <div className={classes.heading}> Company Address :  <span className={classes.response}>{props.clientProfileData.CompanyAddress}  </span></div>
        <div className={classes.heading}> Company PinCode :  <span className={classes.response}>{props.clientProfileData.CompanyPincode}  </span></div>
        <div className={classes.heading}> Pan Number :  <span className={classes.response}>{props.clientProfileData.PanNumber}  </span></div>
        <div className={classes.heading}> GST Number :  <span className={classes.response}>{props.clientProfileData.GSTNumber}  </span></div>
        <div className={classes.heading}> Company Logo :  <span className={classes.response}> {props.clientProfileData.CompanyLogo ? <img className={classes.profile} src=  {props.clientProfileData.CompanyLogo} alt="logo" /> : <></> }  </span></div>
        <div className={classes.heading}>Company Industry :  <span className={classes.response}>{props.clientProfileData.CompanyIndustry}  </span></div>
     
     
      </>
       :<></>
    }
       </div>
</div>
        
   </div>

</div>

)

}

export default ProfileInfoClient; 