import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from "react-router-dom";
import classes from "./MainRoute.module.css";
import SideBar from '../Components/SideBar'
import Login from '../Screens/Login/Login';
import SignUp from '../Screens/Client/ClientSignUp';
import PostARequirement from '../Screens/Client/PostARequirement';
import UserDetail from '../Components/PostARequirement/UserDetail';
import PartnerPages from '../Screens/PartnerPages/PartnerPages';
// import BulkHiring from '../Screens/BulkHiring/BulkHiring';
import CreateArticle from '../Screens/CreateArticle';
import ReportABug from '../Screens/ReportABug';
import ViewUserDetail from '../Components/PostARequirement/ViewUserDetail';
import InsertNotification from '../Screens/Notification/InsertNotification';
import Notifications from '../Screens/Notification/Notifications';
import LandingPage from "../Screens/LandingPage/LandingPage";
import LandingPageAdd from "../Screens/LandingPage/LandingPageAdd";
import LandingPageView from "../Screens/LandingPage/LandingPageView";
import InsertPartner from '../Screens/PartnerPages/InsertPartner';
import UpdatePartnerPage from '../Screens/PartnerPages/UpdatePartnerPage';
import TrustedBy from '../Screens/TrustedBy/TrustedBy';
//import FrequentlyAskedQuestions from '../Screens/FrequentlyAskedQuestion/FrequentlyAskedQuestions';
import KycForm from '../Screens/Freelancer/KycForm'
// import FreelancerProfile from '../Screens/FreelancerProfile/FreelancerProfile'
//import FaqModal from '../Screens/FrequentlyAskedQuestion/FaqModal';
import ViewKycSingleDetail from '../Screens/Freelancer/KycForm/ViewKycSingleDetail';
import Article from '../Screens/Client/Article';
import ViewArticle from '../Screens/Client/ViewArticle';
import EditArticle from '../Screens/Client/EditArticle';
import LogOut from '../Screens/Login/LogOut';
import DashBoardOne from '../Screens/DashBoardOne';
import SignUpDetail from '../Screens/SignUpDetail'
import SignUPToday from '../Screens/SignUpToday'
import BulkHiringDetail from '../Screens/BulkHiring/BulkHiringDetail'
// import UserDetailInfo from '../Screens/UserDetailInfo';
import FreelancerSignUp from '../Screens/Freelancer/FreelancerSignUp';
import PaymentList from '../Screens/PaymentList';
import FreelancerPackagePayment from '../Screens/Freelancer/FreelancerPackagePayment';
import ClientAddonPayments from '../Components/ClientOptions/ClientAddonPayments';
import ClientReqPayment from '../Components/ClientOptions/ClientReqPayment';
import ClientFreelancerDeposit from '../Components/ClientOptions/ClientFreelancerDeposit';
import ClientLogin from '../Screens/Client/ClientLogin';
import ClientProfile from '../Screens/Client/ClientProfile';
import FreelancerProfile from '../Screens/Freelancer/FreelancerProfile';
import FreelancerLogin from '../Screens/Freelancer/FreelancerLogin';
import UserDetailInfo from '../Screens/UserDetailInfo';
import ContactUs from '../Screens/Client/ContactUs';
import FrequentlyAskedQuestion from '../Screens/Faq/FrequentlyAskedQuestion';
import FaqCreate from '../Screens/FaqCreate';
import UpdateFaq from '../Screens/Faq/UpdateFaq';
import BulkHiring from '../Screens/Client/BulkHiring';
import FreelancerCredit from '../Screens/Freelancer/FreelancerCredit';
import OtpList from '../Screens/OTP/OtpList';
import OldWildCard from '../Screens/olddata/OldWildCard';
import ClientSearch from '../Screens/Search User/ClientSearch'
import FreelancerSearch from '../Screens/Search User/FreelancerSearch';
import ViewChat from '../Components/UserDetails/ViewChat';
import FreeJobAlert from '../Screens/freejobalert/FreeJobAlert';
import Chat from "../Screens/chat/Chat";
import FreelancerContactUs from '../Screens/Freelancer/FreelancerContactUs';
import UserEditInfo from '../Screens/Client/UserEditInfo';


const MainRoute = () => {

     const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    useEffect(() => {
        sessionStorage.getItem("email")
            ? setIsUserLoggedIn(true)
            : setIsUserLoggedIn(false);
    }, []);
    

   
  
let UserLoggedIn= localStorage.getItem("UserLoggedIn")


    return (

        <div className={classes.MainContainer}>

            <Router>
              <Route exact path="/"> <Login /> </Route>

            <PublicRedirect/>
                <div className={classes.sidebar}>
                        <SideBar />
                    <Switch>
                     
                           
                        <Route path="/ViewKycSingleDetail">
                            <ViewKycSingleDetail />
                        </Route>
                        

                        <Route path="/Article">
                            <Article />
                        </Route>

                     <Route path="/createArticle">
                            <CreateArticle />
                     </Route> 

                        <Route path="/ViewArticle/:articleId">
                            <ViewArticle />
                        </Route>

                        <Route path="/EditArticle/">
                            <EditArticle />
                        </Route>

                        <Route path="/TrustedBy">
                            <TrustedBy />
                        </Route>

                        

                      

                        <Route path="/KycForm">
                            <KycForm />
                        </Route>

                        <Route path="/PostARequirement">
                            <PostARequirement />
                        </Route>

                        <Route path="/ViewUserDetail/:id">
                            <ViewUserDetail />
                        </Route>

                        <Route path="/PartnerPages">
                            <PartnerPages />
                        </Route>

                        <Route path="/InsertPartner">
                            <InsertPartner />
                        </Route>

                        <Route path="/UpdatePartnerPage/:Name/:id">
                            <UpdatePartnerPage />
                        </Route>

                   {/*     <Route path="/BulkHiring">
                            <BulkHiring />
                         </Route> */}

                        <Route path="/ContactUs">
                            <ContactUs />
                        </Route>

                        <Route path="/ReportABug">
                            <ReportABug />
                        </Route>

                        <Route path="/InsertNotification">
                            <InsertNotification />
                        </Route>

                        <Route path="/Notifications">
                            <Notifications />
                        </Route>

                        <Route path="/LandingPages">
                            <LandingPage />
                        </Route>

                        <Route path="/Landingpage/LandingPageView/:pageId">
                            <LandingPageView />
                        </Route>

                        <Route path="/LandingPageAdd">
                            <LandingPageAdd />
                        </Route>

                         
                        <Route path="/Logout">
                            <LogOut />
                        </Route>
                        <Route path="/ViewChat/:g_id">
                            <ViewChat />
                        </Route>

                        <Route path="/UserDetailInfo/:id">
                            <UserDetailInfo />
                        </Route>

                        <Route path="/SignUp">
                            <SignUp /> 
                        </Route>

                        <Route path="/SignUpDetail/:id">
                            <SignUpDetail />
                        </Route>
                        <Route path="/FreelancerSignUp">
                            <FreelancerSignUp />
                        </Route>

                        <Route path="/DashBoard">

                            <DashBoardOne />
                        </Route>

                        {/* <Route path="/userDetailInfo/:RequirementID">

                            <UserDetailInfo />
                        </Route> */}

                        <Route path="/SignUPToday">
                            <SignUPToday />
                        </Route>
                        
                         {/*    <Route path="/BulkHiringDetail/:id">
                                  <BulkHiringDetail />
                            </Route> */}
                       

                            <Route path="/paymentlist">
                            <PaymentList />
                        </Route>

                        <Route path="/FreelancerPackagePayment">
                            <FreelancerPackagePayment />
                        </Route>

                        <Route path="/ClientAddonPayments">
                            <ClientAddonPayments />
                        </Route>

                        <Route path="/ClientReqPayment">
                            <ClientReqPayment />
                        </Route>
                        
                        <Route path="/ClientFreelancerDeposit">
                            <ClientFreelancerDeposit />
                        </Route>

                        <Route path="/ClientLogin">
                            <ClientLogin />
                        </Route>

                        <Route path="/FreelancerLogin">
                            <FreelancerLogin />
                        </Route>
                       

                        <Route path="/ClientProfile">
                            <ClientProfile />
                        </Route> 

                        <Route path="/FreelancerProfile">
                            <FreelancerProfile/>
                        </Route>

                     <Route path="/FrequentlyAskedQuestion">
                            <FrequentlyAskedQuestion />
                        </Route> 

                        <Route path="/FaqCreate">
                            <FaqCreate />
                        </Route>

                        <Route path="/BulkHiring">
                            <BulkHiring/>
                        </Route>

                        <Route path="/FreelancerCredit">
                            <FreelancerCredit/>
                        </Route>

                        <Route path="/freelancerContactus">
                            <FreelancerContactUs />
                        </Route>
                        
                        <Route path="/OtpList">
                            <OtpList/>
                        </Route>

                        <Route path="/OldWildCard">
                            <OldWildCard/>
                        </Route>

                        <Route path="/ClientSearch">
                            <ClientSearch/>
                        </Route>

                        <Route path="/FreelancerSearch">
                            <FreelancerSearch/>
                        </Route>


                        <Route path="/FreeJobAlert">
                            <FreeJobAlert/>
                        </Route>

                        <Route path="Chat">
                            <Chat />
                        </Route>
                       
                        <Route path="/UpdateFaq/:id">
                            <UpdateFaq />
                        </Route> 

                        <Route path="/UserEditInfo/:ReqId/:userID" >
                            <UserEditInfo/>
                        </Route> 

                     

                    </Switch>


                </div>

            </Router>

        </div>
    )
}

 const PublicRedirect = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    
    useEffect(() => {
        if (!sessionStorage.getItem("email")) {
            history.push("/");
        }
    }, [pathname]);
    return <></>;
}; 
export default MainRoute;
