import React, { useState, useRef, useEffect } from 'react';
import classes from '../../Components/UIcomp/RightTable.module.css'
import { useHistory, Link } from 'react-router-dom';
import HeaderForRightSec from '../../Components/UIcomp/HeadingForRightSec';
import ReactExport from "react-data-export";
import TablesExcelDir from '../../Components/ExcelDir/TablesExcelDir';
import { TiTick } from 'react-icons/ti';
import Loader from 'react-loader-spinner';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ClientProfile = () => {

    let loggedIn = localStorage.getItem ("UserLoggedIn")
    let history = useHistory();
    const tableRef = useRef(null);
    
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoader, setIsLoader] = useState(false);
    const[totalCount, setTotalCount] = useState("")
    const [allData, setAllData] = useState([])

    const handleNextbtn = () => {
      setCurrentPage(currentPage + 1);
      setIsLoader("next")
    };
  
    const handlePrevbtn = () => {
      setCurrentPage(currentPage - 1);
      setIsLoader('prev');
    };

    useEffect(() => {
        var date = new Date();
    date.setDate(date.getDate() - 7);

    let startDateformat =(date.getDate().toString.length < 2 ? "0" + date.getDate() : date.getDate())

    const monthFormat = (date.getMonth()+1).length > 1 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)
    var finalDate = date.getFullYear() + '-'+  monthFormat + '-' + startDateformat
    setStartDate(finalDate)
    
    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate())

    let endDateformat = todayDate.getDate().toString.length < 2 ? "0" +todayDate.getDate() : todayDate.getDate()
    const todaymonthFormat = (todayDate.getMonth()+1).length > 1 ? (todayDate.getMonth()+1) : "0" + (todayDate.getMonth()+1)
    var finalToday = todayDate.getFullYear() + '-'+  todaymonthFormat + '-' + endDateformat
    setEndDate(finalToday)
    getClient(finalDate ,finalToday,currentPage)
    }, []);

    useEffect(() => {
      if (startDate && endDate && currentPage)
      {getClient(startDate, endDate, currentPage)}
      getClient()
  }, [currentPage])
  
    const getClient = (end ,today,page ) => {
      // setIsLoader(true)

        var myHeaders = new Headers();
           myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
           myHeaders.append("Content-Type", "application/json");
        
           var raw = JSON.stringify({
            "StartDate":  end? end : (startDate),
            "EndDate": today ?  today : (endDate),
            "Page":(end&&page)?page:"",
          });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch("https://api-preview.rozgaarindia.com/api/client/ClientProfileList", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.status_code === 200 && result.status === "SUCCESS"&&!end) {
              setAllData(result.data)
          }

           else if (result.status_code === 200 && result.status === "SUCCESS"&&end) {
                setData(result.data)
                setTotalCount(result.count)
            }
            else if (result.status_code  === 200 && result.status === "SUCCESS" && result.data===[]  ){
               setData("No data found !")
              }
            else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
                alert("Please select start date and end date")
              }
            else {
                alert(result.message)
            }
      
        })
        .catch(error => console.log('error', error))
        .finally(()=>{
           setIsLoader(false)
        });
}
   
      const dateAndTimeHandler  =(profileDate) =>{

        var date = new Date(profileDate);
          return date.toString().slice(3,25)
    }

    
    return (
        <>
      
        <div className={classes.MainContainer}>

           <HeaderForRightSec headingText={" Client Profile"} />

                <div className={classes.date_container}>
                 <div className={classes.date_field}>From<input type="date" className={classes.date_input}    onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                 <div className={classes.date_field}>To<input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
                 <div className={classes.searchButton} onClick={()=>{setCurrentPage(1);getClient(startDate, endDate, currentPage)}}> Search </div>
                 <div className={classes.recordLength}> Data Count {totalCount} </div>
                </div>

                <TablesExcelDir tableData={allData} tableType={"Client"} />
                <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ?
                    <>
                    
                        
                        <button   onClick={handlePrevbtn} className={classes.buttonone}>
                       { isLoader=="prev"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Prev</>}
                            </button> 
                    
                    </> 
                   : <span className={classes.buttononePre}>Prev</span>}

                     <span className={classes.CurrentPageText}>{currentPage}</span>
                    {(data.length >= 20 )? 
                    <>
                  
                       
                        <button  onClick={handleNextbtn} className={classes.buttonone}>
                        {isLoader=="next"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Next</>}
                        </button>
                    
                    </> : 
                    <span className={classes.buttononePre}>Next</span>}
                    
                </div>

          
            {/*    <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                    {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                    {data.length >=20 ? <span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span> : <span className={classes.CurrentPageText}>{currentPage}</span>}
                    {data.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
    </div> */}

            {data.length > 0 ? <div className={classes.table_container}>
                <table className={classes.signup_details_table} id="table-to-xls">
                    <tr className={classes.table_row}>

                      <th className={classes.table_heading}>S.No</th>
                       <th className={classes.table_heading}>Country</th>
                       <th className={classes.table_heading}>User Name</th>
                       <th className={classes.table_heading}>Full Name</th>
                       <th className={classes.table_heading}>Email</th>
                       <th className={classes.table_heading}>Mobile </th>
                       <th className={classes.table_heading}>Co/In</th>
                       <th className={classes.table_heading}>Date</th>

                    </tr>

                 
                    <tbody>
                        {data.map((item, index) => {
                          return (
                                <tr className={classes.table_row} >
                                { <td className={classes.table_td}>{index + 1}</td> }
                                  <td className={classes.table_td}>{item.Country}</td>
                                  <td className={classes.table_td}><div className={classes.Tagsection}><Link to={"/UserDetailInfo/" + item.UserId}  className={classes.userName}>{item.UserName}</Link>
                                  {item.isOldUser==="True" && <div className={classes.Tag}>Old</div>}
                                  </div></td>
                                  <td className={classes.table_td}>{item.FirstName} {item.LastName}
                                 
                                  </td>
                                  <td className={classes.table_td}>{item.Email} {item.EmailVerified && <TiTick color='green'/>}</td>
                                  <td className={classes.table_td}>{item.Mobile?<>{item.Mobile} {item.MobileVerified && <TiTick color='green' />}</>:"N/A"} </td>
                                  <td className={classes.table_td}>{item.IsCompany==="1" ? <>{item.CompanyName}</>: "Individual"}</td>
                                  <td className={classes.table_td}>{dateAndTimeHandler(item.UpdatedAt)} </td>
                  
                                </tr>

                            )

                         }

                        )} 

                    </tbody>

                </table>

            </div>

            :
             <div className={classes.NoDataFound}>Please select start date and end date</div>}
               
        </div>
      
    </>

);


}

export default ClientProfile;
