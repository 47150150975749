import React, { useState, useEffect } from "react";
import SunEditor from "suneditor-react";
import { AiFillCopy } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import "suneditor/dist/css/suneditor.min.css";
import copy from "copy-to-clipboard";
import classes from '../Components/UIcomp/RightTable.module.css'
import HeaderForRightSec from "../Components/UIcomp/HeadingForRightSec";
 

const CreateArticle = () => {
  
  const [multipleImages, setmultipleImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [title, setTitle] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [tags, setTags] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [singleImage, setSingleImage] = useState("");
  const [bannerDetailImage,setBannerDetailImage]=useState("")
  const [articleSource, setArticleSource] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [catData, setCatData] = useState([]);
  const [subCatData, setsubCatData] = useState([]);
  const [articleSourceData, setArticleSourceData] = useState([]);
  const [authorAbout, setAuthorAbout] = useState([]);
  const [authorUrl, setAuthorUrl] = useState([]);
  const [lName, setLName] = useState([]);
  const [listOfName, setListOfName] = useState([]);
  const [publishArticle, setPublishArticle] = useState("0");
  const [paidArticle, setPaidArticle] = useState("0");
  const [featuredArticles, setFeaturedArticles] = useState("0");

  useEffect(() => {
    articleCategories();
  }, []);

  const imageHandleChange = (e) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImages((prevImages) => prevImages.concat(fileArray));
      Array.from(e.target.files).map((item) => {
        setmultipleImages((prevImages) => [...prevImages, item]);
      })
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  const deleteImages = (id)=> {
    setSelectedImages(selectedImages.filter((item, index) => {
      return index !== id;
    }))
    setmultipleImages(multipleImages.filter((item , index) => {
      return index !== id;
    }))
  }

  const createArticles = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    // myHeaders.append("Content-Type", "multipart/form-data");
    
    var formdata = new FormData();
    formdata.append("Title", title);
    formdata.append("ShortDescription", description);
    formdata.append("Category", category);
    formdata.append("Subcategory", subCategory);
    formdata.append("Type", "vdv");
    formdata.append("Tags", tags);
    // formdata.append("IsPublished", "");
    formdata.append("Source", articleSource);
    formdata.append("MetaTitle", metatitle);
    formdata.append("MetaDescription", metadescription);
    formdata.append("Author", lName);
    formdata.append("AboutAuthor", authorAbout);
    formdata.append("AuthorUrl", authorUrl);
    formdata.append("Description", content);
    formdata.append("ArticleText", "sfcszcf");
    formdata.append("PublishDate", publishDate);
    formdata.append("PaidArticle", paidArticle);
    formdata.append("FeaturedArticle", featuredArticles);
    formdata.append("IsPublished", publishArticle);
    formdata.append("Banner", singleImage);
    formdata.append("ArticleImage", bannerDetailImage);
    formdata.append("Video", videoUrl);
      


    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/InsertArticles",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if(result.status_code===200 && result.status==="SUCCESS"){
          alert("Article successfully inserted")
        }else if(result.status_code >= 300 && result.status !== "SUCCESS"){
          alert("There is some issue")
        }
      })
      .catch((error) => console.log("error", error));
  };

  const addCategories = (selectedCategories) => {
    setCategory((prevCategories) => {
      return [...prevCategories, selectedCategories];
    });
  };

  const deleteCategories = (id) => {
    setCategory((prevCat) => {
      return prevCat.filter((item, index) => {
        return index !== id;
      });
    });
  };

  const articleCategories = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/GetDropdownArticle",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCatData(result.Category);
        setsubCatData(result.Subcategory);
        setArticleSourceData(result.Source);
      })
      .catch((error) => console.log("error", error));
  };

  const formValidation = () => {
    let isValid = true;
    let error = {};
    if (title === "") {
      isValid = false;
      error.title = "Title is required";
    }
    if (metatitle === "") {
      isValid = false;
      error.metatitle = "Meta title is required";
    }
    if (metadescription === "") {
      isValid = false;
      error.metadescription = "Meta description is required";
    }
    if (description === "") {
      isValid = false;
      error.description = "Description is required";
    }
    if (lName === "") {
      isValid = false;
      error.lName = "Author name is required";
    }
    if (authorAbout === "") {
      isValid = false;
      error.authorAbout = "About author is required";
    }
    if (authorUrl === "") {
      isValid = false;
      error.authorUrl = "Url is required";
    }
    if (authorAbout === "") {
      isValid = false;
      error.authorAbout = "About author is required";
    }
    if (lName === "") {
      isValid = false;
      error.lName = "Author Name  is required";
    }
    if (authorUrl === "") {
      isValid = false;
      error.authorUrl = "Author url is required";
    }

    setErrors(error);
    return isValid;
  };
  const handleChange = (content) => {
    setContent(content);
  };
                                                  
  
  const renderPhotos = (source) => {
    return source.map((item, index) => {
 
      return (
        <div className={classes.imageDiv} >   
          <img
            src={item}
            alt="article"
            key={index}
            className={classes.image_top}
          />
            <IoIosCloseCircleOutline className={classes.closeIcon} onClick={() => deleteImages(index)} />
         <AiFillCopy onClick={() => { copy(item); alert("Copied") }}  className={classes.copyIcon}/>
        </div>
      );
    });
  };
  return (
    <div className={classes.MainContainer}>

      <HeaderForRightSec headingText={"CreateArticle"} />

     <h4 className={classes.ArticleHeading}>Article ID : </h4>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formValidation();
          createArticles();
        }}
      >
        <div className={classes.form_container}>
          <div className={classes.left}>
            <div className={classes.formeditor}>
              <label htmlFor="text">Title : </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <div className={classes.form_error_msg_captcha}>
                {errors.title}
              </div>

            </div>
            <div className={classes.formeditor}>
              <label htmlFor="text">Meta Title : </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="meta title"
                value={metatitle}
                onChange={(e) => {
                  setMetatitle(e.target.value);
                }}
              />
              <div className={classes.form_error_msg_captcha}>
                {errors.metatitle}
              </div>
            </div>

            <div className={classes.formeditor}>
              <label htmlFor="shortDescription">Short Description : </label>
              <textarea
                rows="4"
                cols="50"
                id="shortDescription"
                name="shortDescription"
                placeholder="write description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <div className={classes.form_error_msg_captcha}>
                {errors.description}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label htmlFor="text">Meta Description : </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="meta description"
                value={metadescription}
                onChange={(e) => {
                  setMetadescription(e.target.value);
                }}
              />
              <div className={classes.form_error_msg_captcha}>
                {errors.metadescription}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label htmlFor="Content">Content :</label>
              <div className={classes.content}>
                <SunEditor
                  height="auto"
                  setContents={content}
                  onChange={handleChange}
                  defaultValue={content}
                  placeholder="Enter your description here..."
                  setOptions={{
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "hiliteColor",
                        "removeFormat",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "fullScreen",
                        "showBlocks",
                        "codeView",
                        "print",
                        "save",
                      ],
                    ],
                  }}
                />
              </div>
            </div>
            <div className={classes.submitDiv}>
              <button type="submit" className={classes.submitButton}>
                Create
              </button>
            </div>
          </div>
          <div className={classes.right}>
            <h4 className={classes.ArticleHeading_others}>Other Details :</h4>

            <div className={classes.formeditor}>
              <label>Publish Date : </label>
              <input
                type="date"
                id="filepicker"
                name="fileList"
                value={publishDate}
                onChange={(e) => setPublishDate(e.target.value)}
                webkitdirectory
                className={classes.date_input}
              ></input>
            </div>
            
            <div className={classes.formeditor}>
              <label>Upload banner home Image : </label>
              <input
                type="file"
                id="images"
                name="image"
                onChange={(e) => setSingleImage(e.target.files[0])}
              ></input>
            </div>

            <div className={classes.formeditor}>
              <label>Upload banner detail image : </label>
              <input
                type="file"
                id="images1"
                name="image"
                onChange={(e) => setBannerDetailImage(e.target.files[0])}
              ></input>
            </div>

            <div className={classes.formeditor}>
              <label htmlFor="image">Upload Multiple Image : </label>

              <input
                type="file"
                multiple
                onChange={imageHandleChange}
                accept="image/*"
              />
            </div>

            <div className={classes.articlemultipleImagea} >
              {renderPhotos(selectedImages)}
            </div>

            <div className={classes.formeditor}>
              <label>Video: </label>
              <input
                type="text"
                id="video"
                placeholder="Youtube URL"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              ></input>
            </div>
            <div className={classes.formeditor}>
              <label>Tags: </label>
              <input
                type="text"
                id="tags"
                placeholder="Tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              ></input>
            </div>

            <div className={classes.formeditor}>
              <label>Category: </label>
              <select
                className={classes.dropDownClass}
                onChange={(e) => addCategories(e.target.value)}
              >
                <option>---Select Category---</option>
                {catData.map((item, index) => {
                  return (
                    <option key={index} value={item.Category}>
                      {" "}
                      {item.Category}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            
            <div className={classes.mapDiv}>
              {category.map((item, index) => {
                return (
                  <div className={classes.categories}>
                    {item}
                    <AiOutlineClose
                      onClick={(e) => deleteCategories(index)}
                      className={classes.deleteCategoryIcon}
                    />
                  </div>
                );
              })}
            </div>
            <div className={classes.formeditor}>
              <label>Sub-Category: </label>
              <select
                className={classes.dropDownClass}
                onChange={(e) => setSubCategory(e.target.value)}
              >
                <option>---Select Sub-Category---</option>
                {subCatData.map((item, index) => {
                  return <option key={index}> {item.Subcategory} </option>;
                })}
              </select>
            </div>
            <div className={classes.formeditor}>
              <label>Article Source: </label>
              <select
                className={classes.dropDownClass}
                onChange={(e) => setArticleSource(e.target.value)}
              >
                <option>---Select Article Source--</option>
                {articleSourceData.map((item, index) => {
                  return <option key={index}> {item.Source} </option>;
                })}
              </select>
            </div>

            <div className={classes.box_author}>
              <div className={classes.formeditor}>
                <label htmlFor="author name:">Author Name: </label>
                <input
                  type="text"
                  name="author name"
                  placeholder="author name"
                  onChange={(e) => {
                    setLName(e.target.value);
                  }}
                  value={lName}
                />
                <div className={classes.form_error_msg_captcha}>
                  {errors.lName}
                </div>
              </div>
              <div className={classes.formeditor}>
                <label htmlFor="about author">About Author: </label>
                <input
                  type="text"
                  name="about author"
                  placeholder="about author"
                  onChange={(e) => {
                    setAuthorAbout(e.target.value);
                  }}
                  value={authorAbout}
                />
                <div className={classes.form_error_msg_captcha}>
                  {errors.authorAbout}
                </div>
              </div>
              <div className={classes.formeditor}>
                <label htmlFor="author url">Url: </label>
                <input
                  type="text"
                  name="url"
                  placeholder="author url"
                  onChange={(e) => {
                    setAuthorUrl(e.target.value);
                  }}
                  value={authorUrl}
                />
                <div className={classes.form_error_msg_captcha}>
                  {errors.authorUrl}
                </div>
              </div>
            </div>

            <div className={classes.checkboxDiv}>
              <label>Paid Article : </label>
              <input
                type="checkbox"
                className={classes.checkBoxClass}
                onChange={(e) =>
                  setPaidArticle(paidArticle === "0" ? "1" : "0")
                }
              />
              <label>Featured Article : </label>
              <input
                type="checkbox"
                className={classes.checkBoxClass}
                onChange={(e) =>
                  setFeaturedArticles(featuredArticles === "0" ? "1" : "0")
                }
              />
              <label>Live : </label>
              <input
                type="checkbox"
                className={classes.checkBoxClass}
                onChange={(e) =>
                  setPublishArticle(publishArticle === "0" ? "1" : "0")
                }
              />
            </div>
          </div>
        </div>
      </form>
   </div>
  );
 }

export default CreateArticle;
