import React, { useState } from "react";
import RiHeadingText from "../../Components/RiHeadingText";
import classes from "./LandingPage.module.css";
import { Link } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import bredcrumb from "../../Components/BreadCrumb/BreadCrumb.module.css";
import { useHistory } from "react-router-dom";

const LandingPage = () => {
  let loggedIn = localStorage.getItem("UserLoggedIn");
  // console.log("loggedIn:", loggedIn);
  let history = useHistory()

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [countData, setCountData] = useState([]);
  console.log(countData);
  
  const LandingPageApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate,
      EndDate: endDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rzadmin/GetLandingPageJson",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCountData(result.LandingPageID);
        
      })
      .catch((error) => console.log("error", error));
  };

  {/* const pageInfo = {
    "currentPageName": "LandingPage"
  } */}
  

  return (

    <>
      <div className={classes.MainContainer}>
        <ul className={bredcrumb.breadcrumb}>
            <li className={bredcrumb.breadcrumb_li}>
              <a href="/DashBoard" className={bredcrumb.breadcrumb_li_a}>DashBoard</a>
            </li>
                <li className={bredcrumb.breadcrumb_li}>LandingPage</li>

        </ul> 
      {/* <RiHeadingText title={"Landing page"} /> */}
    {/*  <BreadCrumb pageInfo={pageInfo}/> */}
    <div className= {classes.landingPage_heading}>LandingPage</div>
      <div className={classes.date_container}>
        <div className={classes.date_field}>
          From
          <input
            type="date"
            className={classes.date_input}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
          ></input>
        </div>
        <div className={classes.date_field}>
          To
          <input
            type="date"
            className={classes.date_input}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
          ></input>
        </div>

        <button className={classes.search_button} onClick={LandingPageApi}>
          Search
        </button>

        <Link to="/LandingPageAdd">
          <button className={classes.add_button}>Add</button>
        </Link>

        
      </div>

      <div className={classes.table_container}>
        <table className={classes.signup_details_table}>
          <tr className={classes.table_row}>
            <th className={classes.table_heading}>Id</th>
            <th className={classes.table_heading}>Key Index</th>
            <th className={classes.table_heading}>Landing PageID</th>
            <th className={classes.table_heading}>Heading1</th>
            <th className={classes.table_heading}>Main Description</th>
            <th className={classes.table_heading}>Heading 1Url</th>
            <th className={classes.table_heading}>Heading2</th>
            <th className={classes.table_heading}>Description2</th>
            <th className={classes.table_heading}>Heading2 Url</th>
            <th className={classes.table_heading}>Heading3</th>
            <th className={classes.table_heading}>Description3</th>
            <th className={classes.table_heading}>Heading3 Url</th>
            <th className={classes.table_heading}>Heading4</th>
            <th className={classes.table_heading}>Description4</th>
            <th className={classes.table_heading}>Heading4 Url</th>
            <th className={classes.table_heading}>CallFor Action</th>
            <th className={classes.table_heading}>Hub Name</th>
            <th className={classes.table_heading}>Ordinal Position</th>
            <th className={classes.table_heading}>Banner Image</th>
            <th className={classes.table_heading}>view</th>
          </tr>

          <tbody>
            {countData.map((item, value) => {
              return (
                <tr className={classes.table_row}>
                  <td className={classes.table_td}>{item.ID}</td>
                  <td className={classes.table_td}>{item.KeyIndex}</td>
                  <td className={classes.table_td}>{item.LandingPageID}</td>
                  <td className={classes.table_td}>{item.Heading1}</td>
                  <td className={classes.table_td}>{item.MainDescription}</td>
                  <td className={classes.table_td}>{item.Heading1Url}</td>
                  <td className={classes.table_td}>{item.Heading2}</td>
                  <td className={classes.table_td}>{item.Description2}</td>
                  <td className={classes.table_td}>{item.Heading2Url}</td>
                  <td className={classes.table_td}>{item.Heading3}</td>
                  <td className={classes.table_td}>{item.Description3}</td>
                  <td className={classes.table_td}>{item.Heading3Url}</td>
                  <td className={classes.table_td}>{item.Heading4}</td>
                  <td className={classes.table_td}>{item.Description4}</td>
                  <td className={classes.table_td}>{item.Heading4Url}</td>
                  <td className={classes.table_td}>{item.CallForAction}</td>
                  <td className={classes.table_td}>{item.HubName}</td>
                  <td className={classes.table_td}>{item.OrdinalPosition}</td>
                  <td className={classes.table_td}>{item.BannerImage}</td>
                  <td className={classes.table_td}>
                    <Link
                      to={"/Landingpage/LandingPageView/" + item.LandingPageID}
                      className={classes.articleLink}> <BsEye />

                    </Link>

                  </td>
                </tr>
              );

            })}

        </tbody>

      </table>

      </div>

    </div>
    
    </>
  );
}
export default LandingPage;
