import React, { useState, useRef, useEffect } from 'react';
import classes from '../../Components/UIcomp/RightTable.module.css'
import { useHistory,Link } from 'react-router-dom';
import HeaderForRightSec from '../../Components/UIcomp/HeadingForRightSec';
import ReactExport from "react-data-export";
import TablesExcelDir from '../../Components/ExcelDir/TablesExcelDir';
import { TiTick } from 'react-icons/ti';
import Modal from "../../Components/UserDetails/Modal";
import Loader from 'react-loader-spinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FreelancerProfile = () => {

    let loggedIn = localStorage.getItem ("UserLoggedIn")
    let history = useHistory();
    const tableRef = useRef(null);
    
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [visibility, setVisibility] = useState(false);
    const [skillData, setSkillData] = useState();
    const [isLoader, setIsLoader] = useState(false);
    const[totalCount, setTotalCount] = useState("")
    const [allData, setAllData] = useState([])

    const handleNextbtn = () => {
      setCurrentPage(currentPage + 1);
      setIsLoader("next")
    };
  
    const handlePrevbtn = () => {
      setCurrentPage(currentPage - 1);
      setIsLoader('prev');
    };


    useEffect(() => {
        var date = new Date();
    date.setDate(date.getDate() - 7);

    let startDateformat =(date.getDate().toString.length < 2 ? "0" + date.getDate() : date.getDate())
    const monthFormat = (date.getMonth()+1).length > 1 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)
    var finalDate = date.getFullYear() + '-'+  monthFormat + '-' + startDateformat
    setStartDate(finalDate)

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate())

    let endDateformat = todayDate.getDate().toString.length < 2 ? "0" +todayDate.getDate() : todayDate.getDate()
    const todaymonthFormat = (todayDate.getMonth()+1).length > 1 ? (todayDate.getMonth()+1) : "0" + (todayDate.getMonth()+1)
    var finalToday = todayDate.getFullYear() + '-'+  todaymonthFormat + '-' + endDateformat
    setEndDate(finalToday)
    getFreelancer(finalDate ,finalToday,currentPage)
    }, []);
    useEffect(() => {
      if (startDate && endDate && currentPage)
      { getFreelancer(startDate, endDate, currentPage)}
      getFreelancer()
  }, [currentPage])

    const getFreelancer = (end ,today,page ) => {
      // setIsLoader(true)

        var myHeaders = new Headers();
           myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
           myHeaders.append("Content-Type", "application/json");
        
           var raw = JSON.stringify({
            "StartDate":  end? end : (startDate),
            "EndDate": today ?  today : (endDate),
            "page":(end&&page)?page:"",
          });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch("https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/FreelancerProfileData", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.status_code === 200 && result.status === "Success" &&!end) {
              setAllData(result.data)
          }
           else if (result.status_code === 200 && result.status === "Success" &&end ) {
                setData(result.data)
                setTotalCount(result.count)
            }
            else if (result.status_code  === 200 && result.status === "Success" && result.data === []  ){
               setData("No data found !")
              }
            else if (result.status_code  >= 300 && result.status !== "Success"  ){
                alert("Please select start date and end date")
              }
            else {
                alert(result.message)
            }
      
        })
        .catch(error => console.log('error', error))
        .finally(()=>{
           setIsLoader(false)
        });
}
   
      const dateAndTimeHandler  =(profileDate) =>{

        var date = new Date(profileDate);
          return date.toString().slice(3,25)
    }

    const popupCloseHandler = (e) => {
      setVisibility(e) 
    };

    const viewData = (skills) => {
      setVisibility (!visibility);
      setSkillData (skills);
    }
    
    return (
        <>
      
        <div className={classes.MainContainer}>

           <HeaderForRightSec headingText={" Freelancer Profile"} />

                <div className={classes.date_container}>
                 <div className={classes.date_field}>From<input type="date" className={classes.date_input}    onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                 <div className={classes.date_field}>To<input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
                 <div className={classes.searchButton} onClick={()=>{ setCurrentPage(1);getFreelancer(startDate, endDate, currentPage)}}> Search </div>
                 <div className={classes.recordLength}>  Data Count {totalCount} </div>
                </div>

                <TablesExcelDir tableData={allData} tableType={"freelancer"} />
                <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ?
                    <>
                    
                        
                        <button  onClick={handlePrevbtn}  className={classes.buttonone}>
                       { isLoader=="prev"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Prev</>}
                            </button> 
                    
                    </> 
                   : <span className={classes.buttononePre}>Prev</span>}

                     <span className={classes.CurrentPageText}>{currentPage}</span>
                    {(data.length >= 20 )? 
                    <>
                  
                       
                        <button onClick={handleNextbtn}  className={classes.buttonone}>
                        {isLoader=="next"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Next</>}
                        </button>
                    
                    </> : 
                    <span className={classes.buttononePre}>Next</span>}
                    
                </div>
               {/* <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                    {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                    {data.length >=20 ? <span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span> : <span className={classes.CurrentPageText}>{currentPage}</span>}
                    {data.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
    </div> */}

            {data.length > 0 ? <div className={classes.table_container}>
                <table className={classes.signup_details_table} id="table-to-xls">
                    <tr className={classes.table_row}>

                       <th className={classes.table_heading}>S.No</th>
                       <th className={classes.table_heading}>Country</th>
                       <th className={classes.table_heading}>User Name</th>
                       <th className={classes.table_heading}>Full Name</th>
                       <th className={classes.table_heading}>Email</th>
                       <th className={classes.table_heading}>Mobile </th>
                        <th className={classes.table_heading}>Skills</th> 
                       <th className={classes.table_heading}>Percent</th>
                       <th className={classes.table_heading}> Profile Status</th>
                       <th className={classes.table_heading}> Payment Status</th>
                       <th className={classes.table_heading}>Date</th>

                    </tr>

                 
                    <tbody>

                        {data.map((item, index) => {
                          return (
                                <tr className={classes.table_row} >
                                { <td className={classes.table_td}>{index + 1}</td> }
                                  <td className={classes.table_td}>{item.Country?item.Country:"N/A"}</td>
                                 
                                  <td className={classes.table_td}> <div className={classes.Tagsection}> <Link to={{ pathname : "/SignUpDetail/"+ item.FreelancerId, state: {signUpData: item }}}  className={classes.userName}>{item.UserName}</Link>
                                    {item.isOldUser===true && <div className={classes.Tag}>Old</div>}
                                  </div></td>
                                  <td className={classes.table_td}>{item.UserfirstName} {item.UserlastName}</td>
                                  <td className={classes.table_td}>{item.UserEmail?<>{item.UserEmail} {item.UserEmailVerified && <TiTick color='green'/>}</>:"N/A"}</td>
                                  <td className={classes.table_td}>{item.UserMoblile?<>{item.UserMoblile} {item.UserMobileVerified && <TiTick color='green'/>}</>:"N/A"}</td>
                                  <td className={classes.table_td_link} onClick={()=>viewData (item.Skills)}><div className={classes.skillContainer}>{item.Skills?item.Skills.split(",")[0] : "N/A"}</div> </td>
                                  <td className={classes.table_td}>{item.Percent?item.Percent:"N/A"}</td>
                                  <td className={classes.table_td}>{item.ProfileStatus?item.ProfileStatus:"N/A"}</td>
                                  <td className={classes.table_td}>{item.PaymentStatus?item.PaymentStatus:"N/A"}</td>
                                 <td className={classes.table_td}>{item.UpdatedAt===""?<></>:<>{dateAndTimeHandler(item.UpdatedAt)}</>} </td>
                  
                                </tr>

                            )

                         }

                        )} 

                    </tbody>

                </table>

                {skillData ? 
                <Modal
                onClose = {popupCloseHandler} show = {visibility}
                >
                  {skillData ? skillData.replaceAll (', ', ', ') :  '' }
                </Modal> : '' }

            </div>

            :
             <div className={classes.NoDataFound}>Please select start date and end date</div>}
               
        </div>
      
    </>

);


}

export default FreelancerProfile;
