import { computeHeadingLevel } from "@testing-library/react";
import React,{useState} from "react"
import Modal from "./Modal";
import classes from "./RequirementInfo.module.css";
import Loader from "react-loader-spinner";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";

const RequirementInfo = (props) => {

  const [approve, setApprove] = useState("");
  const [reject, setReject] = useState("");
  const [reason,setReason]=useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  let requirementID=props.requirementList.map((item)=>{
    return (item.RequirementID)
  })
  
  const dateAndTimeHandler = (userDetailDate) => {
    var date = new Date(userDetailDate);
    return date.toString().slice(3, 25);
  };
  const [visibility, setVisibility] = useState(false);
  const [message, setMessage] = useState('');
  const [id, setId] = useState('');
  
  const popupCloseHandler = (e) => {
    setVisibility(e);
  };
  const showMessage = (id, msg) => {
    setVisibility(!visibility);
    setMessage(msg);
    setId(id);
  };
  const setRequestStatus = (id, status)=> {
    console.log("Id:", id);
    console.log("Status:", status);
    setVisibility(!visibility);
}

const UserStatusApi = (RequirementID, status) => {
  if (status === "Approved") setApprove(approve, RequirementID);
  else if (status === "Disapproved") setReject(reject, RequirementID);

  setIsLoading(true);

  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
  );
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    RequirementID: requirementID[0],
    Status: status,
    Reason: reason
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  fetch(
    "https://api-preview.rozgaarindia.com/api/rzadmin/PostAReqAdminStatus",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.status_code === 200) {
        if (status === "Approved") {
          alert("Approved successfully")
          window.location.reload()
        } else if (status === "Disapproved") {
          alert("Rejected successfully")
          window.location.reload()
        } 
      }
    })
    .catch((error) => console.log("error", error))
    .finally(() => {
      setIsLoading(false);
    });
};

const formValidation = () => {
  let isValid = true;
  let errors = {};
  if (!reason || reason === "") {
    isValid = false;
    errors.reason = "Please enter valid reason";
  }
  setError(errors);
  return isValid;
}
const statusColor = (statustabcontainer) => {
  if (statustabcontainer === "Approved") {
    return "Approved";
  }
  if (statustabcontainer === "Draft") {
    return "Draft";
  }
  if (statustabcontainer === "Submitted") {
    return "Submitted";
  }

  if (statustabcontainer === "Disapproved") {
    return "Disapproved";
  }
};

  return (
    <div className={classes.mainContainer}>
      <Modal
        onClose={popupCloseHandler}
        show={visibility}
      >
        <span className={classes.msg}> Do you want to {message} this Request ? </span>
    { message == 'Approve' ? <>
        <div className={classes.modalButtons}>
        {isLoading ? (
              <div className={classes.Btnloder}>
                <Loader type="TailSpin" color="white" width={20} height={18} />
              </div>
            ) : (
          <button className={classes.yesBtn} onClick={() => UserStatusApi(requirementID[0], "Approved")} > Yes </button>)}
          <button className={classes.noBtn} onClick={(e) => setVisibility(!visibility)} > No </button>
        </div>
      </> :
      <> 
     <div>
    <input 
    type="text"
    className={classes.modalinput}
    placeholder={"Reason for rejction"}
    onChange={(e)=>setReason(e.target.value)}
    value={reason}
     />
      <p className={classes.ErrorMessage}>{error.reason}</p>
      </div>
        <div className={classes.modalButtons}>
        {isLoading ? (
              <div className={classes.Btnloder}>
                <Loader type="TailSpin" color="white" width={20} height={18} />
              </div>
            ) : (
          <button className={classes.yesBtn} onClick={() => { formValidation()? UserStatusApi(requirementID[0], "Disapproved")
              : console.log("Something's wrong");
          }} > Yes </button>)}
          <button className={classes.noBtn}onClick={(e) => setVisibility(!visibility)} > No </button>
        </div>
      </>
    }
      </Modal>
        {props.requirementList&&props.requirementList.map((item, i) => {
          return (
            <div className={classes.reqBox}>
              <div className={classes.reqBoxContainer}>
                <div className={classes.LeftContainer}>
                    <div className={classes.HeadContainer}>
                      <span className={item.RequirmentStatus==="Free"?classes.RequirmentStatus:classes.RequirmentStatuspaid}>{item.RequirmentStatus}</span>
                     </div>
                    <div className={classes.HeadContainer}>
                      <img src={item.CompanyLogo} width="70px" alt="" />
                    </div>
                    <div className={classes.titleHeadContainer}>
                      {" "}
                      <span className={classes.heading}>Title </span> : {item.Title}
                    </div>
                    <div className={classes.titleHeadContainer}>
                      <span className={classes.heading}>Description </span> :{" "}
                      
                      <div
            dangerouslySetInnerHTML={{ __html: item.Description }}
            className={classes.faq_answer}
         />
                    </div>
                    <div className={classes.titleHeadContainer}>
                      <span className={classes.heading}>Freelancer Policy </span> :{" "}
                      {item.FreelancerPolicy}
                    </div>
                    <div className={classes.titleHeadContainer}>
                      <span className={classes.heading}>Requirement Type </span> :{" "}
                      {item.RequirementType}
                    </div>
                    <div className={classes.titleHeadContainer}>
                      <span className={classes.heading}>MultipleFreelancers </span> :{" "}
                      {item.MultipleFreelancers}
                    </div>
                    <div className={classes.titleHeadContainer}>
                      <span className={classes.heading}>Freelancer Count </span> :{" "}
                      {item.FreelancersCount}
                    </div>
                    {item.IsCompany === "1" ? (
                      <>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Company Name </span> :{" "}
                          {item.CompanyName}{" "}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Company Website </span> :{" "}
                          {item.CompanyWebsite}{" "}
                        </div>
                      </>
                    ) : null}
                    {item.IsOnsite === "1" ? (
                      <div className={classes.IsOnsiteTag}>IsOnsite</div>
                    ) : (
                      <></>
                    )}
                    {item.IsOnsite === "1" ? (
                      <>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>City </span> : {item.City}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>State</span> : {item.State}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Country </span> :{" "}
                          {item.Country}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Location</span> :
                          {item.Location}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Address</span> :{" "}
                          {item.Address}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Pin Code</span> :{" "}
                          {item.Pincode}
                        </div>
                      </>
                    ) : null}

                    {item.IsHybrid === "1" ? (
                      <div className={classes.IsHybrid}>IsHybrid</div>
                    ) : (
                      <></>
                    )}
                    {item.IsHybrid === "1" ? (
                      <>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>City </span> : {item.City}
                        </div>
                        
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>State</span> : {item.State}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Country </span> :{" "}
                          {item.Country}{" "}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Location</span> :{" "}
                          {item.Location}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Address</span> :{" "}
                          {item.Address}{" "}
                        </div>
                        <div className={classes.titleHeadContainer}>
                          <span className={classes.heading}>Pin Code</span> :{" "}
                          {item.Pincode}
                        </div>
                      </>
                    ) : null}
                 {/* <div className={classes.titleHeadContainer}>
                      <span className={classes.heading}>Total Amount</span> :{" "}
                      {item.TotalAmount}
                    </div>*/}
                    
                    <div className={classes.titleHeadContainer}> 
                      <span className={classes.heading}>Image Url</span> :{" "}
                      <span className={classes.response}>
                        <a href={item.ImageUrl}>{item.ImageUrl}</a>{" "}
                      </span>
                    </div>
                    <div className={classes.titleHeadContainer}>
                      {" "}
                      <span className={classes.heading}>Updated Date</span> :{" "}
                      {dateAndTimeHandler(item.UpdatedDate)}{" "}
                    </div>
                    <div className={classes.SkillsSection}>
                      <div className={classes.Skills}>
                        {item.Skills.map((item1) => {
                          return (
                            <div className={classes.tabcontainer}> {item1.Skill} </div>
                          );

                        })}
                      </div>
                    </div>


                    
                <div className={classes.ProposalSection}>
                  <div className={classes.ProposalLengthSection}>
                    <div className={classes.mainHeading}>Proposals : </div>
                    <div className={classes.ProposalLengthText}>
                      {item.Proposal.length}
                    </div>
                  </div>

                  <div className={classes.Proposal}>
                    {item.Proposal.map((item1) => {
                     // console.log("Proposal:", item1);
                      return (
                      <div className={classes.ProposalContainerSection}>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            RequirementId :{" "}
                          </span>{" "}
                          &nbsp;{item1.RequirementId} &nbsp;
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>PaymentId: </span>
                          {item1.PaymentId}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            User Name :{" "}
                          </span>{" "}
                          {item1.UserName}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            First Name :{" "}
                          </span>{" "}
                          {item1.FirstName}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Last Name :{" "}
                          </span>{" "}
                          {item1.LastName}
                        </div>
                        <div className={classes.ProposalContainer}>
                          <span className={classes.heading}>
                            Profile Picture{" "}
                          </span>{" "}
                          :{" "}
                          <span className={classes.profile}>
                            <a href={item1.ProfilePicture} target="_blank">
                            {item1.ProfilePicture}
                            </a>{" "}
                          </span>
                        </div>

                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Proposal Amount :{" "}
                          </span>{" "}
                          {item1.PraposalAmount}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Currency :{" "}
                          </span>{" "}
                          {item1.Currency}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>Unit : </span>{" "}
                          {item1.Unit}
                        </div>

                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>Status : </span>{" "}
                          {item1.Status}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Payment Status :{" "}
                          </span>{" "}
                          {item1.PaymentStatus}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Deposit Amount :{" "}
                          </span>{" "}
                          {item1.DepositAmount}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Client Remarks :{" "}
                          </span>{" "}
                          {item1.ClientRemarks}
                        </div>
                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Client RStatus :{" "}
                          </span>{" "}
                          {item1.ClientRStatus}
                        </div>

                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Created At
                          </span> : {dateAndTimeHandler(item1.CreatedAt)}{" "}
                        </div>

                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Updated At
                          </span> : {dateAndTimeHandler(item1.UpdatedAt)}{" "}
                        </div>

                        <div className={classes.ProposalContainer}>
                          {" "}
                          <span className={classes.heading}>
                            Freelancer Skills :{" "}
                          </span>{" "}
                          {item1.FreelancerSkills}
                        </div>
                      </div>
                      );
                    })}
                  </div>
                </div>

              
                <div className={classes.ProposalSection}>
                  <div className={classes.ProposalLengthSection}>
                    <div className={classes.mainHeading}>Addons : </div>
                    <div className={classes.ProposalLengthText}>
                      {item.Addons.length}
                    </div>
                  </div>
                  <div className={classes.Addons}>
                    {item.Addons.map((item1) => {
                      return (
                        <div className={classes.ProposalContainerSection}>
                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              Addon Name :{" "}
                            </span>{" "}
                            &nbsp;{item1.AddonName} &nbsp;
                          </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              Addon Amount :{" "}
                            </span>{" "}
                            &nbsp;{item1.AddonAmount} &nbsp;
                          </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              Payment Status :{" "}
                            </span>{" "}
                            &nbsp;{item1.PaymentStatus} &nbsp;
                          </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              Updated Date
                            </span> : {dateAndTimeHandler(item1.UpdatedDate)}{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className={classes.ProposalSection}>
					        <div className={classes.ProposalLengthSection}>
				  
                    <div className={classes.mainHeading}>
                      Requirement Application :{" "}
                    </div>
              
                    <div className={classes.ProposalLengthText}>
                      {item.RequirementApplication.length}
                    </div>
					        </div>
				  
                  <div className={classes.RequirementApplication}>
                    {item.RequirementApplication.map((item1) => {
                      return (
                        <div className={classes.ProposalContainerSection}>
                            <div className={classes.ProposalContainer}>
                              {" "}
                              <span className={classes.heading}>
                                Freelancer Id :{" "}
                              </span>{" "}
                              &nbsp;{item1.FreelancerId} &nbsp;
                            </div>

                            <div className={classes.ProposalContainer}>
                              {" "}
                              <span className={classes.heading}>
                                User Name :{" "}
                              </span>{" "}
                              &nbsp;{item1.UserName} &nbsp;
                            </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              First Name :{" "}
                            </span>{" "}
                            &nbsp;{item1.FirstName} &nbsp;
                          </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              Last Name :{" "}
                            </span>{" "}
                            &nbsp;{item1.LastName} &nbsp;
                          </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              Full Name :{" "}
                            </span>{" "}
                            &nbsp;{item1.FullName} &nbsp;
                          </div>

                          <div className={classes.ProposalContainer}>
                            <span className={classes.heading}>
                              Profile Picture{" "}
                            </span>{" "}
                            :{" "}
                            <span className={classes.profile}>
                              <a href={item1.ProfilePicture} target="_blank">
                              {item1.ProfilePicture}
                              </a>{" "}
                            </span>
                          </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              Freelancer Profile Id :{" "}
                            </span>{" "}
                            &nbsp;{item1.FreelancerProfileId} &nbsp;
                          </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>Status: </span> &nbsp;
                            {item1.Status} &nbsp;
                          </div>

                          <div className={classes.ProposalContainer}>
                            {" "}
                            <span className={classes.heading}>
                              Updated At
                            </span> : {dateAndTimeHandler(item1.UpdatedAt)}{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                </div>
                 {/*  End Left Container*/}
                <div className={classes.RightContainer}>
                 
                  <div className={classes.Buttons}>
                    { (item.Status==="Approved"|| item.Status==="Closed") ? <></> :
                    <button className={classes.approve} onClick={(e) => showMessage(item.ID, 'Approve')} > Approve </button>}
                   { item.Status==="Disapproved" || item.Status==="Closed"? <></>:
                    <button className={classes.disapprove} onClick={(e) => showMessage(item.ID, 'Reject')} >Reject</button>}
                  
                  </div>
                  
                  <div className={classes.StatusContainer}>
                  <div className={`${classes.statustabcontainer} ${classes[statusColor(item.Status)] }`}>
                  {statusColor(item.Status)}
                  </div>
                  </div>

                  <div className={classes.editMain}>
                      <Link to={"/UserEditInfo/" + item.RequirementID +"/"+ item.UserId}> Edit <FiEdit/> </Link>
                      </div>
                  <div className={classes.BudgetHeading}>
                    {item.Budget&& <span className={classes.tabcontainer}>
                        
                        {item.BudgetCurrency} {item.Budget} {item.BudgetUnit}
                      </span>}
                     
                    </div>
                </div>

              </div>
            </div> 
          );  
      })}

    </div>
  );
};

export default RequirementInfo;
