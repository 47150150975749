import React, { useState,useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import HeaderForRightSec from '../../Components/UIcomp/HeadingForRightSec';
import classes from '../../Components/UIcomp/RightTable.module.css'

const UpdateFaq = () => {

    let loggedIn = localStorage.getItem("UserLoggedIn");
    let history = useHistory();

    const [question,setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    
     const {id} = useParams()

    useEffect(() => {
        GetApi()
    }, [])


    const UpdateAPI = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

       var raw = JSON.stringify({
        "FAQID": id,
         "Question": question,
         "Answer": answer,
         "Isdelete": "0"
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/UpdateFaq", requestOptions)
  .then(response => response.json())
  .then(result => {
    if (result.status_code === 200 && result.status === "Success") {
        alert("Data Update Successfully")
        
    }
})
.catch(error => console.log('error', error));

    }
 const GetApi = () => {
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "FAQID": id,
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/GetFaq", requestOptions)
  .then(response => response.json())
  .then(result => { 
    if (result.status_code === 200 && result.status === "Success") {
    setQuestion(result.data.Question)
    setAnswer(result.data.Answer)
}})

  .catch(error => console.log('error', error));
 }
    return (
        <>
            <div className={classes.MainContainer}>
             
           <HeaderForRightSec headingText={"Faq Update"} />   

            
            <div className={classes.inputContainer}>
                <label className={classes.lableDesign}>Question :</label>
                <SunEditor
                    onChange={(e) => {
                      setQuestion(e);
                    }}
                    value={question}
                    setContents={question} 
                  />
            </div>

            <div className={classes.inputContainer}>
                <label className={classes.lableDesign}>Answer :</label>
                <SunEditor
                    onChange={(e) => {
                      setAnswer(e);
                    }}
                    value={answer}
                    setContents={answer} 
                  />
            </div>

          


            <button className={classes.buttonBox} onClick={() => UpdateAPI()}> Submit</button>
        </div>
        
        </>
    );
 
}
 export default UpdateFaq;
