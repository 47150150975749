import { useEffect, useState } from "react";
import CompanyProfileInfo from "../Components/UserDetails/CompanyProfileInfo";
import ProfileInfoClient from "../Components/UserDetails/ProfileInfoClient";
import RequirementInfo from "../Components/UserDetails/RequirementInfo";
import TransactionInfo from "../Components/UserDetails/TransactionInfo";
import SkillsInfo from "../Components/UserDetails/SkillsInfo";
import Chat from "../Components/UserDetails/Chat";
import classes from "./UserDetailInfo.module.css";

const UserDetailInfo = () => {
  const [clientProfileData, setClientProfileData] = useState({});
  const [tabSelected, setTabSelected] = useState("Requirements");
  const [requirementList, setRequirementList] = useState([]);

  let url = window.location.href;
  let object = new URL(url);
  let path = object.pathname;
  let RequirementID = path.split("/").pop();
  console.log("userid", RequirementID);

  useEffect(() => {
    clientProfile();
    RequirementDetail();
    // setTabSelected("Requirements")
  }, []);
  const clientProfile = (end, today) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserId: RequirementID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/ClientProfile",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setClientProfileData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const RequirementDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserId: RequirementID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/client/RequirementListClientView",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //  console.log("Req Result:",result)
        setRequirementList(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const tabSelectionSwitch = () => {
    switch (tabSelected) {
      case "Requirements":
        return <RequirementInfo requirementList={requirementList} />;
      case "Company profile":
        return <CompanyProfileInfo />;
      case "Trasaction":
        return (
          <div className={classes.TransactionInfo_margin}>
            <TransactionInfo />
          </div>
        );
        case "Chat":
          return (
         
              <Chat/>
       
          );
      case "Skills":
        return <SkillsInfo />;
      default:
        return <div></div>;
    }
  };

  return (
    <div className={classes.pageLayout}>
      <ProfileInfoClient clientProfileData={clientProfileData} />

      <div className={classes.tabSection}>
        <div
          className={
            tabSelected === "Requirements"
              ? classes.selectedTab
              : classes.tabHeading
          }
          onClick={() => setTabSelected("Requirements")}
        >
          {" "}
          Requirements
        </div>
        {/* <div  className={tabSelected ==="Company profile"? classes.selectedTab  :  classes.tabHeading} onClick={()=>setTabSelected("Company profile") }>  Company profile</div>  */}

        <div
          className={
            tabSelected === "Trasaction"
              ? classes.selectedTab
              : classes.tabHeading
          }
          onClick={() => setTabSelected("Trasaction")}
        >
          {" "}
          Transaction
        </div>
        <div
          className={
            tabSelected === "Chat"
              ? classes.selectedTab
              : classes.tabHeading
          }
          onClick={() => setTabSelected("Chat")}
        >
          {" "}
          Chat
        </div>
        {/* <div className={classes.tabHeading}> Freelancer Payments</div> */}
        <div
          className={
            tabSelected === "Skills" ? classes.selectedTab : classes.tabHeading
          }
          onClick={() => setTabSelected("Skills")}
        >
          {" "}
          Skills
        </div>
      </div>
      {tabSelectionSwitch()}
    </div>
  );
};

export default UserDetailInfo;
