import React, { useState } from "react";
import { FcAutomatic, FcAssistant, FcBusinessman,FcAddressBook,FcApproval, FcAlphabeticalSortingZa,FcSearch,FcAndroidOs,FcCollaboration } from "react-icons/fc";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link, useLocation, useHistory } from "react-router-dom";
import { GoogleLogout } from "react-google-login";
import classes from "./RiTheme.module.css";

const SideBar = () => {
  const history = useHistory();
  const [clientshow, setClientShow] = useState(false);
  const [freelancerShow, setFreelancerShow] = useState(false);
  const [faqShow, setFaqShow] = useState(false)
  const [otpShow, setOtpShow] = useState(false)
  const [olddataShow, setOldDataShow] = useState(false)
  const [searchuserShow, setSearchUserShow] = useState(false)
  const [freeJobAlertShow, setFreeJobAlertShow] = useState(false)
  
  const onLogoutSuccess = () => {
    sessionStorage.removeItem("email");
    history.push("/");
  };

  const { pathname } = useLocation();

  return (
    <>
      {pathname === "/" ? (
        <></>
      ) : (
        <div className={classes.MainContainer}>
          <div className={classes.sideBar_logo}>
            <img src="/Rozgaar_Black_Logo.svg" />
          </div>

          <Link
            to="/DashBoard"
            className={`${classes.link} ${
              pathname === "/DashBoard" ? classes.active_link : ""
            }`}
          >
            <div className={classes.sideBar_SideBarContainer}>
              <FcAutomatic className={classes.sideBar_SideBarIcon} />
              <div
                className={`${classes.link} ${
                  pathname === "/DashBoard" ? classes.active_link : ""
                }`}
              >
                DashBoard
              </div>
            </div>
          </Link>

          {!clientshow ? (
            <div
              onClick={() => setClientShow(true)}
              className={classes.ClientShowHeader}
            >
              <div className={classes.flex}>
                <FcAssistant className={classes.sideBar_SideBarIcon} />
                <div className={classes.ClientUp}>Client</div>
              </div>
              <div>
                <IoIosArrowDown size={20} />
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={() => setClientShow(false)}
                className={classes.ClientShowHeader}
              >
                <div className={classes.flex}>
                  <FcAssistant className={classes.sideBar_SideBarIcon} />
                  <div className={classes.ClientUp}>Client</div>
                </div>

                <div>
                  <IoIosArrowUp size={20} />
                </div>
              </div>

              <Link to="/SignUp" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/SignUp" ? classes.active_link_SubMenu : ""
                    }`}
                  >
                    SignUp
                  </div>
                </div>
              </Link>

                 <Link to="/ClientLogin" className={classes.removeDeco}>
                                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                                   
                                    <div className={`${classes.link_SubMenu} ${pathname === "/ClientLogin" ? classes.active_link_SubMenu : ""}`}>Client Login</div>
                                </div>
                    </Link> 

              <Link to="/PostARequirement" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/PostARequirement"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    Post A Requirement
                  </div>
                </div>
              </Link>
              <Link to="/ClientReqPayment" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/ClientReqPayment"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    Requirement Payment{" "}
                  </div>
                </div>
              </Link>
              <Link to="/ClientAddonPayments" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/ClientAddonPayments"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    Addons Payment{" "}
                  </div>
                </div>
              </Link>

              <Link
                to="/ClientFreelancerDeposit"
                className={classes.removeDeco}
              >
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/ClientFreelancerDeposit"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                  Client Deposit
                  </div>
                </div>
              </Link>

              <Link to="/ClientProfile" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/ClientProfile"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    {" "}
                    Client Profile
                  </div>
                </div>
              </Link>

              <Link to="/ContactUs" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/ContactUs"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    Contact Us
                  </div>
                </div>
              </Link>

              <Link to="/BulkHiring" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/BulkHiring"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                   BulkHiring
                  </div>
                </div>
              </Link>
             
         
                          
  <Link to="/Article" className={classes.removeDeco}>
                                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                                   
                                    <div className={`${classes.link_SubMenu} ${pathname === "/Article" ?classes.active_link_SubMenu  : ""}`}>Articles </div>
                                </div>
                  </Link> 
            </div>
          )}

          {!freelancerShow ? (
            <div
              onClick={() => setFreelancerShow(true)}
              className={classes.ClientShowHeader}
            >
              <div className={classes.flex}>
                <FcBusinessman className={classes.sideBar_SideBarIcon} />
                <div className={classes.ClientUp}>Freelancer</div>
              </div>
              <div>
                <IoIosArrowDown size={20} />
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={() => setFreelancerShow(false)}
                className={classes.ClientShowHeader}
              >
                <div className={classes.flex}>
                  <FcBusinessman className={classes.sideBar_SideBarIcon} />
                  <div className={classes.ClientUp}>Freelancer</div>
                </div>
                <div>
                  <IoIosArrowUp size={20} />
                </div>
              </div>

              <Link to="/FreelancerSignUp" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/FreelancerSignUp"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    {" "}
                    SignUp
                  </div>
                </div>
              </Link>

               <Link to="/FreelancerLogin" className={classes.removeDeco}>
                                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                                  
                                    <div className={`${classes.link_SubMenu} ${pathname === "/FreelancerLogin" ?classes.active_link_SubMenu  : ""}`}>Freelancer Login </div>
                                </div>
                    </Link>  

              <Link to="/FreelancerProfile" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/FreelancerProfile"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    Freelancer Profile{" "}
                  </div>
                </div>
              </Link>

              <Link
                to="/FreelancerPackagePayment"
                className={classes.removeDeco}
              >
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/FreelancerPackagePayment"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    {" "}
                    Package Payment
                  </div>
                </div>
              </Link>

              <Link to="/KycForm" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/KycForm" ? classes.active_link_SubMenu : ""
                    }`}
                  >
                    Kyc
                  </div>
                </div>
              </Link>

              <Link to="/FreelancerCredit" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/FreelancerCredit" ? classes.active_link_SubMenu : ""
                    }`}
                  >
                  Freelancer Credit
                  </div>
                </div>
              </Link>

              <Link to="/freelancerContactus" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/freelancerContactus" ? classes.active_link_SubMenu : ""
                    }`}
                  >
                 Freelancer Contact Us
                  </div>
                </div>
              </Link>

              {/*       <Link to="/Profile" className={classes.removeDeco}>
                                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                                  
                                    <div className={`${classes.link_SubMenu} ${pathname === "/Profile" ?classes.active_link_SubMenu  : ""}`}>Profile</div>
                                </div>
                            </Link> 

                            <Link to="/ Live Profile" className={classes.removeDeco}>
                                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                                  
                                    <div className={`${classes.link_SubMenu} ${pathname === "/ Live Profile" ?classes.active_link_SubMenu  : ""}`}> Live Profile</div>
                                </div>
                            </Link> 


                            <Link to="/ Portfolio" className={classes.removeDeco}>
                                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                                  
                                    <div className={`${classes.link_SubMenu} ${pathname === "/ PortFolio" ?classes.active_link_SubMenu  : ""}`}> Portfolio</div>
                                </div>
                            </Link> 

                            <Link to="/ Package" className={classes.removeDeco}>
                                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                                  
                                    <div className={`${classes.link_SubMenu} ${pathname === "/Package" ?classes.active_link_SubMenu  : ""}`}> Package</div>
                                </div>
                    </Link>   */}
            </div>
          )}

{!faqShow ? (
            <div
              onClick={() => setFaqShow(true)}
              className={classes.ClientShowHeader}
            >
              <div className={classes.flex}>
                <FcAddressBook className={classes.sideBar_SideBarIcon} />
                <div className={classes.ClientUp}>Faq</div>
              </div>
              <div>
                <IoIosArrowDown size={20} />
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={() => setFaqShow(false)}
                className={classes.ClientShowHeader}
              >
                <div className={classes.flex}>
                  <FcAddressBook className={classes.sideBar_SideBarIcon} />
                  <div className={classes.ClientUp}>Faq</div>
                </div>
                <div>
                  <IoIosArrowUp size={20} />
                </div>
              </div>

              <Link to="/FrequentlyAskedQuestion" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/FrequentlyAskedQuestion"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    Frequently Asked Question{" "}
                  </div>
                </div>

              </Link>

              </div>
                  )}

{!otpShow ? (
            <div
              onClick={() => setOtpShow(true)}
              className={classes.ClientShowHeader}
            >
              <div className={classes.flex}>
                <FcApproval className={classes.sideBar_SideBarIcon} />
                <div className={classes.ClientUp}>Otp</div>
              </div>
              <div>
                <IoIosArrowDown size={20} />
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={() => setOtpShow(false)}
                className={classes.ClientShowHeader}
              >
                <div className={classes.flex}>
                  <FcApproval className={classes.sideBar_SideBarIcon} />
                  <div className={classes.ClientUp}>Otp</div>
                </div>
                <div>
                  <IoIosArrowUp size={20} />
                </div>
              </div>

              <Link to="/OtpList" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/OtpList"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    Otp List{" "}
                  </div>
                </div>
              </Link>

              </div>
          )}


{!olddataShow ? (
            <div
              onClick={() => setOldDataShow(true)}
              className={classes.ClientShowHeader}
            >
              <div className={classes.flex}>
                <FcAlphabeticalSortingZa className={classes.sideBar_SideBarIcon} />
                <div className={classes.ClientUp}>Old Data</div>
              </div>
              <div>
                <IoIosArrowDown size={20} />
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={() => setOldDataShow(false)}
                className={classes.ClientShowHeader}
              >
                <div className={classes.flex}>
                  <FcAlphabeticalSortingZa className={classes.sideBar_SideBarIcon} />
                  <div className={classes.ClientUp}>Old Data</div>
                </div>
                <div>
                  <IoIosArrowUp size={20} />
                </div>
              </div>

              <Link to="/OldWildCard" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/OldWildCard"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                    Old Wild Card{" "}
                  </div>
                </div>
              </Link>

              </div>
          )}

{!searchuserShow ? (
            <div
              onClick={() => setSearchUserShow(true)}
              className={classes.ClientShowHeader}
            >
              <div className={classes.flex}>
                <FcSearch className={classes.sideBar_SideBarIcon} />
                <div className={classes.ClientUp}>Search User </div>
              </div>
              <div>
                <IoIosArrowDown size={20} />
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={() => setSearchUserShow(false)}
                className={classes.ClientShowHeader}
              >
                <div className={classes.flex}>
                  <FcSearch className={classes.sideBar_SideBarIcon} />
                  <div className={classes.ClientUp}>Search User </div>
                </div>
                <div>
                  <IoIosArrowUp size={20} />
                </div>
              </div>

              <Link to="/ClientSearch" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/ClientSearch"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                  Client Search {" "}
                  </div>
                </div>
              </Link>

             <Link to="/FreelancerSearch" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/FreelancerSearch"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                  Freelancer Search {" "}
                  </div>
                </div>
                  </Link> 
              </div>
          )}

 {!freeJobAlertShow ? (
            <div
              onClick={() => setFreeJobAlertShow(true)}
              className={classes.ClientShowHeader}
            >
              <div className={classes.flex}>
                <FcAndroidOs className={classes.sideBar_SideBarIcon} />
                <div className={classes.ClientUp}>FreeJobAlert</div>
              </div>
              <div>
                <IoIosArrowDown size={20} />
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={() => setFreeJobAlertShow(false)}
                className={classes.ClientShowHeader}
              >
                <div className={classes.flex}>
                  <FcAndroidOs className={classes.sideBar_SideBarIcon} />
                  <div className={classes.ClientUp}>FreeJob Alert</div>
                </div>
                <div>
                  <IoIosArrowUp size={20} />
                </div>
              </div>

              <Link to="/FreeJobAlert" className={classes.removeDeco}>
                <div className={classes.sideBar_SideBarContainer_SubMenu}>
                  <div
                    className={`${classes.link_SubMenu} ${
                      pathname === "/FreeJobAlert"
                        ? classes.active_link_SubMenu
                        : ""
                    }`}
                  >
                   FreeJob Alert{" "}
                  </div>
                </div>
              </Link>

              </div>
          )}


          
          <Link
            to="/Logout"
            className={`${classes.link} ${
              pathname === "/Logout" ? classes.active_link : ""
            }`}
          >
            <div className={classes.sideBar_SideBarContainer}>
              <div
                className={`${classes.link} ${
                  pathname === "/Logout" ? classes.active_link : ""
                }`}
              >
                <GoogleLogout
              //  clientId={"878789164296-qpuam31qa0fl6h767akhldgpe2c6nnha.apps.googleusercontent.com"}
                  //    Live clientId
                 clientId={"503774238711-hneu6nndp2v02ahs163rhibbbdpvft0c.apps.googleusercontent.com"}
                  // Live clientId
                  // buttonText="Sign Out"
                  onLogoutSuccess={onLogoutSuccess}
                  className={classes.sideBar_LogoutBtn}
                >
                  <div className={classes.sideBar_SideBarTextContainer}>
                    <div className={classes.sideBar_SideBarTextContainer_a}>
                      Log Out
                    </div>
                  </div>
                  <div
                    className={`${classes.link} ${
                      pathname === "/Logout" ? classes.active_link : ""
                    }`}
                  ></div>
                </GoogleLogout>
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default SideBar;
