import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "../../Components/UIcomp/RightTable.module.css";
import ChatCases from "./ChatCases";
import HeaderForRightSec from '../../Components/UIcomp/HeadingForRightSec';

const ViewChat = () => {
  const { g_id } = useParams();
  const [chat, setChat] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    chatDetailsView();
  }, [currentPage]);

  const chatDetailsView = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      GroupId: g_id,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/client/MessagesOfGroup",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS") {
          setChat(result.data);
        } else {
          console.log("fail");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // const dateAndTimeHandler = (signUpDate) => {
  //   var date = new Date(signUpDate);
  //   return date.toString().slice(3, 25);
  // };
  return (
    <div className={classes.mainContainerChat}>
       <HeaderForRightSec headingText={"Chat"} />

      {!chat ? (
        <div className={classes.blank_Notification_div}>
          <div className={classes.blank_Notification_text}></div>
        </div>
      ) : (
        <div>
          <div className={classes.PaginationContainer}></div>&nbsp;
          {chat.length > 0 ? (
            <div className={classes.mainContainer}>
            
              <div className={classes.chatBox}>
                {chat.map((item) => {
                  return (
                    <>
                      <ChatCases
                        msgSender={item.MessageSender}
                        msg={item.message}
                        items={item}
                        mType={item.m_type}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className={classes.NoDataFound}>
              You do not have any chat yet
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewChat;
