import React, { useState, useEffect } from "react";
import SunEditor from "suneditor-react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { AiFillCopy } from "react-icons/ai";
import "suneditor/dist/css/suneditor.min.css";
import { useLocation } from "react-router-dom";
import copy from "copy-to-clipboard";
import classes from '../../Components/UIcomp/RightTable.module.css';
import HeaderForRightSec from "../../Components/UIcomp/HeadingForRightSec";


const EditArticle = () => {

  const location = useLocation();

  const [multipleImages, setmultipleImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [title, setTitle] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  
  const [videoUrl, setVideoUrl] = useState("");
  const [tags, setTags] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [singleImage, setSingleImage] = useState("");
  const [bannerDetailImage,setBannerDetailImage]=useState("")
  const [getbannerDetailImage,setGetbannerDetailImage]=useState("")
  const [articleSource, setArticleSource] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [catData, setCatData] = useState([]);
  const [subCatData, setsubCatData] = useState([]);
  const [articleSourceData, setArticleSourceData] = useState([]);
  const [authorAbout, setAuthorAbout] = useState([]);
  const [authorUrl, setAuthorUrl] = useState([]);
  const [lName, setLName] = useState([]);
  const [listOfName, setListOfName] = useState([]);
  const [publishArticle, setPublishArticle] = useState("0");
  const [paidArticle, setPaidArticle] = useState("0");
  const [featuredArticles, setFeaturedArticles] = useState("0");
  const [getSingleimage, setGetSingleimage] = useState("");
  const [getMultipleimage, setGetMultipleimage] = useState([]);
  const ArticleId = location.pathname.split("/").pop();

  useEffect(() => {
    articleCategories();
    getSingleArticleApi();
  }, []);


  const getSingleArticleApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "ArticleId": ArticleId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/GetSingleArticle",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setTitle(result.data.Title);
          setMetatitle(result.data.MetaTitle);
          setDescription(result.data.ShortDescription);
          setMetadescription(result.data.MetaDescription);
          setContent(result.data.Description);
          setLName(result.data.Author);
          setAuthorAbout(result.data.AboutAuthor);
          setAuthorUrl(result.data.AuthorUrl);
          setPublishDate(result.data.PublishDate.slice(0,10));
          setGetSingleimage(result.data.banner);
          setGetbannerDetailImage(result.data.ArticleImage)
          setGetMultipleimage(result.data.image);
          setVideoUrl(result.data.videourl);
          setTags(result.data.Tags);
          addCategories(result.data.Category);
          setSubCategory(result.data.Subcategory);
          setArticleSource(result.data.Source);
          setPaidArticle(result.data.PaidArticle);
          setFeaturedArticles(result.data.FeaturedArticle);
          setPublishArticle(result.data.IsPublished);
          // setPrefilledImage(result.data.banner);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const UpdateArticleApi = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );

    var formdata = new FormData();
    var formdata = new FormData();
    formdata.append("ArticleId", ArticleId);
    formdata.append("Title", title);
    formdata.append("MetaTitle", metatitle);
    formdata.append("ShortDescription", description);
    formdata.append("MetaDescription", metadescription);
    formdata.append("Description", content);
    formdata.append("PublishDate", publishDate);
    formdata.append("Banner", singleImage);
    formdata.append("ArticleImage", bannerDetailImage);
    formdata.append("Video", videoUrl?videoUrl.VideoUrl:videoUrl);
    formdata.append("Tags", tags);
    formdata.append("Category", category);
    formdata.append("Subcategory", subCategory);
    formdata.append("Source", articleSource);
    formdata.append("Author", lName);
    formdata.append("AboutAuthor", authorAbout);
    formdata.append("AuthorUrl", authorUrl);
    formdata.append("PaidArticle",  paidArticle );
    formdata.append("FeaturedArticle", featuredArticles);
    formdata.append("IsPublished", publishArticle);
  
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/UpdateArticles",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>{
        if(result.status_code===200 && result.status==="SUCCESS"){
          alert("Article updated successfully")
        }
        else{
          alert("there is some issue")
        }
      })
      .catch((error) => console.log("error", error));
  };

  const articleCategories = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/GetDropdownArticle",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCatData(result.Category);
        setsubCatData(result.Subcategory);
        setArticleSourceData(result.Source);
      })
      .catch((error) => console.log("error", error));
  };
 

  const UploadMultipleImageApi=()=>{
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");


var formdata = new FormData();
formdata.append("ArticleId", ArticleId);

for (let i = 0; i <= multipleImages.length; i++) {
  formdata.append("Image", multipleImages[i]);
}

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch("https://api-preview.rozgaarindia.com/api/rozgaarapi/AddrzarticleImage", requestOptions)
  .then(response => response.json())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
  }

  const imageHandleChange = (e) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImages((prevImages) => prevImages.concat(fileArray));
      Array.from(e.target.files).map((item) => {
        setmultipleImages((prevImages) => [...prevImages, item]);
      })
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };
console.log(multipleImages);

  const renderPhotos = (source) => {
    return source.map((item, index) => {
     
      return (
        <div className={classes.imageDiv}>
          <img
            src={item}
            key = {index}
            alt="article"
            
            className={classes.image_top}
          />
          <IoIosCloseCircleOutline className={classes.closeIcon} onClick={() => deleteImages(index)} />
          <AiFillCopy onClick={() => { copy(item); alert("Copied") }} />

        </div>
      );
    });
  };

  const deleteImages = (id)=> {
     setSelectedImages(selectedImages.filter((item, index) => {
       return index !== id;
     }))
     setmultipleImages(multipleImages.filter((item , index) => {
       return index !== id;
     }))
   }
  

  const addCategories = (selectedCategories) => {
    setCategory((prevCategories) => {
      return [...prevCategories, selectedCategories];
    });
  };

  const deleteCategories = (id) => {
    setCategory((prevCat) => {
      return prevCat.filter((item, index) => {
        return index !== id;
      });
    });
  };

  const refreshPage=()=>{ 
    window.location.reload(); 
}

  const deleteImagesApi = (id) => {
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "ImageId": id ,
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://api-preview.rozgaarindia.com/api/rozgaarapi/DeleterzarticleImage", requestOptions)
  .then(response => response.json())
  .then(result =>{
    if(result.status_code === 200 && result.status === "SUCCESS"){
      alert("Image Deleted Sucessfully")
      refreshPage()
    }
  })
  .catch(error => console.log('error', error));
  }

  const formValidation = () => {
    let isValid = true;
    let error = {};
    if (title === "") {
      isValid = false;
      error.title = "Title is required";
    }
    if (metatitle === "") {
      isValid = false;
      error.metatitle = "meta title is required";
    }
    if (description === "") {
      isValid = false;
      error.description = "description is required";
    }
    if (metadescription === "") {
      isValid = false;
      error.metadescription = "meta description is required";
    }
    if (content === "") {
      isValid = false;
      error.content = "content is required";
    }
    if (publishDate === "") {
      isValid = false;
      error.publishDate = "publish Date is required";
    }
    // if (singleImage === "") {
    //   isValid = false;
    //   error.singleImage = "singleImage is required";
    // }
    if (videoUrl === "") {
      isValid = false;
      error.videoUrl = "videoUrl is required";
    }
    if (tags === "") {
      isValid = false;
      error.tags = "tags is required";
    }
    if (category === "") {
      isValid = false;
      error.category = "category is required";
    }
    if (subCategory === "") {
      isValid = false;
      error.subCategory = "subCategory is required";
    }
    if (articleSource === "") {
      isValid = false;
      error.articleSource = "articleSource is required";
    }  
    if (lName === "") {
      isValid = false;
      error.lName = "Author Name is required";
    }  
    if (authorAbout === "") {
      isValid = false;
      error.authorAbout = "authorAbout is required";
    }
    if (authorUrl === "") {
      isValid = false;
      error.authorUrl = "authorUrl is required";
    }
   
    setErrors(error);
    return isValid;
  };
  const handleChange = (content) => {
    setContent(content);
  };

console.log("getMultipleimage:",
)
console.log("videoUrl",videoUrl)

  return (
    <div className={classes.MainContainer}>
      <HeaderForRightSec headingText={"EditArticle"} />

      <h4 className={classes.ArticleHeading}>Article ID : {ArticleId}</h4>
      <form 
      onSubmit={(e) => {
          e.preventDefault();
          formValidation();UpdateArticleApi();UploadMultipleImageApi()
        }}
        >
        <div className={classes.form_container}>
          <div className={classes.left}>
            <div className={classes.formeditor}>
              <label htmlFor="text">Title : </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <div className={classes.form_error_msg_captcha}>
                {errors.title}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label htmlFor="text">Meta Title : </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="meta title"
                value={metatitle}
                onChange={(e) => {
                  setMetatitle(e.target.value);
                }}
              />
              <div className={classes.form_error_msg_captcha}>
                {errors.metatitle}
              </div>
            </div>

            <div className={classes.formeditor}>
              <label htmlFor="shortDescription">Short Description : </label>
              <textarea
                rows="4"
                cols="50"
                id="shortDescription"
                name="shortDescription"
                placeholder="write description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <div className={classes.form_error_msg_captcha}>
                {errors.description}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label htmlFor="text">Meta Description : </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="meta description"
                value={metadescription}
                onChange={(e) => {
                  setMetadescription(e.target.value);
                }}
              />
              <div className={classes.form_error_msg_captcha}>
                {errors.metadescription}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label htmlFor="Content">Content :</label>
              <div className={classes.content}>
                <SunEditor
                  height="auto"
                  setContents={content}
                  onChange={handleChange}
                  defaultValue={content}
                  placeholder="Enter your description here..."
                  setOptions={{
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "hiliteColor",
                        "removeFormat",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "fullScreen",
                        "showBlocks",
                        "codeView",
                        "print",
                        "save",
                      ],
                    ],
                  }}
                />
              </div>
              <div className={classes.form_error_msg_captcha}>
                {errors.content}
              </div>
            </div>
            <div className={classes.submitDiv}>
              <button type="submit" className={classes.submitButton}>
                Update
              </button>
            </div>
          </div>
          <div className={classes.right}>
            <h4 className={classes.ArticleHeading_others}>Other Details :</h4>

            <div className={classes.formeditor}>
              <label>Publish Date : </label>
              <input
                type="date"
                id="filepicker"
                placeholder="yyyy-mm-dd"
                name="fileList"
                value={publishDate}
                onChange={(e) => setPublishDate(e.target.value)}
                webkitdirectory
                className={classes.date_input}
              ></input>
              <div className={classes.form_error_msg_captcha}>
                {errors.publishDate}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label>Upload banner home Image : </label>
              <input
                type="file"
                id="images"
                name="image"
                onChange={(e) => setSingleImage(e.target.files[0])}
              ></input>
               <div className={classes.form_error_msg_captcha}>
                {errors.singleImage}
              </div>
            </div>

            {getSingleimage ? (
              <>
                <div>Prefilled Image</div>
                <img
                  src={getSingleimage.BannerImageUrl}
                  className={classes.prefilledImage}
                />
              </>
            ) : (
              "No image uploaded"
            )}


<div className={classes.formeditor}>
              <label>Uploaded banner detail image: </label>
              <input
                type="file"
                id="images1"
                name="image"
                onChange={(e) => setBannerDetailImage(e.target.files[0])}
              ></input>
              
               <div className={classes.form_error_msg_captcha}>
                {errors.bannerDetailImage}
              </div>
            </div>

            {getbannerDetailImage ? (
              <>
                <div>Prefilled Uploaded banner detail image</div>
                <img
                  src={getbannerDetailImage.ArticleImage}
                  className={classes.prefilledImage}
                />
              </>
            ) : (
              "No image uploaded"
            )}
            <div className={classes.formeditor}>
              <label htmlFor="image">Upload Multiple Image : </label>
              <input
                type="file"
                name="image"
                id="image"
                multiple
                onChange={ imageHandleChange}
                className={classes.image}
              />


              {getMultipleimage
                ? getMultipleimage.map((item , index) => {
                    return (
                     <div className={classes.imageDiv}>
                        <img
                          src={item.ImageUrl}
                          className={classes.image_top}
                          
                        />
                        <IoIosCloseCircleOutline className={classes.closeIcon} onClick={() => deleteImagesApi(item.ImageId)} />
                        <AiFillCopy onClick={() => { copy(item.ImageUrl); alert("Copied") }} className={classes.copyIcon}/>
                      </div>
                    );
                  })
                : "No images uploaded"}
                
            </div>

            <div className={classes.articlemultipleImagea} >
              {renderPhotos(selectedImages)}
            </div>

            <div className={classes.formeditor}>
              <label>Video: </label>
              <input
                type="text"
                id="video"
                placeholder="Youtube URL"
                value={videoUrl?videoUrl.VideoUrl:videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              ></input>
               <div className={classes.form_error_msg_captcha}>
                {errors.videoUrl}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label>Tags: </label>
              <input
                type="text"
                id="tags"
                placeholder="Tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              ></input>
              <div className={classes.form_error_msg_captcha}>
                {errors.tags}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label>Category: </label>
              <select
                className={classes.dropDownClass}
                onChange={(e) => addCategories(e.target.value)}
              >
                {/* <option>---Select Category---</option> */}
                {catData.map((item, index) => {
                  return (
                    <option key={index} value={item.Category}>
                      {" "}
                      {item.Category}{" "}
                    </option>
                  );
                })}
              </select>
              <div className={classes.form_error_msg_captcha}>
                {errors.category}
              </div>
            </div>
            <div className={classes.mapDiv}>
              {category.map((item, index) => {
                return (
                  <div className={classes.categories}>
                    {item}
                    <AiOutlineClose
                      onClick={(e) => deleteCategories(index)}
                      className={classes.deleteCategoryIcon}
                    />
                  </div>
                );
              })}
            </div>
            <div className={classes.formeditor}>
              <label>Sub-Category: </label>
              <select
                className={classes.dropDownClass}
                onChange={(e) => setSubCategory(e.target.value)}
              >
                {/* <option>---Select Sub-Category---</option> */}
                {subCatData.map((item, index) => {
                  return <option key={index}> {item.Subcategory} </option>;
                })}
              </select>
              <div className={classes.form_error_msg_captcha}>
                {errors.subCategory}
              </div>
            </div>
            <div className={classes.formeditor}>
              <label>Article Source: </label>
              <select
                className={classes.dropDownClass}
                onChange={(e) => setArticleSource(e.target.value)}
              >
                {/* <option>---Select Article Source--</option> */}
                {articleSourceData.map((item, index) => {
                  return <option key={index}> {item.Source} </option>;
                })}
              </select>
              <div className={classes.form_error_msg_captcha}>
                {errors.articleSource}
              </div>
            </div>
            {/* <div className={classes.formeditor}>
            <label htmlFor="author">Og Twitter: </label>
            <input type="text" name="Twitter" placeholder="og twitter" value={ogtwitter}
              onChange={(e) => {
                setOgtwitter(e.target.value)
              }} />
            <div className={classes.form_error_msg_captcha}>{errors.ogtwitter}</div>
          </div>
          <div className={classes.formeditor}>
            <label htmlFor="author">Og Facebook: </label>
            <input type="text" name="Facebook" placeholder="og facebook" value={ogfacebook} onChange={(e) => {
              setOgfacebook(e.target.value)
            }} />
            <div className={classes.form_error_msg_captcha}>{errors.ogfacebook}</div>
          </div>
          <div className={classes.formeditor}>
            <label htmlFor="author">Og Instagram: </label>
            <input type="text" name="Instagram" placeholder="og instagram" value={oginstagram} onChange={(e) => {
              setOginstagram(e.target.value)
            }} />
            <div className={classes.form_error_msg_captcha}>{errors.oginstagram}</div>
          </div>
          <div className={classes.formeditor}>
            <label htmlFor="author">Og Linkedin: </label>
            <input type="text" name="Linkedin" placeholder="og linkedin" value={oglinkedin} onChange={(e) => {
              setOglinkedin(e.target.value)
            }} />
            <div className={classes.form_error_msg_captcha}>{errors.oglinkedin}</div>
          </div> */}
            <div className={classes.box_author}>
              <div className={classes.formeditor}>
                <label htmlFor="author name:">Author Name: </label>
                <input
                  type="text"
                  name="author name"
                  placeholder="author name"
                  onChange={(e) => {
                    setLName(e.target.value);
                  }}
                  value={lName}
                />
                <div className={classes.form_error_msg_captcha}>
                  {errors.lName}
                </div>
              </div>
              <div className={classes.formeditor}>
                <label htmlFor="about author">About Author: </label>
                <input
                  type="text"
                  name="about author"
                  placeholder="about author"
                  onChange={(e) => {
                    setAuthorAbout(e.target.value);
                  }}
                  value={authorAbout}
                />
                <div className={classes.form_error_msg_captcha}>
                  {errors.authorAbout}
                </div>
              </div>
              <div className={classes.formeditor}>
                <label htmlFor="author url">Url: </label>
                <input
                  type="text"
                  name="url"
                  placeholder="author url"
                  onChange={(e) => {
                    setAuthorUrl(e.target.value);
                  }}
                  value={authorUrl}
                />
                <div className={classes.form_error_msg_captcha}>
                  {errors.authorUrl}
                </div>
              </div>
            </div>
            {/* <button
            type="button"
            onClick={() => { onSubmitHandler(); }}
            className={classes.button_author}
          >
            Add
          </button>

          {listOfName.map((item, index) => {
            return (<>
              <div className={item.lName.length > 0 || item.authorAbout.length > 0 || item.authorUrl.length > 0 ? classes.author_name : <></>}>
                <div className={classes.del} onClick={() => { deleteNote(index) }}> {item.lName.length > 0 || item.authorAbout.length > 0 || item.authorUrl.length > 0 ? <><TiDelete className={classes.deleteIcon} /></> : <></>}
                  <div className={item.lName.length > 0 ? classes.author : <></>}>{item.lName}</div>
                  <div className={item.authorAbout.length > 0 ? classes.author : <></>}>{item.authorAbout}</div>
                  <div className={item.authorUrl.length > 0 ? classes.author : <></>}>{item.authorUrl}</div>
                </div>
              </div>
            </>
            );
          })} */}
            <div className={classes.checkboxDiv}>
              <label>Paid Article : </label>
              <input
                type="checkbox"
                className={classes.checkBoxClass}
                checked={paidArticle==="0"?"":paidArticle}
                onChange={(e) =>
                  setPaidArticle(paidArticle === "0" ? "1" : "0")
                }
              />
              <label>Featured Article : </label>
              <input
                type="checkbox"
                className={classes.checkBoxClass}
                checked={featuredArticles==="0"?"":featuredArticles}

                onChange={(e) =>
                  setFeaturedArticles(featuredArticles === "1" ? "1" : "0")
                }
              />
              <label>Live : </label>
              <input
                type="checkbox"
                className={classes.checkBoxClass}
                checked={publishArticle==="0"?"":publishArticle}

                onChange={(e) =>
                  setPublishArticle(publishArticle === "1" ? "1" : "0")
                }
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditArticle;
