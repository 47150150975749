import { useState } from "react";
import ReactExport from "react-data-export";
import classes from "./TablesExcelDir.module.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TablesExcelDir = (props) => {
  const [downloadPassword, setDownloadPassword] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  
  const passwordStatic = "abc";

  const checkAuth = (pass) => {
    console.log("password", passwordCheck);
    if (passwordCheck === "abc") {
      setDownloadPassword(true);
      console.log("Done");
    } else {
      console.log("No");
      setErrorMsg("Please enter a valid password");
    }
  };
  {console.log(props.tableData)}
  const tableSwitch = () => {
    switch (props.tableType) {
      case "FreelancerSignup":
        return (
          <ExcelFile
            filename={"FreelancerSignup"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Country" value="c_ountyr" />
              <ExcelColumn label="First Name" value="f_name" />
              <ExcelColumn label="Last Name" value="l_name" />
              <ExcelColumn label="User Name" value="u_name" />
              <ExcelColumn label="Email" value="e_mail" />
              <ExcelColumn label="Mobile" value="m_obile" />
              <ExcelColumn label="Date" value="u_pdatedat" />
            </ExcelSheet>
          </ExcelFile>
        );
      case "FreelancerLogin":
        return (
          <ExcelFile
            filename={"Freelancer Login"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Country" value="Country" />
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label="First Name" value="FirstName" />
              <ExcelColumn label="Last Name" value="LastName" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
               <ExcelColumn label="Date" value="UpdatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );
      case "freelancer":
        return (
          <ExcelFile
            filename={"Freelancer Profile"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Profile">
              <ExcelColumn label="Country" value="Country" />
              <ExcelColumn label="First Name" value="UserfirstName" />
              <ExcelColumn label="Last Name" value="UserlastName" />
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label="Email" value="UserEmail" />
              <ExcelColumn label="Mobile" value="UserMoblile" />
             <ExcelColumn label="Percent" value="Percent" />
              <ExcelColumn label="Status" value="ProfileStatus" />
              <ExcelColumn label="Date" value="UpdatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );
      case "FreelancerPackage":
        return (
          <ExcelFile
            filename={"Freelancer Package"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Order Id" value="PGOrderId" />
              <ExcelColumn label="GST" value="GST" />
              <ExcelColumn label="Amount" value="GrandTotal" />
              <ExcelColumn label="Status" value="PaymentStatus" />
              <ExcelColumn label="First Name" value="FreelancerFirstName" />
              <ExcelColumn label="Last Name" value="FreelancerLastName" />
              <ExcelColumn label="Username" value="FreelancerUserName" />
              <ExcelColumn label="Email" value="FreelancerEmail" />
              <ExcelColumn label="Mobile" value="FreelancerMobile" />
              <ExcelColumn label="Date" value="CreatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );
      case "KycForm":
        return (
          <ExcelFile
            filename={"KycForm"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Username" value="UserName" />
              <ExcelColumn label="Full Name" value="FullName" />

              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="Status" value="Status" />
              <ExcelColumn label="Date" value="UpdatedDate" />
            </ExcelSheet>
          </ExcelFile>
        );
      case "FreelancerCredit":
        return (
          <ExcelFile
            filename={"FreelancerCredit"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Username" value="UserName" />
              <ExcelColumn label="First Name" value="FirstName" />
              <ExcelColumn label="Last Name" value="LastName" />

              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="Credit Count<" value="TotalCount" />
              <ExcelColumn label="Credit Used<" value="TotalUse" />
              <ExcelColumn label="Credit Left<" value="TotalLeft" />
              <ExcelColumn label="Date" value="CreatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );

        case "FreelancerContactUs":
          return (
            <ExcelFile
              filename={"FreelancerContactUs"}
              element={
                <div
                  style={{
                    marginLeft: 20,
                    marginTop: 20,
                    textAlign: "right",
                    marginRight: 20,
                  }}
                >
                  {" "}
                  <button>Download Excel</button>{" "}
                </div>
              }
            >
            
              <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Name" value="Name" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="Subject" value="Subject" />
              <ExcelColumn label="Message" value="Message" />
              <ExcelColumn label="ContactType" value="ContactType" />
              <ExcelColumn label="Upload" value="FileUpload" />
              <ExcelColumn label="Date" value="UpdatedDate" />
            </ExcelSheet>
          </ExcelFile>
          );

      case "Clientsignup":
        return (
          <ExcelFile
            filename={"Clientsignup"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Country" value="Country" />
              <ExcelColumn label="First Name" value="FirstName" />
              <ExcelColumn label="Last Name" value="LastName" />
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="Date" value="UpdatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );
      case "ClientLogin":
        return (
          <ExcelFile
            filename={"Client Login"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Country" value="Country" />
              <ExcelColumn label="First Name" value="FirstName" />
              <ExcelColumn label="Last Name" value="LastName" />
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="Date" value="UpdatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "Client":
        return (
          <ExcelFile
            filename={"Client"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Country" value="Country" />
              <ExcelColumn label="First Name" value="FirstName" />
              <ExcelColumn label="Last Name" value="LastName" />
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="Co/In" value="CompanyName" />
              <ExcelColumn label="Date" value="UpdatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "Post A  Requirement":
        return (
          <ExcelFile
            filename={"Post A  Requirement"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="RequirementID" value="RequirementID" />
              <ExcelColumn label="Status" value="Status" />
              <ExcelColumn label="First Name" value="FirstName" />
              <ExcelColumn label="Last Name" value="LastName" />
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label="RequirementType" value="RequirementType" />
              <ExcelColumn label="AddonName" value="AddonName" />
              <ExcelColumn label="FreelancersCount" value="Applied" />
              <ExcelColumn label="Post Title" value="Title" />
              <ExcelColumn label="Post Status" value="Status" />
              <ExcelColumn label="Date" value="UpdatedDate" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "ContactUs":
        return (
          <ExcelFile
            filename={"ContactUs"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Name" value="Name" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="Subject" value="Subject" />
              <ExcelColumn label="Message" value="Message" />
              <ExcelColumn label="ContactType" value="ContactType" />
              <ExcelColumn label="Upload" value="FileUpload" />
              <ExcelColumn label="Date" value="UpdatedDate" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "BulkHiring":
        return (
          <ExcelFile
            filename={"BulkHiring"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label="First Name" value="FirstName" />
              <ExcelColumn label="Last Name" value="LastName" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="FreelancersCount" value="FreelancersCount" />
              <ExcelColumn label="Date" value="UpdatedDate" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "ClientAddon":
        return (
          <ExcelFile
            filename={"ClientAddon"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Order Id" value="PGOrderId" />
              <ExcelColumn label="GST" value="GST" />
              <ExcelColumn label="Amount" value="GrandTotal" />
              <ExcelColumn label="Status" value="PaymentStatus" />
              <ExcelColumn label="First Name" value="ClientFirstName" />
              <ExcelColumn label="Last Name" value="ClientLastName" />
              <ExcelColumn label="User Name" value="ClientUserName" />
              <ExcelColumn label="Email" value="ClientEmail" />
              <ExcelColumn label="Mobile" value="ClientMobile" />
              <ExcelColumn label="Date" value="CreatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "Client Deposit":
        return (
          <ExcelFile
            filename={"Client Deposit"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Order Id" value="PGOrderId" />
              <ExcelColumn label="GST" value="GST" />
              <ExcelColumn label="Deposit " value="GrandTotal" />
              <ExcelColumn label="Status" value="PaymentStatus" />
              <ExcelColumn label=" Client First Name" value="ClientFirstName" />
              <ExcelColumn label=" Client Last Name" value="ClientLastName" />
              <ExcelColumn label="Client Username" value="ClientUserName" />
              <ExcelColumn
                label=" Freelancer First Name"
                value="FreelancerFirstName"
              />
              <ExcelColumn
                label=" Freelancer Last Name"
                value="FreelancerLastName"
              />
              <ExcelColumn
                label="Freelancer Username"
                value="FreelancerUserName"
              />
              <ExcelColumn label="Date" value="CreatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "ClientReqDetail":
        return (
          <ExcelFile
            filename={"ClientReqDetail"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="Order Id " value="PGOrderId" />
              <ExcelColumn label="GST" value="GST" />
              <ExcelColumn label="Amount" value="GrandTotal" />
              <ExcelColumn label="Status" value="PaymentStatus" />
              <ExcelColumn label="UserName" value="ClientUserName" />
              <ExcelColumn label="Email" value="ClientEmail" />
              <ExcelColumn label="Mobile" value="ClientMobile" />
              <ExcelColumn label="Date" value="CreatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );
        case "Article":
          return (
            <ExcelFile
              filename={"Article"}
              element={
                <div
                  style={{
                    marginLeft: 20,
                    marginTop: 20,
                    textAlign: "right",
                    marginRight: 20,
                  }}
                >
                  {" "}
                  <button>Download Excel</button>{" "}
                </div>
              }
            >
              <ExcelSheet data={props.tableData} name="Freelancer Signup">
                <ExcelColumn label="Article Id" value="ArticleId" />
                <ExcelColumn label="Title" value="Title" />
                <ExcelColumn label="Short Description" value="ShortDescription" />
                <ExcelColumn label="Date" value="UpdatedAt" />
               
              </ExcelSheet>
            </ExcelFile>
          );
      case "FrequentlyAskedQuestion ":
        return (
          <ExcelFile
            filename={"FrequentlyAskedQuestion "}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="FAQ ID" value="FAQID" />
              <ExcelColumn label="Question" value="Question" />
              <ExcelColumn label="Answer" value="Answer" />
              <ExcelColumn label="Date" value="UpdatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );
      case "OtpList":
        return (
          <ExcelFile
            filename={"OtpList"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
              <ExcelColumn label="OTP" value="Otp" />
              <ExcelColumn label="Date" value="CreatedAt" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "OldWildCard":
        return (
          <ExcelFile
            filename={"OldWildCard"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="User Name" value="username" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Mobile" value="mobile" />
              <ExcelColumn label="GSkill" value="gskill" />
              <ExcelColumn label="Admin Status" value="admin_status" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Package Type" value="package_type" />
              <ExcelColumn label="Category Name" value="cat_name" />
              <ExcelColumn label="Seller Email" value="seller_email" />
              <ExcelColumn label="Date" value="created_at" />
            </ExcelSheet>
          </ExcelFile>
        );
      case "ClientSearch":
        return (
          <ExcelFile
            filename={"ClientSearch"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="User Name" value="UserName" />
             <ExcelColumn label=" First Name" value="FirstName" />
              <ExcelColumn label=" Last Name" value="LastName" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
            </ExcelSheet>
          </ExcelFile>
        );

      case "FreelancerSearch":
        return (
          <ExcelFile
            filename={"FreelancerSearch"}
            element={
              <div
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                {" "}
                <button>Download Excel</button>{" "}
              </div>
            }
          >
            <ExcelSheet data={props.tableData} name="Freelancer Signup">
              <ExcelColumn label="User Name" value="UserName" />
              <ExcelColumn label=" First Name" value="FirstName" />
              <ExcelColumn label=" Last Name" value="LastName" />
              <ExcelColumn label="Email" value="Email" />
              <ExcelColumn label="Mobile" value="Mobile" />
            </ExcelSheet>
          </ExcelFile>
        );

        case "FreeJobAlert":
          return (
            <ExcelFile
              filename={"FreeJobAlert"}
              element={
                <div
                  style={{
                    marginLeft: 20,
                    marginTop: 20,
                    textAlign: "right",
                    marginRight: 20,
                  }}
                >
                  {" "}
                  <button>Download Excel</button>{" "}
                </div>
              }
            >
              <ExcelSheet data={props.tableData} name="Freelancer Signup">
              
                <ExcelColumn label="Subscriber Id" value="SubscriberId" />
                <ExcelColumn label="Email" value="Email" />
                <ExcelColumn label="Job Type" value="JobsType" />
                <ExcelColumn label="Date" value="UpdatedAt" />
                
              </ExcelSheet>
            </ExcelFile>
          );
      default:
        return "foo";
    }
  };

  return (
    <div>
      {" "}
      {downloadPassword ? (
        <div> {tableSwitch()} </div>
      ) : (
        <>
          <div className={classes.inputandButton}>
            <form className={classes.inputLocation}>
              {" "}
              <input
                type={"password"}
                placeholder="Enter passsword"
                name="password"
                autocomplete="on"
                value={passwordCheck}
                onChange={(e) => {
                  setErrorMsg("");
                  setPasswordCheck(e.target.value);
                }}
              />
            </form>
            <div
              className={classes.buttonForPassword}
              onClick={() => {
                checkAuth() 
              }}
            >
              {" "}
              Submit{" "}
            </div>
          </div>
          {errorMsg && <div className={classes.errorMess}> {errorMsg} </div>}
        </>
      )}
    </div>
  );
};

export default TablesExcelDir;
