import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import classes from '../../Components/UIcomp/RightTable.module.css';
import HeaderForRightSec from "../../Components/UIcomp/HeadingForRightSec";

const ViewArticle = () => {
  
    const [showArticles, setShowArticles] = useState('');
    const [image, setImage] = useState('');
    
    const { articleId } = useParams()

    useEffect(() => {
        singleArticle()
    }, [articleId]);

    const singleArticle = () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        ArticleId: articleId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://api-preview.rozgaarindia.com/api/rozgaarapi/GetSingleArticle",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
            setShowArticles(result.data)
            setImage(result.data.banner)

        })
        .catch((error) => console.log("error", error));
    };
 var html = showArticles.Description;
    var div = document.createElement("div");
    div.innerHTML = html;
  // console.log(div.innerText); 
// console.log(showArticles.Description);
    return (
        <div className={classes.MainContainer}>
           <HeaderForRightSec headingText={"ViewArticle"} />
        
            <h4 className={classes.ArticleHeading}>Article Id :{showArticles.ArticleId} </h4>
            <form>
            <div className={classes.FormContainer}>
                <div className={classes.left}>
                <div>
                    
                </div>
                <div className={classes.formGroup}>
                            <h4 className={classes.formGroup_TextTitle}><span>Title :</span> <b>{showArticles.Title}</b></h4>
                        </div>
                   
                        <div>
                            <p className={classes.date_article}><span className={classes.sub_heading}>Publish date :</span> {showArticles.CreatedAt}</p>
                         </div>

                        <div>
                            <p className={classes.artical_date}><span className={classes.sub_heading}>Short Description : </span>{showArticles.ShortDescription} </p>
                        </div>

                        <div className={classes.Img_Artical}>
                            <img src={image.BannerImageUrl} alt="" height="500" width="600"/>
                        </div>
                        
                        
                            <span className={classes.sub_heading_bottom}>Content :</span><div  />
                         
                            <div className={classes.short}>{div.innerText}</div>
                        
                </div>
                <div className={classes.right}>
                <h5 className={classes.otherDetailHeading}>Other Details</h5>
                <div className={classes.formGroup}>
                            <p className={classes.artical_date}><span className={classes.sub_heading}>Featured Article :</span> {showArticles.FeaturedArticle}</p>
                            <p className={classes.artical_date}><span className={classes.sub_heading}>Live :</span>{showArticles.IsPublished}</p>
                            <p className={classes.artical_date}><span className={classes.sub_heading}>Paid Article :</span>{showArticles.PaidArticle}</p>

                        </div>



                </div>
            </div>
                
            </form>
       
        </div>
    )
}

export default ViewArticle
