import React, { useEffect, useState } from "react";
import classes from "../KycForm/KycForm.module.css";
import { useLocation } from "react-router-dom";
import HeaderForRightSec from "../../../Components/UIcomp/HeadingForRightSec";
import Modal from "../../../Components/UserDetails/Modal";
import Loader from "react-loader-spinner";

const ViewKycSingleDetail = () => {

  const location = useLocation();

  const [data, setData] = useState("");
  const [approve, setApprove] = useState("");
  const [reject, setReject] = useState("");
  const [reason, setReason] = useState("");
  const FreelancerId = location.pathname.split("/").pop();
  const [show, setShow] = useState('')
  const [approveStatus, setApproveStatus] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    UserKycDetail();
  }, []);

  const UserKycDetail = () => {

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: FreelancerId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/KycDetailbyId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setData(result.data);
          setApproveStatus(result.data.Status)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const KycUpdated = (FreelancerId, status) => {
    setIsLoading(true);

    if (status === "Approved") setApprove(approve, FreelancerId);
    else if (status === "Rejected" ) setReject(reject, FreelancerId);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: FreelancerId,
      Status: status,
      StatusReason: reason,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/kycbyStatusUpdate",
      requestOptions
    )
    .then((response) => response.json())
    .then(result => {
      if (result.status_code === 200) {
        if (status === "Approved") {
          alert(" Your KYC is approved successfully")
        }
        else {
          alert("Your KYC is rejected")
        }
        UserKycDetail()
      }

      if (result.status_code === 300) {
        alert("Please enter the reason for approve or reject");
      }
    }).catch((error) => console.log("error", error))
    .finally(() => {
      setIsLoading(false);
    });
};


  return (
 
   <div className={classes.MainContainer}>

        <HeaderForRightSec headingText={"Kyc Detail Form"} />

        <div className={classes.contentSectionLeft}>
          <div className={classes.statusText}>
        <div className={classes.statusTextContainer}>{data.Status}</div>
        </div>
          <div className={classes.userInfoContainer}>
         
            <div className={classes.individualSections}>
              
              <div className={classes.mainHeading}> User Information</div>

              <div className={classes.imgContainer}>
         {/* {<img className={classes.profile} src={"https://freesvg.org/img/abstract-user-flat-4.png"} height={80} width={80} alt="clientProfile" /> } */}
         <div className={classes.nameContainer}>{data.FullName&&data.FullName.charAt(0).toUpperCase() }</div>
        </div>
       
              <div className={classes.heading}>
                {" "}
               
                ID : <span className={classes.response}>{data.ID}</span>
              </div>
              <div className={classes.heading}>
                {" "}
                Full Name : {" "}
                <span className={classes.response}>{data.FullName}</span>
              </div>
              <div className={classes.heading}>
                {" "}
                Email : <span className={classes.response}>{data.Email} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Mobile Number : {" "}
                <span className={classes.response}>{data.Mobile} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Date of Birth : {" "}
                <span className={classes.response}>{data.DateOfBirth} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Gender : {" "}
                <span className={classes.response}>{data.Gender} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Address : {" "}
                <span className={classes.response}>{data.Address} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                State : <span className={classes.response}>{data.State} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                City : <span className={classes.response}>{data.City} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Pin Code : {" "}
                <span className={classes.response}>{data.Pincode}</span>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.contentSectionLeft}>
          <div className={classes.userInfoContainerSecond}>
            <div className={classes.individualSections}>
              <div className={classes.mainHeading}> Document Information</div>
              <div className={classes.heading}>
                {" "}
                Id Proof Front : {" "}
                <span className={classes.response}>
                  <a href={data.DocumentFrontSide} target="_blank">
                    <img
                      src={data.DocumentFrontSide}
                      width={80}
                      height={80}
                    />
                  </a>
                </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Id Proof Back : {" "}
                <span className={classes.response}>
                  <a href={data.UploadDocumentBackside} target="_blank">
                    {" "}
                    <img
                      src={data.UploadDocumentBackside}
                      width={80}
                      height={80}
                    />
                  </a>
                </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Account Holder Name :{" "}
                <span className={classes.response}>
                  {data.AccountHolderName}{" "}
                </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Account Holder Number : {" "}
                <span className={classes.response}>{data.AccountNumber} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                IFSC : <span className={classes.response}>{data.Ifsc} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Bank Name : {" "}
                <span className={classes.response}>{data.BankName} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Branch Name : {" "}
                <span className={classes.response}>{data.Branchname} </span>
              </div>
              <div className={classes.heading}>
                {" "}
                Uploaded Pan : {" "}
                <span className={classes.response}>
                  <a href= {data.PanCard} target="_blank">
                    <img src={data.PanCard} width={80} height={80} />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* 
        <div className={classes.table_container}>
          <div className={classes.signup_details_table}>
            <div className={classes.table_row}>
              <div className={classes.heading}>ID</div>
              <div className={classes.table_heading}>Full Name</div>
              <div className={classes.table_heading}>Email</div>
              <div className={classes.table_heading}>Mobile Number</div>
              <div className={classes.table_heading}>Date of Birth</div>
              <div className={classes.table_heading}>Gender</div>
              <div className={classes.table_heading}>Address</div>
              <div className={classes.table_heading}>State</div>
              <div className={classes.table_heading}>City</div>
              <div className={classes.table_heading}>Pin Code</div>

              <div className={classes.table_heading}>Id Proof Front</div>
              <div className={classes.table_heading}>Id Proof Back</div>
              <div className={classes.table_heading}>Account Holder Name</div>
              <div className={classes.table_heading}>Account Holder Number</div>
              <div className={classes.table_heading}>IFSC</div>
              <div className={classes.table_heading}>Bank Name</div>
              <div className={classes.table_heading}>Branch Name</div>
              <div className={classes.table_heading}>Uploaded Pan</div>
            </div>

            <div>
              <div className={classes.table_row}>
                <div className={classes.table_td}>{data.ID}</div>
                <div className={classes.table_td}>{data.FullName}</div>
                <div className={classes.table_td}>{data.Email}</div>
                <div className={classes.table_td}>{data.Mobile}</div>
                <div className={classes.table_td}>{data.DateOfBirth}</div>
                <div className={classes.table_td}>{data.Gender}</div>
                <div className={classes.table_td}>{data.Address}</div>
                <div className={classes.table_td}>{data.State}</div>
                <div className={classes.table_td}>{data.City}</div>
                <div className={classes.table_td}>{data.Pincode}</div>

                <div className={classes.table_td}>{data.DocumentFrontSide}</div>
                <div className={classes.table_td}>
                  {data.UploadDocumentBackside}
                </div>
                <div className={classes.table_td}>{data.AccountHolderName}</div>
                <div className={classes.table_td}>{data.AccountNumber}</div>
                <div className={classes.table_td}>{data.Ifsc}</div>
                <div className={classes.table_td}>{data.BankName}</div>
                <div className={classes.table_td}>{data.Branchname}</div>
                <div className={classes.table_td}>{data.PanCard}</div>
              </div>
            </div>
          </div>
        </div> */}

        <div className={classes.postARequirementLiveButtons}>

        <div className={classes.btnbtn}>
          
            <div className={classes.flex}>
              {approveStatus === "Approved" ? <div className={classes.Approved}>Approved</div> : <div onClick={() => setShow('modal1')} className={classes.approve}>{isLoading ? <Loader type="TailSpin" color="white" width={20} height={18} /> : <div>Approve</div>}</div>}
            </div>

            {show === 'modal1' && <Modal modalCateg={'modal1'} setshow={setShow} NoButton={() => setShow('')} YesButton={() => { KycUpdated(FreelancerId, "Approved"); setShow('') }} />}
 
            <div className={classes.flex}>
              {approveStatus === "Rejected" ?
                <div className={classes.Rejected}> Rejected</div> :
                <div onClick={() => setShow('modal2')}
                  className={classes.reject}>{isLoading ? <Loader type="TailSpin" color="white" width={20} height={18} /> : <div>Reject</div>}
                </div>}

            </div>
            
        </div>

          {show === 'modal2' && <Modal modalCateg={'modal2'} setshow={setShow}
            value={reason} onChange={(e) => setReason(e.target.value)}
            CancelYes={() => { KycUpdated(FreelancerId, "Rejected"); setShow('') }} CancelNo={() => setShow('')} />}

        </div>

   </div>
    
  );

}

export default ViewKycSingleDetail;
