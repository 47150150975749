import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "../../Components/UIcomp/RightTable.module.css";

const FreelancerTransaction = () => {




    let url = window.location.href;
    let object = new URL(url);
    let path = object.pathname;
    let RequirementID = path.split("/").pop();



    return (
        <>

            {!FreelancerTransaction ? <div className={classes.blank_Notification_div}>
                You do not have any Transaction yet
            </div> :

                <div className={classes.mainContainer}>

                    <table className={classes.signup_details_table} id="table-to-xls">
                        <tr className={classes.table_row}>
                            <th className={classes.table_heading}>S.No.</th>
                            <th className={classes.table_heading}>Status</th>
                            <th className={classes.table_heading}>Client User Name</th>
                            <th className={classes.table_heading}>Freelancer User Name</th>
                            <th className={classes.table_heading}>View</th>
                        </tr>
                        {/* {FreelancerTransaction.map((item, index) => {
                            return (
                                <tr className={classes.table_row}>
                                    <td className={classes.table_td}>{index + 1}</td>
                                    <td className={classes.table_td}>{item.g_status}</td>
                                    <td className={classes.table_td}>{item.FreelancerUserName}</td>
                                    <td className={classes.table_td}>{item.ClientUserName}</td>
                                    <td className={classes.table_td}> <Link target={"blank"} to={"/ViewFreelancerTransaction/" + item.g_id}>View</Link></td>
                                </tr>
                            )
                        })} */}
                    </table>
                </div>
            }
        </>
    )
}
export default FreelancerTransaction;