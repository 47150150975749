import { useEffect, useState } from "react";
import classes from "../../Components/UIcomp/RightTable.module.css";
import { TiTick } from "react-icons/ti";

const TransactionInfo = () =>{
      const [trasactions, setTransactions] = useState([]);

      let userID = localStorage.getItem("userID");
      let url = window.location.href;
  let object = new URL(url);
  let path = object.pathname;
  let RequirementID = path.split("/").pop();
  

      useEffect(() => {
        trasactionDetails();
      }, []);
    
      const trasactionDetails = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
        );
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify(
          {
            // "ClientId": "0ee80cd7-a4fb-11ec-800c-02c01e8e4bda"
        
            "ClientId":RequirementID
          }
        );
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
    
        await fetch("https://api-preview.rozgaarindia.com/api/client/ClientTransaction", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "SUCCESS") {
              setTransactions(result.data)
            } else {
              console.log("fail")
            }
          })
          .catch((error) => console.log("error", error));
      };

      const dateAndTimeHandler = (signUpDate) => {
        var date = new Date(signUpDate);
        return date.toString().slice(3, 25);
      };
     
    return(
        <>

        {!trasactions   ?  <div className={classes.blank_Notification_div}>
            <div className={classes.blank_Notification_text}>
              You do not have any Transaction yet
            </div>
          </div>:
          <div className={classes.mainContainer}>
           <table className={classes.signup_details_table} id="table-to-xls">
              <tr className={classes.table_row}>
                 <th className={classes.table_heading}>S.No.</th>
                <th className={classes.table_heading}>PG Order ID</th>
                <th className={classes.table_heading}>Grand Total</th>
                <th className={classes.table_heading}>Payment Type</th>
                <th className={classes.table_heading}>Payment Status</th>
                <th className={classes.table_heading}>GST</th>
                <th className={classes.table_heading}>PG Email</th>
                <th className={classes.table_heading}>PG Contact</th>
                <th className={classes.table_heading}>Date</th>
                <th className={classes.table_heading}>Method/Network/Type</th>
               
              </tr>

              {trasactions.map((item, index) => {
                return (
                  <tr className={classes.table_row}>
                    <td  className={classes.table_td}>{index + 1}</td>
                    <td  className={classes.table_td}>{item.PGOrderId}</td>
                    <td  className={classes.table_td}>{item.GrandTotal}</td>
                    <td  className={classes.table_td}>{item.PaymentType}</td>
                    <td  className={classes.table_td}>{item.PaymentStatus}</td>
                    <td  className={classes.table_td}>{item.GST}</td>
                    <td  className={classes.table_td}>  {item.PGEmail} {item.PGEmail && <TiTick color="green" />}</td>
                    <td  className={classes.table_td}> {item.PGContact} {item.PGContact && <TiTick color="green" />}</td>
                    <td className={classes.table_td}>
                        {dateAndTimeHandler(item.UpdatedAt)}{" "}
                      </td>
                  <td  className={classes.table_td}>
                  {item.Response2===""?<></>:<>
                  {item?.Response2?.payload?.payment?.entity?.method==="upi"?
                  <>{item?.Response2?.payload?.payment?.entity?.method}/{item?.Response2?.payload?.payment?.entity?.vpa}</>
                    :<>{item?.Response2?.payload?.payment?.entity?.card?.network}&nbsp;{item?.Response2?.payload?.payment?.entity?.card?.type}&nbsp;{item?.Response2?.payload?.payment?.entity?.method}</> 
                }</>
                  }
                   </td>   
                  </tr>
                )
              })}

            </table>

            </div> 
        
        }

        </>

      )
}

export default TransactionInfo;