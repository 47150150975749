import React, { useEffect, useState,useRef } from 'react';
import classes from '../../Components/UIcomp/RightTable.module.css'
import { useHistory, Link } from "react-router-dom";
import HeaderForRightSec from '../../Components/UIcomp/HeadingForRightSec';
import { TiTick } from 'react-icons/ti';
import ReactExport from "react-data-export";
import TablesExcelDir from '../../Components/ExcelDir/TablesExcelDir';
import Loader from 'react-loader-spinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ClientSignUp = () => {

    let loggedIn = localStorage.getItem("UserLoggedIn");
    let history = useHistory();
    const tableRef = useRef(null);

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [countData, setCountData] = useState([])
    const [allData, setAllData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoader, setIsLoader] = useState(false);
    const[totalCount, setTotalCount] = useState("")
    

    const handleNextbtn = () => {
        setCurrentPage(currentPage + 1);
        setIsLoader("next")
      };
    
      const handlePrevbtn = () => {
        setCurrentPage(currentPage - 1);
        setIsLoader('prev');
      };

    useEffect(() => {
        var date = new Date();
date.setDate(date.getDate() - 7);

 let startDateformat = (date.getDate().toString.length < 2 ? "0" + date.getDate() : date.getDate())

const monthFormat = (date.getMonth()+1).length > 1 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)
var finalDate = date.getFullYear() + '-'+  monthFormat + '-' + startDateformat
setStartDate(finalDate)

var todayDate = new Date();
  todayDate.setDate( todayDate.getDate());

  let endDateformat = todayDate.getDate().toString.length < 2 ? "0" +todayDate.getDate() : todayDate.getDate()
  
  const todayFormat = (todayDate.getMonth()+1).length > 1 ? (todayDate.getMonth()+1) : "0" + (todayDate.getMonth()+1)
  var finalToday = todayDate.getFullYear() + '-'+  todayFormat + '-' + endDateformat
 setEndDate(finalToday)

 SignupDetails(finalDate, finalToday, currentPage)
 }, []);
    
    useEffect(() => {
        if (startDate && endDate && currentPage)
            {SignupDetails(startDate, endDate, currentPage)}
            SignupDetails()
    }, [currentPage])
    
    const SignupDetails = (end ,today, page ) => {
    //    setIsLoader(true)
       
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "StartDate":  end? end :"",
            "EndDate": today ?  today : "",
            "Page":(end&&page)?page:"",
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch("https://api-preview.rozgaarindia.com/api/rzadmin/GetSignupCount", requestOptions)
            .then(response => response.json())
            .then(result => {
         
                if (result.status_code === 200 && result.status === "SUCCESS"&&!end) {
                    setAllData(result.data)
                  
                }
                
                else if (result.status_code === 200 && result.status === "SUCCESS"&&end) {
                    setCountData(result.data)
                    setTotalCount(result.count)
                  
                }
                else if (result.status_code  === 200 && result.status === "SUCCESS" && result.data===[]  ){
                   setCountData("No data found !")
                  }
                else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
                    alert("Please select start date and end date")
                  }
                else {
                    alert(result.message)
                }

            })
            .catch(error => console.log('error', error))
            .finally(()=>{
               setIsLoader(false)
            });
    }

        const dateAndTimeHandler  =(signUpDate) =>{

            var date = new Date(signUpDate);
              return date.toString().slice(3,25)
        }

      return (
     <>
       
           <div className={classes.MainContainer}>
           
        
           <HeaderForRightSec headingText={"Client Sign Up"} />
       

            <div className={classes.date_container}>
                <div className={classes.date_field}><label>From</label><input type="date" className={classes.date_input} onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                <div className={classes.date_field}><label>To</label><input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
               <div className={classes.searchButton} onClick={() =>  {setCurrentPage(1);SignupDetails(startDate, endDate, currentPage)}}> Search  </div>
               <div className={classes.recordLength}> Data Count {totalCount} </div>
            </div>
            
            <TablesExcelDir tableData={allData} tableType={"Clientsignup"}  />
            <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ?
                    <>
                    
                        
                        <button onClick={handlePrevbtn}  className={classes.buttonone}>
                       { isLoader=="prev"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Prev</>}
                            </button> 
                    
                    </> 
                   : <span className={classes.buttononePre}>Prev</span>}

                     <span className={classes.CurrentPageText}>{currentPage}</span>
                    {(countData.length >= 20 )? 
                    <>
                  
                       
                        <button  onClick={handleNextbtn}  className={classes.buttonone}>
                        {isLoader=="next"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Next</>}
                        </button>
                    
                    </> : 
                    <span className={classes.buttononePre}>Next</span>}
                    
                </div>

            
            {countData.length > 0 ? <div className={classes.table_container}>
                <table className={classes.signup_details_table} id="table-to-xls">
                    <tr className={classes.table_row}>
                        <th className={classes.table_heading}>S.No</th>
                        <th className={classes.table_heading}>Country</th>
                        <th className={classes.table_heading}>Full Name</th>
                        <th className={classes.table_heading}>User Name</th>
                        <th className={classes.table_heading}>Email</th>
                        <th className={classes.table_heading}>Mobile</th>
                        <th className={classes.table_heading}>Date</th>
                    </tr>

                    <tbody>
                        {countData.map((item, index) => {
                            return (
                                <tr className={classes.table_row} >
                                    {<td className={classes.table_td}>{index + 1}</td> }
                                    <td className={classes.table_td}>{item.Country}</td>
                                    <td className={classes.table_td}>{item.FirstName} {item.LastName}</td>
                                    <td className={classes.table_td}><div className={classes.Tagsection}><Link to={"/UserDetailInfo/" + item.UserId}  className={classes.userName}>{item.UserName}</Link>
                                  {item.isOldUser==="True" && <div className={classes.Tag}>Old</div>}
                                  </div></td>
                                    <td className={classes.table_td}>{item.Email} {item.EmailVerified  && <TiTick color='green'/>}</td>
                                    <td className={classes.table_td}>{item.Mobile?<>{item.Mobile} {item.MobileVerified && <TiTick color='green' />}</>:"N/A"} </td>
                                    <td className={classes.table_td}>{dateAndTimeHandler(item.CreatedAt)} </td>
                                   
                                </tr>
                            )
                        }

                       )}

                   </tbody>

                </table>

            </div> : <div className={classes.NoDataFound}>Please select start date and end date</div>}
            
        </div>

 
        </>

    );
}


export default ClientSignUp;
