import { useEffect, useState } from "react";
import classes from "./SkillsInfo.module.css";

const SkillsInfo = () =>{

    const [suggestedSkills, setSuggestedSkills] = useState();
    
    useEffect(() => {
        databoardData();
      }, []);

      let url = window.location.href;
      let object = new URL(url);
      let path = object.pathname;
      let RequirementID = path.split("/").pop();
    

      
    const databoardData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      
     "UserId":RequirementID
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://api-preview.rozgaarindia.com/api/client/ClientDashboard", requestOptions)
      .then(response => response.json())
      .then((result) => {
        if (result.status === "SUCCESS") {
            setSuggestedSkills(result.data.SuggestedSkills) 
          } else {
            console.log("fail")
          }
        
    })
      .catch(error => console.log('error', error));
    }
    return(
            
        <div className={classes.mainContainer}>
      <div className={classes.tabContainer}>
                {suggestedSkills?suggestedSkills.map((item, i) => (
                    <div className={classes.SkillTab}>
                      {item}
                    </div>
                  )):<div>No skills found</div>}
              </div>
            </div>
         
        )
}

export default SkillsInfo;