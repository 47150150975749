import React, { useState,useEffect } from 'react';
import {Link, useLocation } from "react-router-dom";
import classes from "../Screens/Client/SignUp.module.css";

const SignUPToday = () => {

    let location = useLocation();
    let todayDate = location.state.todayDate;
   // console.log("todayDate :", todayDate);


    const [startDate, setStartDate] = useState(todayDate)
    const [endDate, setEndDate] = useState(todayDate)
    const [countData, setCountData] = useState([])

    useEffect(()=> {
        SignupTodayDetails();
  
      }, []);

    const SignupTodayDetails = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "StartDate": (startDate),
            "EndDate": (endDate)
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rzadmin/GetSignupCount", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    setCountData(result.data)
                }
                else if (result.status_code  === 200 && result.status === "SUCCESS" && result.data===[]  ){
                   setCountData("No data found !")
                  }
                else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
                    alert("Please select start date and end date")
                  }
                else {
                    alert(result.message)
                }

            })
            .catch(error => console.log('error', error));
    }

    
    return (
        <>
             <div className={classes.MainContainer}>
           <div>SignUp</div>
           
           
           <div className= {classes.dashBoard_heading}>SignUp Today</div>

            {countData.length > 0 ? <div className={classes.table_container}>
                <table className={classes.signup_details_table}>
                    <tr className={classes.table_row}>
                        <th className={classes.table_heading}>Id</th>
                        <th className={classes.table_heading}>First Name</th>
                        <th className={classes.table_heading}>Last Name</th>
                        <th className={classes.table_heading}>Country</th>
                        <th className={classes.table_heading}>Mobile</th>
                        <th className={classes.table_heading}>CreatedAt</th>
                        <th className={classes.table_heading}>Email</th>
                    </tr>

                    <tbody>
                        {countData.map((item, value) => {
                            return (
                                <tr className={classes.table_row} >
                                    <td className={classes.table_td}><Link to={{ pathname : "/SignUpDetail/"+ item.ID, state: {signUpData: item }}}  className={classes.userName}>{item.ID} </Link></td>
                                    <td className={classes.table_td}>{item.FirstName}</td>
                                    <td className={classes.table_td}>{item.LastName}</td>
                                    <td className={classes.table_td}>{item.Country}</td>
                                    <td className={classes.table_td}>{item.Mobile}</td>
                                    <td className={classes.table_td}>{item.CreatedAt.slice(0, 10).split("-").reverse().join("-")}</td>
                                    <td className={classes.table_td}>{item.Email}</td>
                                </tr>
                            )
                        }

                       )}

                   </tbody>

                </table>

            </div> : <div className={classes.NoDataFound}>No Record Found For Today</div>}
            
        </div>
       

        </> 
     

    )
}

export default SignUPToday;
