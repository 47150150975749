import React, { useEffect, useState,useRef } from 'react';
import classes from '../../Components/UIcomp/RightTable.module.css'
import { BsEye } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import TablesExcelDir from '../../Components/ExcelDir/TablesExcelDir';
import HeaderForRightSec from '../../Components/UIcomp/HeadingForRightSec';

const Articles = () => {

      let loggedIn = localStorage.getItem("UserLoggedIn");
      let history = useHistory();
      const tableRef = useRef(null);

  const [allArticles, setAllArticles] = useState([]);
  const [startDate , setStartDate] = useState('')
  const [endDate , setEndDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
    const [isLoader, setIsLoader] = useState(false);
    const [ count,setCount] = useState("");
    const [allData, setAllData] = useState([])

    const handleNextbtn = () => {
        setCurrentPage(currentPage + 1);
        setIsLoader("next")
      };
    
      const handlePrevbtn = () => {
        setCurrentPage(currentPage - 1);
        setIsLoader('prev');
      };
    useEffect(() => {
        var date = new Date();
date.setDate(date.getDate() - 7);

let startDateformat =(date.getDate().toString.length < 2 ? "0" + date.getDate() : date.getDate())
const monthFormat = (date.getMonth()+1).length > 1 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)
var finalDate = date.getFullYear() + '-'+  monthFormat + '-' + startDateformat
setStartDate(finalDate)

var todayDate = new Date();
  todayDate.setDate(todayDate.getDate())

  let endDateformat = todayDate.getDate().toString.length < 2 ? "0" +todayDate.getDate() : todayDate.getDate()
  const todaymonthFormat = (todayDate.getMonth()+1).length > 1 ? (todayDate.getMonth()+1) : "0" + (todayDate.getMonth()+1)
  var finalToday = todayDate.getFullYear() + '-'+  todaymonthFormat + '-' + endDateformat
 setEndDate(finalToday)
 getAllArticles(finalDate ,finalToday, currentPage)
    }, []);
    
    useEffect(() => {
        if (startDate && endDate && currentPage)
       { getAllArticles (startDate, endDate, currentPage) }
        getAllArticles()
    }, [currentPage])

  const getAllArticles =(end ,today,page ) => {

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    
   
    var raw = JSON.stringify({
      "Startdate":  end? end : (startDate),
      "Enddate": today ?  today : (endDate),
      "Page":(end&&page)?page:"",
  });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow", 
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/AllArticle",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS"&&!end) {
          setAllData(result.data)
      }
        else if(result.status_code === 200 && result.status === "SUCCESS"&&end){
          setAllArticles(result.data)
          setCount(result.count)

        }else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
         // alert("Please select start date and end date")
        }
        

      })
      .catch(error => console.log('error', error))
      .finally(()=>{
         setIsLoader(false)
      });
}
  console.log(startDate , endDate , "start and end date");

  const deleteArticleApi = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ArticleId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/rozgaarapi/UpdateDeleterzArticle",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const approveAlert = (id) => {
    let answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      deleteArticleApi(id);
      alert("Deleted successfully");
    }
  };
 console.log(startDate , endDate);

 
  return (
    <>
   
     <div className={classes.MainContainer}>
     
     <HeaderForRightSec headingText={"Article"} />
       

     <div className={classes.date_container}>
                <div className={classes.date_field}><label>From</label><input type="date" className={classes.date_input} onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                <div className={classes.date_field}><label>To</label><input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
               <div className={classes.searchButton} onClick={()=> {setCurrentPage(1);getAllArticles(startDate, endDate, currentPage)}}> Search  </div>
               
               <Link to={"/createArticle"} className={classes.searchButton}>
            Article
          </Link>
          <div className={classes.recordLength}> Data Count {count} </div>
            </div>

            <TablesExcelDir tableData={allData} tableType={"Article"} />

            <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ?
                    <>
                    
                        
                        <button onClick={handlePrevbtn} className={classes.buttonone}>
                       { isLoader=="prev"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Prev</>}
                            </button> 
                    
                    </> 
                   : <span className={classes.buttononePre}>Prev</span>}

                     <span className={classes.CurrentPageText}>{currentPage}</span>
                    {(allArticles.length >= 20 )? 
                    <>
                  
                       
                        <button  onClick={handleNextbtn} className={classes.buttonone}>
                        {isLoader=="next"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Next</>}
                        </button>
                    
                    </> : 
                    <span className={classes.buttononePre}>Next</span>}
                    
                </div>
      
          
          {allArticles.length > 0 ? <div className={classes.table_container}>
                <table className={classes.signup_details_table} id="table-to-xls">
            <tr className={classes.table_row}>
           <th className={classes.table_heading}>Sr No.</th>
           <th className={classes.table_heading}>Article Id</th>
           <th className={classes.table_heading}>Title</th>
           <th className={classes.table_heading}>Short Description</th>
           <th className={classes.table_heading}>View</th>
           <th className={classes.table_heading}>Edit</th>
           <th className={classes.table_heading}>Delete</th>
          </tr>

         <tbody>
           {allArticles.map((item, index) => {
             return (
                <tr className={classes.table_row}>
                 <td className={classes.table_td}>{index + 1}</td>
                 <td className={classes.table_td}>{item.ArticleId}</td>
                 <td className={classes.table_td}>{item.Title}</td>
                 <td className={classes.table_td}>
                   {" "}
                   {item.ShortDescription}{" "}
                 </td>
                 <td className={classes.table_td}>
                   <Link
                     to={"/ViewArticle/" + item.ArticleId}
                     className={classes.articleLink}
                   >
                     <BsEye />
                   </Link>{" "}
                 </td>
                 <td className={classes.table_td}>
                   <Link
                     to={"/EditArticle/" + item.ArticleId}
                     className={classes.articleLink}
                   >
                     <FiEdit />
                   </Link>
                 </td>
                 <td
                   className={classes.table_td}
                   onClick={() => approveAlert(item.ArticleId)}
                 >
                   <MdDeleteForever />
                 </td>

                </tr>

)
}

)}

</tbody>

</table>

</div> : <div className={classes.NoDataFound}>Please select start date and end date</div>}

</div>

</> 


);
}
export default Articles;
