import React, { useState,useEffect,useRef } from 'react';
import classes from '../../Components/UIcomp/RightTable.module.css'
import { Link, useHistory } from 'react-router-dom';
import HeaderForRightSec from '../../Components/UIcomp/HeadingForRightSec';
import ReactExport from "react-data-export";
import TablesExcelDir from '../../Components/ExcelDir/TablesExcelDir';
import Loader from 'react-loader-spinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const PostARequirement = () => {
  let loggedIn = localStorage.getItem("UserLoggedIn");
  

  let history = useHistory();
  const tableRef = useRef(null);


  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [approve, setApprove] = useState("");
  const [reject, setReject] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectType, setSelectType] = useState("");
  const[selectWork,setSelectWork] = useState("");
  const[filterData,setFilterData] = useState([]);
  const [ count,setCount] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [allData, setAllData] = useState([])

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
    setIsLoader("next")
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
    setIsLoader('prev');
  };

  useEffect(() => {
    var date = new Date();
date.setDate(date.getDate() - 7);

let startDateformat =(date.getDate().toString.length < 2 ? "0" + date.getDate() : date.getDate())

const monthFormat = (date.getMonth()+1).length > 1 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)
var finalDate = date.getFullYear() + '-'+  monthFormat + '-' + startDateformat
setStartDate(finalDate)

var todayDate = new Date();
todayDate.setDate(todayDate.getDate())

let endDateformat = todayDate.getDate().toString.length < 2 ? "0" +todayDate.getDate() : todayDate.getDate()

const todaymonthFormat = (todayDate.getMonth()+1).length > 1 ? (todayDate.getMonth()+1) : "0" + (todayDate.getMonth()+1)
var finalToday = todayDate.getFullYear() + '-'+  todaymonthFormat + '-' + endDateformat
setEndDate(finalToday)
postAReqSearch(finalDate ,finalToday,currentPage)
}, []);

useEffect(() => {
  if (startDate && endDate && currentPage)
  {postAReqSearch (startDate, endDate, currentPage)}
  postAReqSearch()
}, [currentPage])
  
  const postAReqSearch = (end, today,page) => {
    // setIsLoader(true)

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
     myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "StartDate":  end? end : (startDate),
      "EndDate": today ?  today : (endDate),
      "Page":(end&&page)?page:"",
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api-preview.rozgaarindia.com/api/rzadmin/RequirementList", requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status_code === 200 && result.status === "SUCCESS"&&!end) {
          setAllData(result.data)
      }
        else if (result.status_code === 200 && result.status === "SUCCESS"&&end) {
          setCount(result.dataCount)
          setData(result.data)
      }
      else if (result.status_code  === 200 && result.status === "SUCCESS" && result.data===[]  ){
         setData("No data found !")
        }
      else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
      //   alert("Please select start date and end date")
        }
      else {
          alert(result.message)
      }

  })
  .catch(error => console.log('error', error))
            .finally(()=>{
               setIsLoader(false)
            });
    }
  
 const UserStatusApi = (RequirementID, status) => {

     if (status === "Approved") setApprove(approve, RequirementID);
    else if (status === "Disapproved") setReject(reject, RequirementID);

     var myHeaders = new Headers();
     myHeaders.append(
     "Authorization",
     "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

     var raw = JSON.stringify({
      "RequirementID": RequirementID,
      "Status": status,
      
    });

     var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
     };

     fetch(
      "https://api-preview.rozgaarindia.com/api/rzadmin/PostAReqAdminStatus",
       requestOptions
     )
       .then((response) => response.json())
       .then((result) =>{

        if(result.status_code === 200){
          if(status === "Approved"){
            alert("Approved successfully")

        }else if(status === "Disapproved"){

           alert("Rejected successfully")
         }
       }
     })
      
       .catch((error) => console.log("error", error));
   };  

 
   const dateAndTimeHandler  =(postAReqDate) =>{

    var date = new Date(postAReqDate);
      return date.toString().slice(3,25)
}


   useEffect (() => {
     setFilterData(data);
   }, [data]);

   const setType = (e) => {
     setSelectType (e.target.value);

     let filteredData = JSON.parse(JSON.stringify(data));

     if (e.target.value !== "") {
       filteredData= filteredData.filter(row => row.Status === e.target.value);
     }
     setFilterData(filteredData);
    }
     
    const setWork = (e) => {
      setSelectWork (e.target.value);
 
      let workedData = JSON.parse(JSON.stringify(data));
 
   if (e.target.value !== "" && e.target.value=='IsHybrid') {
        workedData=workedData.filter(row =>row.IsHybrid=='1');
      } else if (e.target.value !== "" && e.target.value=='IsRemote') {
        workedData=workedData.filter(row =>row.IsRemote=='1');
      } else if (e.target.value !== "" && e.target.value=='IsOnsite') {
        workedData=workedData.filter(row =>row.IsOnsite=='1');
     
    }  else {}
      setFilterData(workedData);
     } 

  return (

      <>
 
        <div className={classes.MainContainer}>
           
        
           <HeaderForRightSec headingText={"Post A Requirment"} />
       

            <div className={classes.date_container}>
                <div className={classes.date_field}><label>From</label><input type="date" className={classes.date_input} onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                <div className={classes.date_field}><label>To</label><input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
               <div className={classes.searchButton} onClick={()=> {setCurrentPage(1); postAReqSearch(startDate, endDate, currentPage)}}> Search  </div>
               <div className={classes.recordLength}> Data Count {count}</div>
               {/*  <button className={classes.search_button} onClick={SignupDetails}>Search</button> */}
            </div>

          <div className={classes.choose}> 
            <div className={classes.choosetype}>
          <label className={classes.label} >Choose Type : </label>
          <select name="type" id="type" onChange={setType} value={selectType} className = {classes.typeDropdown}>

          <option value="">Select</option>
            <option value="Approved">Approved</option>
            <option value="Submitted">Submitted</option>
            <option value="Draft">Draft</option>
          </select>
        </div>
         
       <div className={classes.choosetype}>
          <label className={classes.label} >Choose Work : </label>
          <select name="type" id="type" onChange={setWork} value={selectWork} className = {classes.typeDropdown}>

          <option value="">Select</option>
            <option value="IsHybrid">Hybrid</option>
            <option value="IsRemote">Remote</option>
            <option value="IsOnsite">Onsite</option>
          </select>
  </div> 
  </div>
            <TablesExcelDir tableData={allData} tableType={"Post A  Requirement"} />
            
            <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ?
                    <>
                    
                        
                        <button  onClick={handlePrevbtn} className={classes.buttonone}>
                       { isLoader =="prev"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Prev</>}
                            </button> 
                    
                    </> 
                   : <span className={classes.buttononePre}>Prev</span>}

                     <span className={classes.CurrentPageText}>{currentPage}</span>
                    {(data.length >= 20 )? 
                    <>
                  
                       
                        <button onClick={handleNextbtn} className={classes.buttonone}>
                        {isLoader=="next"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Next</>}
                        </button>
                    
                    </> : 
                    <span className={classes.buttononePre}>Next</span>}
                    
                </div>

          
           {/* <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                    {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                    {data.length >=20 ? <span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span> : <span className={classes.CurrentPageText}>{currentPage}</span>}
                    {data.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
  </div> */}

            {filterData.length > 0 ? <div className={classes.table_container}>
                <table className={classes.signup_details_table} id="table-to-xls">
                   <tr className={classes.table_row}>

                   <th className={classes.table_heading}>S.No</th>
                   <th className={classes.table_heading}>Country</th>
                    <th className={classes.table_heading}> Req Id</th>
                    <th className={classes.table_heading}>Status</th>
                    <th className={classes.table_heading}>Full Name</th>
                    <th className={classes.table_heading}>User Name</th>
                    <th className={classes.table_heading}>Req Type</th>
                   <th className={classes.table_heading}>Working Policy</th>
                   <th className={classes.table_heading}>Add Ons</th>
                   <th className={classes.table_heading}>Freelancers Count</th>
                   <th className={classes.table_heading}>Date</th>
                 </tr>

                    <tbody>
                      
                  {filterData.map((item, index) => {

                    const workingPolicy = () => {
                      let arr = [];
                      if (item.IsRemote === "1") {
                        arr.push("Remote")
                      }
                      if (item.IsHybrid === "1") {
                        arr.push("Hybrid")
                      }
                      if (item.IsOnsite === "1") {
                        arr.push("Onsite")
                      }
                      return arr.join(",")
                    }

                  
                    return (
                      <tr className={classes.table_row} >
                          {<td className={classes.table_td}>{index + 1}</td> }
                          <td className={classes.table_td}>{item.Country?item.Country:"N/A"}</td>
                        <td className={classes.table_td}> {item.RequirementID.slice(0,5)}</td> 
                        <td className={classes.table_td}>{item.Status}</td> 
                        <td className={classes.table_td}>{item.FirstName + " " + item.LastName}<div className={classes.Tagsection}>{item.PaymentStatus==="Success"?(<div className={classes.Tag}>Paid</div>):(" ")}</div></td>
                        <td className={classes.table_td}><div className={classes.Tagsection}><Link to={"/UserDetailInfo/" + item.UserId}  className={classes.userName}>{item.UserName}</Link>
                                  {item.isOldUser==="True" && <div className={classes.Tag}>Old</div>}
                                  </div></td>
                        <td className={classes.table_td}>{item.RequirementType?<>{item.RequirementType} </>:"N/A"} </td>
                        <td className={classes.table_td}>{workingPolicy()}</td>
                       
                        <td className={classes.table_td}>{item.PaymentStatus === "Success" ? (item.AddonName) :"N/A"}</td> 
                        <td className={classes.table_td}>{item.Applied ? item.Applied:"N/A"}</td> 
                        <td className={classes.table_td}>{dateAndTimeHandler(item.UpdatedDate)} </td>
                      </tr>
                    )
                  }
                  )}
                </tbody>

              </table>

            </div> : <div className={classes.NoDataFound}>Please select start date and end date</div>}
            
        </div>

    </>

  )

}

export default PostARequirement;
