import React, { useEffect, useState,useRef } from 'react';
import classes from '../../Components/UIcomp/RightTable.module.css'
import { useHistory, Link } from "react-router-dom";
import HeaderForRightSec from '../../Components/UIcomp/HeadingForRightSec';
import ReactExport from "react-data-export";
import TablesExcelDir from '../../Components/ExcelDir/TablesExcelDir';
import Loader from 'react-loader-spinner';
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const FrequentlyAskedQuestion = () => {

    let loggedIn = localStorage.getItem("UserLoggedIn");
    let history = useHistory();
    const tableRef = useRef(null);

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [countData, setCountData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const[isLoader,setIsLoader] = useState(false);
    const[totalCount, setTotalCount] = useState("")
    const [allData, setAllData] = useState([])


    const handleNextbtn = () => {
        setCurrentPage(currentPage + 1);
        setIsLoader("next")
      };
    
      const handlePrevbtn = () => {
        setCurrentPage(currentPage - 1);
        setIsLoader('prev');
      };

    useEffect(() => {
        var date = new Date();
date.setDate(date.getDate() - 7);

let startDateformat =(date.getDate().toString.length < 2 ? "0" + date.getDate() : date.getDate())


const monthFormat = (date.getMonth()+1).length > 1 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)
var finalDate = date.getFullYear() + '-'+  monthFormat  + '-' + startDateformat
setStartDate(finalDate)

var todayDate = new Date();
  todayDate.setDate(todayDate.getDate())

  let endDateformat = todayDate.getDate().toString.length < 2 ? "0" +todayDate.getDate() : todayDate.getDate()
const todaymonthFormat = (todayDate.getMonth()+1).length > 1 ? (todayDate.getMonth()+1) : "0" + (todayDate.getMonth()+1)
  var finalToday = todayDate.getFullYear() + '-'+  todaymonthFormat + '-' + endDateformat
 setEndDate(finalToday)
 FrequentlyAskedDetails(finalDate ,finalToday,currentPage);
    }, []);
    
    useEffect(() => {
        if (startDate && endDate && currentPage)
       { FrequentlyAskedDetails(startDate, endDate, currentPage)}
       FrequentlyAskedDetails()
    }, [currentPage])

    
    const FrequentlyAskedDetails = (end ,today, page ) => {
       // setIsLoader(true)

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "StartDate":  end? end : (startDate),
            "EndDate": today ?  today : (endDate),
            "Page":(end&&page)?page:"",
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rozgaarapi/GetDatedFaq", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status === "SUCCESS"&&!end) {
                    setAllData(result.data)
                }
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    setCountData(result.data)
                    setTotalCount(result.count)
                }
                else if (result.status_code  === 200 && result.status === "SUCCESS" && result.data===[]  ){
                   setCountData("No data found !")
                  }
                else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
                    alert("Please select start date and end date")
                  }
                else {
                    alert(result.message)
                }

            })
            .catch(error => console.log('error', error))
            .finally(()=>{
               setIsLoader("")
            });
    }

    const dateAndTimeHandler  =(signUpDate) =>{

        var date = new Date(signUpDate);
          return date.toString().slice(3,25)
    }
    
    const deleteApi = (FAQID) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
            "FAQID": FAQID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rozgaarapi/DeleteFaq", requestOptions)
            .then(response => response.json())
            .then(result => {
                FrequentlyAskedQuestions()
            })
            .catch(error => console.log('error', error));
    };

    const FrequentlyAskedQuestions = () => {
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "StartDate": startDate,
            "EndDate": endDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rozgaarapi/GetDatedFaq", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    setCountData(result.data)
                    
                }
                 if (result.status_code  === 200 && result.status === "SUCCESS" && result.data===[]  ){
                   setCountData("No data found !")
                  }
                else if (result.status_code  >= 300 && result.status !== "SUCCESS"  ){
                    alert("Please select start date and end date")
                  }
                else {
                    alert(result.message)
                }
            })
            .catch(error => console.log('error', error));
    }


  

    return (

        <>
       
        <div className={classes.MainContainer}>
           
        
           <HeaderForRightSec headingText={"Frequently Asked Question"} />
       

            <div className={classes.date_container}>
                <div className={classes.date_field}><label>From</label><input type="date" className={classes.date_input} onChange={(e) => setStartDate(e.target.value)} value={startDate} ></input></div>
                <div className={classes.date_field}><label>To</label><input type="date" className={classes.date_input} onChange={(e) => setEndDate(e.target.value)} value={endDate}></input></div>
               <div className={classes.searchButton} onClick={()=> {setCurrentPage(1);FrequentlyAskedDetails(startDate, endDate, currentPage)}}> Search  </div>

               <Link to="/FaqCreate">
                   
                      <div className={classes.searchButton} > Add </div>
                    </Link>
                <div className={classes.recordLength}> Data Count {totalCount} </div>

            </div>
           {/*  <ExcelFile element={<button>Download Data</button>}>
 <ExcelSheet data={countData} name="Leaves">
                    <ExcelColumn label="Country" value="Country"/>
                    <ExcelColumn label="Full Name" value="FirstName"/>
                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                </ExcelSheet>
                </ExcelFile> 
 */}
                
{/* <Button onClick={onDownload}> Export excelkk </Button> */}
<TablesExcelDir tableData={allData} tableType={"FrequentlyAskedQuestion "} />

<div className={classes.PaginationContainer}>
                    {currentPage !== 1 ?
                    <>
                    
                        
                        <button onClick={handlePrevbtn} className={classes.buttonone}>
                       { isLoader=="prev"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Prev</>}
                            </button> 
                    
                    </> 
                   : <span className={classes.buttononePre}>Prev</span>}

                     <span className={classes.CurrentPageText}>{currentPage}</span>
                    {(countData.length >= 20 )? 
                    <>
                  
                       
                        <button  onClick={handleNextbtn} className={classes.buttonone}>
                        {isLoader=="next"?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Next</>}
                        </button>
                    
                    </> : 
                    <span className={classes.buttononePre}>Next</span>}
                    
                </div>

 {/* <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                    {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                    {countData.length >=20 ? <span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span> : <span className={classes.CurrentPageText}>{currentPage}</span>}
                    {countData.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
</div> */}
                
            {countData.length > 0 ? <div className={classes.table_container}>
                <table className={classes.signup_details_table} id="table-to-xls">
                    <tr className={classes.table_row}>

                         <th className={classes.table_heading}>S.No</th>
                        <th className={classes.table_heading}>FAQ ID</th>
                        <th className={classes.table_heading}>Question</th>
                        <th className={classes.table_heading} >Answer</th>
                        <th className={classes.table_heading}>Date</th>
                        <th className={classes.table_heading}>Update</th>
                          <th className={classes.table_heading}>Delete</th>
                    </tr>

                    <tbody>
                        {countData.map((item, index) => {
                            return (
                                <tr className={classes.table_row} >
                                    {<td className={classes.table_td}>{index + 1}</td> }
                                    <td className={classes.table_td}>{item.FAQID}</td>
                                    <td className={classes.table_td} dangerouslySetInnerHTML={{ __html: item.Question }}/>
                                    <td className={classes.table_td}>{item.Answer.replace(/<[^>]+>/g,' ').replace(/(\r\n|\n|\r)/gm, " ").replace(/&nbsp; /g,' ')}</td>  
                                    <td className={classes.table_td}>{dateAndTimeHandler(item.UpdatedAt)} </td>
                                    <td className={classes.table_td}><Link to={"/UpdateFaq/" + item.FAQID}> <FiEdit/> </Link></td>
                                    <td className={classes.table_td}><buttton onClick={() =>deleteApi(item.FAQID)}><MdDeleteForever/></buttton></td>
                                </tr>
                            )
                        }

                       )}

                   </tbody>

                </table>

            </div> : <div className={classes.NoDataFound}>Please select start date and end date</div>}
            
        </div>

    </> 
     

    );
}

export default FrequentlyAskedQuestion;
