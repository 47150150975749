import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import RIAdminInput from "../../Components/RIAdminInput";
import classes from "./LandingPage.module.css";

const LandingPageView = () => {
  const [id, setId] = useState();
  const [keyIndex, setKeyIndex] = useState();
  const [landingPageID, setLandingPageID] = useState();
  const [heading1, setHeading1] = useState();
  const [mainDescription, setMainDescription] = useState();
  const [heading1Url, setHeading1Url] = useState();
  const [heading2, setHeading2] = useState();
  const [description2, setDescription2] = useState();
  const [heading2Url, setHeading2Url] = useState();
  const [heading3, setHeading3] = useState();
  const [description3, setDescription3] = useState();
  const [heading3Url, setHeading3Url] = useState();
  const [heading4, setHeading4] = useState();
  const [description4, setDescription4] = useState();
  const [heading4Url, setHeading4Url] = useState();
  const [callForAction, setCallForAction] = useState();
  const [hubName, setHubName] = useState();
  const [ordinalPosition, setOrdinalPosition] = useState();
  let history = useHistory();
  //   const [bannerImage, setBannerImage] = useState();
  //   const [createdDate, setCreatedDate] = useState();
  //   const [UpdatedDate, setUpdatedDate] = useState();

  const { pageId } = useParams();

  let url = window.location.href;
  let object = new URL(url);
  let path = object.pathname;
  let getPaperID = path.split("/").pop();

  useEffect(() => {
    LandingView();
  }, []);

  console.log(pageId);

  const LandingView = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      LandingPageID: pageId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "http://api-preview.rozgaarindia.com/api/rzadmin/ViewLandingPageJson",
      requestOptions
    )
      .then((response) => response.json())

      .then((result) => {
        setHeading1(result.LandingPageID.Heading1);
        setHeading1Url(result.LandingPageID.Heading1Url);
        setHeading2(result.LandingPageID.Heading2);
        setHeading2Url(result.LandingPageID.Heading2Url);
        setDescription3(result.LandingPageID.Description3);
        setDescription2(result.LandingPageID.Description2);
        setHeading3(result.LandingPageID.Heading3);
        setHeading3Url(result.LandingPageID.Heading3Url);
        setHeading4(result.LandingPageID.Heading4);
        setDescription4(result.LandingPageID.Description4);
        setHeading4Url(result.LandingPageID.Heading4Url);
        setCallForAction(result.LandingPageID.CallForAction);
        setHubName(result.LandingPageID.HubName);
        setOrdinalPosition(result.LandingPageID.OrdinalPosition);
        setMainDescription(result.LandingPageID.MainDescription);
      })
      .catch((error) => console.log("error", error));
  };
  console.log(id);
 

  const LandingAPI = () => {
    var myHeaders = new Headers();
   
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    var formdata = new FormData();
    formdata.append("LandingPageId", pageId);
    formdata.append("Heading1", heading1);
    formdata.append("MainDescription", mainDescription);
    formdata.append("Heading1Url", heading1Url);
    formdata.append("Heading2", heading2);
    formdata.append("Description2", description2);
    formdata.append("Heading2Url", heading2Url);
    formdata.append("Heading3", heading3);
    formdata.append("Description4", description4);
    formdata.append("Description3", description3);
    formdata.append("Heading4Url", heading4Url);
    formdata.append("Heading3Url", heading3Url);
    formdata.append("CallForAction", callForAction);
    formdata.append("HubName", hubName);
    formdata.append("Heading4", heading4);
    formdata.append("OrdinalPosition", ordinalPosition);
    

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "http://api-preview.rozgaarindia.com/api/rzadmin/UpdateLandingPageJson",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        history.push("/LandingPages");
      })
      .catch((error) => console.log("error", error));
  };

  return (
    
    <div className={classes.LandingPage_MainContainer}>
   
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Heading1"}
        onChange={(e) => setHeading1(e.target.value)}
        value={heading1}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Main Description"}
        onChange={(e) => setMainDescription(e.target.value)}
        value={mainDescription}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Heading 1Url"}
        onChange={(e) => setHeading1Url(e.target.value)}
        value={heading1Url}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Heading2"}
        onChange={(e) => setHeading2(e.target.value)}
        value={heading2}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Description2"}
        onChange={(e) => setDescription2(e.target.value)}
        value={description2}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Heading2 Url"}
        onChange={(e) => setHeading2Url(e.target.value)}
        value={heading2Url}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Heading3"}
        onChange={(e) => setHeading3(e.target.value)}
        value={heading3}
      />

      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Description3"}
        onChange={(e) => setDescription3(e.target.value)}
        value={description3}
      />

      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Heading3 Url"}
        onChange={(e) => setHeading3Url(e.target.value)}
        value={heading3Url}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Heading4"}
        onChange={(e) => setHeading4(e.target.value)}
        value={heading4}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Description4"}
        onChange={(e) => setDescription4(e.target.value)}
        value={description4}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Heading4 Url"}
        onChange={(e) => setHeading4Url(e.target.value)}
        value={heading4Url}
      />

      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"CallFor Action"}
        onChange={(e) => setCallForAction(e.target.value)}
        value={callForAction}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Hub Name"}
        onChange={(e) => setHubName(e.target.value)}
        value={hubName}
      />
      <RIAdminInput
        input={"SingleLineInput"}
        type={"text"}
        label={"Ordinal Position"}
        onChange={(e) => setOrdinalPosition(e.target.value)}
        value={ordinalPosition}
      />
      <div className={classes.btnDiv}>
        <button className={classes.button} onClick={LandingAPI}>
          Update
        </button>
      </div>
    </div>
  );
};

export default LandingPageView;
