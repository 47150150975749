import React, { useState } from 'react';
import RiHeadingText from '../../Components/RiHeadingText';
import classes from "./TrustedBy.module.css";
import { useHistory } from "react-router-dom";
import bredcrumb from "../../Components/BreadCrumb/BreadCrumb.module.css";

const TrustedBy = () => {

    let loggedIn = localStorage.getItem("UserLoggedIn");
    // console.log("loggedIn:", loggedIn);
     let history = useHistory();

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    // const [countData, setCountData] = useState([])

  {/*  const pageInfo = {
        "currentPageName": "TrustedBy"
      } */}
    
    return (
        <>
       
            <div className={classes.MainContainer}>
                <ul className={bredcrumb.breadcrumb}>
                   <li className={bredcrumb.breadcrumb_li}>
                    <a href="/DashBoard" className={bredcrumb.breadcrumb_li_a}>DashBoard</a>
                  </li>
                  <li className={bredcrumb.breadcrumb_li}>TrustedBy</li>

                </ul> 
         {/*<BreadCrumb pageInfo={pageInfo}/>


    <RiHeadingText title={'TrustedBy'} /> */}

    <div className= {classes.trustedBy_heading}>TrustedBy</div>
    
            <div className={classes.date_container}>
                <div className={classes.date_field}>From<input type="date" className={classes.date_input} value={startDate} ></input></div>
                <div className={classes.date_field}>To<input type="date" className={classes.date_input} value={endDate}></input></div>
                <button className={classes.search_button} >Search</button>
            </div>

            <div className={classes.table_container}>
                <table className={classes.signup_details_table}>
                    <tr className={classes.table_row}>

                        <th className={classes.table_heading}>Id</th>
                        <th className={classes.table_heading}>Client Image</th>
                        <th className={classes.table_heading}>Mobile Image</th>
                        <th className={classes.table_heading}>Created At </th>
                        <th className={classes.table_heading}>Updated At</th>
                        <th className={classes.table_heading}>Logo Position</th>

                    </tr>

                    {/* <tbody>
                        {countData.map((item, value) => {
                            return (
                                <tr className={classes.table_row} >
                                   
                                    <td className={classes.table_td}>{item.ID}</td>
                                    <td className={classes.table_td}>{item.}</td>
                                    <td className={classes.table_td}>{item.}</td>
                                    <td className={classes.table_td}>{item.}</td>
                                    <td className={classes.table_td}>{item.}</td>
                                    <td className={classes.table_td}>{item.}</td>
                                </tr>
                            )
                        }
                        )}
                    </tbody> */}

                </table>
            </div>

        </div>

        
    </>

    )

}

export default TrustedBy;
