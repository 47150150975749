import React, { useState,useRef, useLayoutEffect, useEffect } from "react";
import { FcCdLogo, FcSearch } from "react-icons/fc";
import classes from "../../Components/UIcomp/RightTable.module.css";
import HeaderForRightSec from "../../Components/UIcomp/HeadingForRightSec";
import { TiTick } from "react-icons/ti";
import ReactExport from "react-data-export";
import TablesExcelDir from "../../Components/ExcelDir/TablesExcelDir";
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const OldWildCard = () => {

  let loggedIn = localStorage.getItem("UserLoggedIn");
  let history = useHistory();
  const tableRef = useRef(null);

  const [allData, setAllData] = useState([]);
 const [countData, setCountData] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoader,setIsLoader] = useState('');
  const[totalCount, setTotalCount] = useState("");

/* --------------------buttons disabled-------------- */
  const [itemsPerPage] = useState(20);
 
  const pages = [];
  for (let i = 1; i <= Math.ceil(allData.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allData.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
    setIsLoader("next")
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
    setIsLoader('prev');
  };


 useEffect( () => {
  SearchDetail()
  SearchDetailAllData()
 },[currentPage])
 

  const SearchDetail = () => {
    // setIsLoader('next')

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "SerachTerm": searchInput,
         page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/SearchOldUser",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCountData(result.data);
          setTotalCount(result.count)
          
        }})
        .catch(error => console.log('error', error))
        .finally(()=>{
           setIsLoader('')
        });

  }
  const SearchDetailAllData = () => {


    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "SerachTerm": searchInput,
    
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/SearchOldUser",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setAllData(result.data);
          setTotalCount(result.count);
          
        }})
        .catch(error => console.log('error', error)) 
  }

  return (
    <>
      <div className={classes.MainContainer}>
        <HeaderForRightSec headingText={"Old  Wild Card"} />
       
          <div className={classes.searchBarContainer}>
            <input  type ="text" placeholder="Search.." name="search" className={classes.searchInput}
              onChange={(e) => setSearchInput(e.target.value)}value={searchInput} />
            <div className={classes.searchIcon} onClick={() => SearchDetail()||SearchDetailAllData()}><FcSearch size={20}/></div>
        <div className={classes.recordLength}>Data Count {totalCount}</div> 
          </div>

       

        <TablesExcelDir tableData={allData} tableType={"OldWildCard"} SearchDetailAllData={SearchDetailAllData} />
        <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ?
                    <>
                    
                        
                        <button 
                        // onClick={() => { setCurrentPage(currentPage - 1) }} 
                        onClick={handlePrevbtn}
                        disabled={currentPage == pages[0] ? true:false}
                        className={classes.buttonone}>
                       { isLoader === 'prev'?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Prev</>}
                            </button> 
                    
                    </> 
                   : <span className={classes.buttononePre}>Prev</span>}

                     <span className={classes.CurrentPageText}>{currentPage}</span>
                    {(countData.length >= 20 )? 
                    <>
                  
                       
                        <button 
                        // onClick={() => { setCurrentPage(currentPage + 1) }} 
                        onClick={handleNextbtn}                                                             
                        
                        className={currentPage == pages[pages.length-1]? classes.buttononePre:classes.buttonone}
                        disabled={currentPage == pages[pages.length-1]? true:false}
                        >
                          
                        {isLoader === 'next'?
                        <Loader type="TailSpin" color="white" width={20} height={20} className={classes.loaderBtn}/>:  <>Next</>}
                        </button>
                    
                    </> : 
                    <span className={classes.buttononePre}>Next</span>}
                    
                </div>

          
          
            
      {/*   <div className={classes.PaginationContainer}>
                    {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                    {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                    {countData.length >=20 ? <span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span> : <span className={classes.CurrentPageText}>{currentPage}</span>}
                    {countData.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
  </div> */}

        <div className={classes.tableBox}>
        {currentItems.length > 0 ? (
          <div className={classes.table_container}>
            <table className={classes.signup_details_table} id="table-to-xls">
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>S.No</th>
                <th className={classes.table_heading}>User Name</th>
                <th className={classes.table_heading}>Email</th>
                <th className={classes.table_heading}>Mobile</th>
               <th className={classes.table_heading}>GSkill</th>
                <th className={classes.table_heading}>Admin Status</th>
                <th className={classes.table_heading}>Status</th>
                <th className={classes.table_heading}>Package Type</th>
                <th className={classes.table_heading}>Category Name</th>
                <th className={classes.table_heading}>Seller Email</th>
                <th className={classes.table_heading}>Date</th>
                
              </tr>

              <tbody>
                {currentItems.map((item, index) => {
                  return ( <tr className={classes.table_row}>
                      {<td className={classes.table_td}>{index + 1}</td>}
                      <td className={classes.table_td}> {item.username}</td>
                     
                      <td className={classes.table_td}>
                        {item.email} {item.email_status && <TiTick color="green" />}
                      </td>
                      <td className={classes.table_td}>
                        {item.mobile} {item.mobile_status && <TiTick color="green" />}{" "}
                      </td>
                      <td className={classes.table_td}> {item.gskill}</td>
                      <td className={classes.table_td}> {item.admin_status}</td>
                      <td className={classes.table_td}> {item.status}</td>
                      <td className={classes.table_td}> {item.package_type}</td>
                      <td className={classes.table_td}> {item.cat_name}</td>
                      <td className={classes.table_td}> {item.seller_email}</td>
                      <td className={classes.table_td}> {item.created_at}</td>
                      
                    </tr>
                    
                   
                  ) 
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={classes.NoDataFound}>
          
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default OldWildCard;
