import React, { useState, useEffect } from 'react';
import RiHeadingText from '../../Components/RiHeadingText';
import classes from './Notifications.module.css'
import { Link, useHistory } from "react-router-dom";
import bredcrumb from "../../Components/BreadCrumb/BreadCrumb.module.css";


const Notifications = () => {

    let loggedIn = localStorage.getItem("UserLoggedIn");
 
    let history = useHistory();

    const [data, setData] = useState([])

    useEffect(() => {
        GetNotification();
    }, [])

    const GetNotification = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "UserId": "useridhdth1"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("http://api-preview.rozgaarindia.com/api/rzadmin/GetNotification", requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.dataread)
            })
            .catch(error => console.log('error', error));
    }

        
    const UpdateNotifications = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "UserId": "useridhdth1"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-preview.rozgaarindia.com/api/rzadmin/UpdateNotification", requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }



    return (
        <>
        
        <div className={classes.MainContainer}>
            <ul className={bredcrumb.breadcrumb}>
              <li className={bredcrumb.breadcrumb_li}>
                <a href="/" className={bredcrumb.breadcrumb_li_a}>DashBoard</a>
             </li>
             <li className={bredcrumb.breadcrumb_li}>Notification</li>

           </ul> 

            {/* <BreadCrumb pageInfo={pageInfo}/>
                <RiHeadingText title={'Notifications'} /> */}

            <div className={classes.table_container}>
                 <button onClick={UpdateNotifications}>update Notification</button> 
                <div  className={classes.button}> <Link to="/InsertNotification" className={classes.button_link} >Insert Notification </Link></div>
                <table className={classes.table}>
                    <tr className={classes.table_row}>
                        <th className={classes.table_heading}>Id</th>
                        <th className={classes.table_heading}>Event</th>
                        <th className={classes.table_heading}>Text</th>
                        <th className={classes.table_heading}>Show Type</th>
                        <th className={classes.table_heading}>Notification Read</th>
                        <th className={classes.table_heading}>AppUrl</th>
                        <th className={classes.table_heading}>NotificationUrl</th>
                    </tr>

                    <tbody>
                        {data.map((item, value) => {
                            return (
                                <tr className={classes.table_row} >
                                    <td className={classes.table_td}>{item.ID}</td>
                                    <td className={classes.table_td}>{item.Event}</td>
                                    <td className={classes.table_td}>{item.Text}</td>
                                    <td className={classes.table_td}>{item.ShowType}</td>
                                    <td className={classes.table_td}>{item.NotificationRead}</td>
                                    <td className={classes.table_td}>{item.AppUrl}</td>
                                    <td className={classes.table_td}>{item.NotificationUrl}</td>
                                </tr>
                            )
                        }

                        )}

                    </tbody>

                </table>
            </div>
        </div>
        
        </>

    );
}

export default Notifications
